import { ReactNode, RefObject } from "react"
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager"
import { ExcludeField } from "../../utils/utilsTypes"

export enum FormElemTypes {
    text = 'Text',
    number = 'Number',
    password = 'Password',
    url = 'Url',
    email = 'Email',
    time = 'Time',
    radio = 'Radio',
    select = 'Select',
    dropDown = 'DropDown',
    textArea = 'TextArea',
    checkbox = 'Checkbox',
    captcha = 'Captcha'
}

type InputHtmlAttributes = {
    ref?: React.MutableRefObject<HTMLInputElement | null>,
    readOnly?: boolean,
    disabled?: boolean,
    placeholder?: string,
    required?: boolean,
    autoFocus?: boolean,
    minLength?: number,
    maxLength?: number,
    defaultValue?: string | number,
    invalidMsg?: string,
    autoComplete?: 'on' | 'off' | 'new-password',
    step?: string,
    min?: string,
    max?: string
}

export type DefaultInputElem = {
    type: FormElemTypes.text | FormElemTypes.number | FormElemTypes.password | FormElemTypes.url | FormElemTypes.email | FormElemTypes.time,
    id?: number | string,
    name: string,
    label?: string,
    icon?: JSX.Element, 
    setDefaultThenClickOutsideParams?: {
        containerRef: React.MutableRefObject<HTMLDivElement | null>
    },
    htmlAttributes?: InputHtmlAttributes,
    withGeneratePassword?: boolean,
    isHidden?: boolean
}

export type TextAreaElem = 
    {
        type: FormElemTypes.textArea
    } &
    ExcludeField<DefaultInputElem, 'type'>;

export type RadioElem = {
    type: FormElemTypes.radio,
    id?: number | string,
    name: string,
    defaultValue?: string | boolean,
    items: {
        label: string,
        value: string | boolean,
        readOnly?: boolean,
        disabled?: boolean,
        autoFocus?: boolean
    }[],
    label?: string,
    icon?: JSX.Element, 
    setDefaultThenClickOutsideParams?: {
        containerRef: React.MutableRefObject<HTMLDivElement | null>
    },
    htmlAttributes?: {
        ref?: RefObject<HTMLInputElement>,
        required?: boolean,
        invalidMsg?: string
    } 
}

export type SelectElem = {
    type: FormElemTypes.select,
    id?: number | string,
    name: string, 
    options: {
        name: string,
        value: string | number
    }[] | string[],
    emptyOption?: {
        name: string,
        value: string | number
    } | string,
    optionsDisabled?: boolean,
    label?: string,
    icon?: JSX.Element, 
    setDefaultThenClickOutsideParams?: {
        containerRef: React.MutableRefObject<HTMLDivElement | null>
    },
    htmlAttributes?: {
        ref?: RefObject<HTMLSelectElement>,
        readOnly?: boolean,
        disabled?: boolean,
        defaultValue?: string,
        required?: boolean,
        autoFocus?: boolean,
        invalidMsg?: string
    } 
}

export type CheckboxElem = {
    type: FormElemTypes.checkbox,
    id?: number | string,
    name: string,
    value?: string,
    checked?: boolean,
    onChange?: () => void,
    options?: {
        name: string,
        value: string | number
    }[] | string[],
    emptyOption?: {
        name: string,
        value: string | number
    } | string,
    optionsDisabled?: boolean,
    label?: string,
    icon?: JSX.Element,
    setDefaultThenClickOutsideParams?: {
        containerRef: React.MutableRefObject<HTMLDivElement | null>
    },
    htmlAttributes?: {
        ref?: RefObject<HTMLSelectElement>,
        readOnly?: boolean,
        disabled?: boolean,
        defaultValue?: string,
        required?: boolean,
        autoFocus?: boolean,
        invalidMsg?: string
    }
}

export type CaptchaElem = {
    type: FormElemTypes.captcha,
    id?: number | string,
    name: string,
    icon?: JSX.Element,
    isHidden: boolean,
    onClick?: () => void,
    img_base64: string | undefined,
    setDefaultThenClickOutsideParams?: {
        containerRef: React.MutableRefObject<HTMLDivElement | null>
    },
    htmlAttributes?: {
        ref?: RefObject<HTMLDivElement>,
        required?: boolean,
        invalidMsg?: string
    }
}

export type DropDownElemValue = string | number;

export type DropDownElem = {
    type: FormElemTypes.dropDown,
    id?: number | string,
    name: string, 
    options: {
        label: string,
        value: DropDownElemValue
    }[],
    defaultValues?: DropDownElemValue[],
    label?: string,
    setDefaultThenClickOutsideParams?: {
        containerRef: React.MutableRefObject<HTMLDivElement | null>
    }
} & StateManagerProps

export type ElementVariant = 
    DefaultInputElem | 
    RadioElem | 
    SelectElem |
    CheckboxElem |
    DropDownElem |
    TextAreaElem |
    CaptchaElem

export type FormButton = {
    name: ReactNode,
    className?: string,
    autoFocus?: boolean,
    color?: string,
    bgColor?: string,
    handleClick?: (data: any)=>void,
    isLoading?: boolean,
    isDisabled?: boolean,
    isLoadingLDAP?: boolean,
    isDisabledLDAP?: boolean
}