import { memo } from 'react'
import { useFormContext } from "react-hook-form";
import { CheckboxElem } from '../FormElemTypes';

const CheckboxInput = memo(({ name, type, checked, onChange, label }: CheckboxElem) => {
    const methods = useFormContext();

    const elemId = 'form_elem_' + name;

    return (
        <div className="form-checkbox-container">
            <input
                {...methods.register(name)}
                className="checkbox"
                type={type}
                name={name}
                id="checkbox"
                checked={checked}
                onChange={onChange}
            />
            <label htmlFor="checkbox">
                { label }
            </label>
        </div>
    )
})

export default CheckboxInput;