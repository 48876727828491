import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {defaultBaseQueryParams} from "../constants/defaultBaseQueryParams";
import { IDefaultDataResponse } from "../models/responses/IDefaultResponse";
import { IComplex } from "../models/entities/IComplex";
import { IProductMetricsRequest } from "../models/requests/IProductMetricsRequest";
import { IProductMetric } from "../models/entities/IProductMetric";
import { IProductMetricType } from "../models/entities/IProductMetricType";
import { RSwal } from "../constants/sweetAlert";

export const productMetricApi = createApi({
    reducerPath: 'productMetricApi',
    baseQuery: fetchBaseQuery(defaultBaseQueryParams),
    tagTypes: ['ProductMetrics'],
    endpoints: build => ({
        getComplexesWithProductMetrics: build.query<IDefaultDataResponse<IComplex[]>, void>({
            query: () => ({
                url: "/ProductMetrics/GetComplexesWithProductMetrics",
                method: "GET"
            })
        }),
        getProductMetricsTypes: build.query<IDefaultDataResponse<IProductMetricType[]>, void>({
            query: () => ({
                url: "/ProductMetrics/GetProductMetricsTypes",
                method: "GET"
            }),
            providesTags: ['ProductMetrics']
        }),
        getProductMetrics: build.query<IDefaultDataResponse<IProductMetric[]>, IProductMetricsRequest>({
            query: (filters) => ({
                url: "/ProductMetrics/GetProductMetrics",
                method: "POST",
                body: filters
            }),
            providesTags: ['ProductMetrics'],
            // Only have one cache entry because the arg always maps to one string
            serializeQueryArgs: ({ endpointName }) => {
              return endpointName
            },
            // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, other) => {
                if (other.arg.lastProductMetricId) {
                    currentCache.data.push(...newItems.data)
                }
                else {
                    currentCache.data = newItems.data
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
            },
            transformResponse: (productMetrics: IDefaultDataResponse<IProductMetric[]>, meta, arg) => {
                if(arg.lastProductMetricId && productMetrics?.data && productMetrics.data.length === 0) {
                    RSwal.fire({
                        icon: 'info',
                        title: 'Таких продуктовых метрик больше не найдено'
                    })
                }
                return productMetrics;
            }
        }),
        getProductMetric: build.query<IDefaultDataResponse<IProductMetric>, number>({
            query: (id) => ({
                url: `/ProductMetrics/GetProductMetric/${id}`,
                method: "GET"
            })
        }),
        createProductMetricType: build.mutation<IDefaultDataResponse<IProductMetricType>, IProductMetricType>({
            query: (productMetric) => ({
                url: '/ProductMetrics/CreateProductMetricType',
                method: "POST",
                body: productMetric
            }),
            invalidatesTags: ['ProductMetrics']
        }),
        updateProductMetricType: build.mutation<IDefaultDataResponse<IProductMetricType>, IProductMetricType>({
            query: (productMetric) => ({
                url: '/ProductMetrics/UpdateProductMetricType',
                method: "PUT",
                body: productMetric
            }),
            invalidatesTags: ['ProductMetrics']
        }),
        deleteProductMetricType: build.mutation<IDefaultDataResponse<IProductMetricType>, number>({
            query: (id) => ({
                url: `/ProductMetrics/DeleteProductMetricType/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ['ProductMetrics']
        }),
    })
})

export const {
    useGetComplexesWithProductMetricsQuery,
    useGetProductMetricsTypesQuery,
    useLazyGetProductMetricsQuery,
    useLazyGetProductMetricQuery,
    useCreateProductMetricTypeMutation,
    useUpdateProductMetricTypeMutation,
    useDeleteProductMetricTypeMutation
} = productMetricApi;