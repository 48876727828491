export enum ComplexProperties {
    id="id",
    region="region",
    type="type",
    token="token",
    replicationToken="replicationToken",
    ignore="ignore",
}

export interface IComplex {
    id: number,
    region: string,
    type: string,
    token: string,
    ignore: boolean,
    replicationToken?: string
}

export enum ComplexPropertiesNames {
    id="id",
    region="Регион",
    type="Система",
    token="Токен",
    replicationToken="Дублирующий токен",
    ignore="Игнор",
}