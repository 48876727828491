import { createRef, memo, useMemo, useState } from 'react';
import PageTitle from '../../components/PageTitle';
import RsuiteTable, { ColumnsList } from '../../components/rsuite-table/RsuiteTable';
import { RowDataType } from 'rsuite-table';
import { ComplexProperties, ComplexPropertiesNames, IComplex } from '../../models/entities/IComplex';
import { useCreateComplexMutation, useDeleteComplexMutation, useGetComplexesQuery, useUpdateComplexMutation } from '../../services/ComplexService';
import { MdAdd, MdClose, MdDelete, MdDone, MdEdit } from 'react-icons/md';
import { fetchDataAlert, showLoadingSwal } from '../../utils/utils';
import Form from '../../components/Form/Form';
import { FormNames, FormTypes, getFormElems } from '../../constants/formElems';
import { useGetRegionsQuery } from '../../services/RegionService';
import { useGetComplexesTypesQuery } from '../../services/ComplexTypeService';
import { SelectElem } from '../../components/Form/FormElemTypes';
import SwalTable from '../../components/SwalTable';
import CopyableBlock from '../../components/CopyableBlock';
import { RSwal } from '../../constants/sweetAlert';
import LoadingBlock from '../../components/LoadingBlock';
import { defaultPollingInterval } from '../../store/globalVariables';

function ComplexesPage() {
    const { refetch: getComplexes, data: getComplexesResult, isFetching, fulfilledTimeStamp } = useGetComplexesQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const [createComplex] = useCreateComplexMutation();
    const [updateComplex] = useUpdateComplexMutation();
    const [deleteComplex] = useDeleteComplexMutation();

    const { refetch: getRegions, data: regions } = useGetRegionsQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const { refetch: getComplexesTypes, data: complexesTypes } = useGetComplexesTypesQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const handleCreateComplex = async (row: IComplex) => {
        showLoadingSwal("Создание...");

        const result = await createComplex({
            id: row.id,
            region: row.region,
            type: row.type,
            ignore: row.ignore,
            replicationToken: row.replicationToken
        });

        fetchDataAlert({
            result: result,
            showSuccessMessage: true,
            successMessage: "data" in result && result.data.data ?
                SwalTable({
                    headers: [
                        {
                            key: ComplexProperties.id,
                            name: ComplexPropertiesNames.id,
                            width: 60,
                            minWidth: 60
                        },
                        {
                            key: ComplexProperties.region,
                            name: ComplexPropertiesNames.region,
                            flexGrow: 1
                        },
                        {
                            key: ComplexProperties.type,
                            name: ComplexPropertiesNames.type,
                            flexGrow: 1
                        },
                        {
                            key: ComplexProperties.token,
                            name: ComplexPropertiesNames.token,
                            flexGrow: 2
                        }
                    ],
                    rows: [{
                        [ComplexProperties.id]: result.data.data[ComplexProperties.id],
                        [ComplexProperties.region]: result.data.data[ComplexProperties.region],
                        [ComplexProperties.type]: result.data.data[ComplexProperties.type],
                        [ComplexProperties.token]: <CopyableBlock copyableValue={result.data.data[ComplexProperties.token]} />
                    }]
                })
                : undefined
        })
    }

    const handleUpdateComplex = async (row: IComplex) => {
        showLoadingSwal("Изменение...");

        const result = await updateComplex({
            id: row.id,
            region: row.region,
            type: row.type,
            ignore: row.ignore,
            replicationToken: row.replicationToken
        });

        fetchDataAlert({
            result: result,
            showSuccessMessage: true
        })
    }

    const handleDeleteComplex = async (id: number) => {
        showLoadingSwal("Удаление...");

        const result = await deleteComplex(id);

        fetchDataAlert({
            result: result,
            showSuccessMessage: true
        })
    }

    const columns: ColumnsList = useMemo(() => {
        const regionsOptions: SelectElem['options'] | undefined = regions?.data.map(region => region.name);
        const complexesTypesOptions: SelectElem['options'] | undefined = complexesTypes?.data.map(complexType => complexType.name);

        const currentCols: ColumnsList = [
            {
                columnProps: {
                    width: 80,
                    fixed: "left"
                },
                headerProps: {
                    children: <div className="rs-table-crud-icon">
                        <MdAdd
                            title="Подключить систему"
                            onClick={() =>
                                regionsOptions && complexesTypesOptions ?
                                    RSwal.fire({
                                        title: "Подключение системы",
                                        html: <Form
                                            isSwalForm
                                            // Элементы формы модального окна
                                            elements={getFormElems({
                                                formName: FormNames.complex,
                                                formType: FormTypes.create,
                                                options: {
                                                    [ComplexProperties.region]: regionsOptions,
                                                    [ComplexProperties.type]: complexesTypesOptions
                                                }
                                            })}
                                            submitButton={{
                                                name: "Создать",
                                                handleClick: (data: IComplex) => handleCreateComplex(data)
                                            }}
                                            denyButton={{
                                                name: "Отменить",
                                                autoFocus: true,
                                                handleClick: () => RSwal.close()
                                            }}
                                        />,
                                        showConfirmButton: false,
                                        showCloseButton: true
                                    }) : null} />
                    </div>
                },
                cellProps: {
                    children: (rowData) => {
                        const row = rowData as IComplex;
                        return <>
                            <div className="rs-table-crud-icon">
                                <MdEdit
                                    title="Изменить систему"
                                    onClick={() =>
                                        regionsOptions && complexesTypesOptions ?
                                            RSwal.fire({
                                                title: "Редактирование системы",
                                                html: <Form
                                                    isSwalForm
                                                    // Элементы формы модального окна
                                                    elements={getFormElems({
                                                        formName: FormNames.complex,
                                                        formType: FormTypes.update,
                                                        options: {
                                                            [ComplexProperties.region]: regionsOptions,
                                                            [ComplexProperties.type]: complexesTypesOptions
                                                        },
                                                        row: {
                                                            id: row.id,
                                                            region: row.region,
                                                            type: row.type,
                                                            token: row.token,
                                                            replicationToken: row.replicationToken,
                                                            ignore: row.ignore
                                                        }
                                                    })}
                                                    submitButton={{
                                                        name: "Изменить",
                                                        handleClick: (data: IComplex) => handleUpdateComplex(data)
                                                    }}
                                                    denyButton={{
                                                        name: "Отменить",
                                                        autoFocus: true,
                                                        handleClick: () => RSwal.close()
                                                    }}
                                                />,
                                                showConfirmButton: false,
                                                showCloseButton: true
                                            }) : null} />
                            </div>
                            <div className="rs-table-crud-icon">
                                <MdDelete
                                    title="Удалить систему"
                                    onClick={() =>
                                        regionsOptions && complexesTypesOptions ?
                                            RSwal.fire({
                                                title: "Удаление системы",
                                                html: <Form
                                                    isSwalForm
                                                    // Элементы формы модального окна
                                                    elements={getFormElems({
                                                        formName: FormNames.complex,
                                                        formType: FormTypes.delete,
                                                        options: {
                                                            [ComplexProperties.region]: regionsOptions,
                                                            [ComplexProperties.type]: complexesTypesOptions
                                                        },
                                                        row: {
                                                            id: row.id,
                                                            region: row.region,
                                                            type: row.type,
                                                            token: row.token,
                                                            replicationToken: row.replicationToken,
                                                            ignore: row.ignore
                                                        }
                                                    })}
                                                    submitButton={{
                                                        name: "Удалить",
                                                        handleClick: (data: IComplex) => handleDeleteComplex(data.id)
                                                    }}
                                                    denyButton={{
                                                        name: "Отменить",
                                                        autoFocus: true,
                                                        handleClick: () => RSwal.close()
                                                    }}
                                                />,
                                                showConfirmButton: false,
                                                showCloseButton: true
                                            }) : null} />
                            </div>
                        </>
                    }
                }
            },
            {
                columnProps: {
                    sortable: true,
                    fixed: true,
                    width: 60
                },
                headerProps: {
                    children: ComplexPropertiesNames.id
                },
                cellProps: {
                    dataKey: ComplexProperties.id
                }
            },
            {
                columnProps: {
                    sortable: true,
                    flexGrow: 1,
                    fullText: true
                },
                headerProps: {
                    children: ComplexPropertiesNames.region
                },
                cellProps: {
                    dataKey: ComplexProperties.region
                },
                searchable: true
            },
            {
                columnProps: {
                    sortable: true,
                    flexGrow: 1,
                    fullText: true
                },
                headerProps: {
                    children: ComplexPropertiesNames.type
                },
                cellProps: {
                    dataKey: ComplexProperties.type
                },
                searchable: true
            },
            {
                columnProps: {
                    sortable: true,
                    flexGrow: 1,
                    fullText: true
                },
                headerProps: {
                    children: ComplexPropertiesNames.token
                },
                cellProps: {
                    dataKey: ComplexProperties.token
                },
                searchable: true
            },
            {
                columnProps: {
                    sortable: true,
                    width: 115
                },
                headerProps: {
                    children: ComplexPropertiesNames.ignore
                },
                cellProps: {
                    dataKey: ComplexProperties.ignore,
                    children: (row: RowDataType | IComplex) =>
                        <div className='fs-1-25'>
                            {row.ignore ?
                                <MdDone className='text-danger' /> :
                                <MdClose />}
                        </div>
                },
                searchable: true
            }
        ];

        if(getComplexesResult?.data.withReplication) {
            currentCols.splice(
                5,
                0,
                {
                    columnProps: {
                        sortable: true,
                        flexGrow: 1,
                        fullText: true
                    },
                    headerProps: {
                        children: "Дублирующий токен"
                    },
                    cellProps: {
                        dataKey: ComplexProperties.replicationToken
                    },
                    searchable: true
                }
            )
        }

        return currentCols;
    }, [regions, complexesTypes, getComplexesResult]);

    return (
        <main>
            <PageTitle
                title='Подключенные системы'
                reloadPage={() => {
                    getComplexes();
                    getRegions();
                    getComplexesTypes();
                }}
                isLoading={isFetching}
                pageUpdateTime={fulfilledTimeStamp && !isFetching ? new Date(fulfilledTimeStamp).toLocaleString().replace(',', '') : ""}
            />

            {getComplexesResult?.data.complexes ?
                <RsuiteTable
                    data={getComplexesResult.data.complexes}
                    columns={columns}
                    withTotalRows
                    virtualized
                /> :
                <LoadingBlock rounded />}
        </main>
    );
}

export default memo(ComplexesPage);