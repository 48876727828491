import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {defaultBaseQueryParams} from "../constants/defaultBaseQueryParams";
import { IComplexType } from "../models/entities/IComplexType";
import { IDefaultResponse } from "../models/responses/IDefaultResponse";
import { IDefaultDataResponse } from "../models/responses/IDefaultDataResponse";

export const complexTypeApi = createApi({
    reducerPath: 'complexTypeApi',
    baseQuery: fetchBaseQuery(defaultBaseQueryParams),
    tagTypes: ['ComplexType'],
    endpoints: build => ({
        getComplexesTypes: build.query<IDefaultDataResponse<IComplexType[]>, void>({
            query: ()  => ({
                url: `/List/Get?mode=COMPLEX_TYPES`
            }),
            providesTags: (result) =>
                result?.data? [
                    ...result.data.map(({ id }) => ({ type: 'ComplexType' as const, id })),
                    { type: 'ComplexType', id: 'LIST' },
                    ]
                : [{ type: 'ComplexType', id: 'LIST' }]
        }),
        createComplexType: build.mutation<IDefaultDataResponse<IComplexType>, IComplexType>({
            query: (row)  => ({
                url: `/List/CreateComplexType`,
                method: "POST",
                body: row
            }),
            invalidatesTags: [{ type: 'ComplexType', id: 'LIST' }]
        }),
        updateComplexType: build.mutation<IDefaultResponse, IComplexType>({
            query: (row)  => ({
                url: `/List/UpdateComplexType`,
                method: "PUT",
                body: row
            }),
            invalidatesTags: [{ type: 'ComplexType', id: 'LIST' }]
        }),
        deleteComplexType: build.mutation<IDefaultResponse, number>({
            query: (id)  => ({
                url: `/List/DeleteComplexType/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: 'ComplexType', id: 'LIST' }]
        })
    })
})

export const {
    useGetComplexesTypesQuery,
    useCreateComplexTypeMutation,
    useUpdateComplexTypeMutation,
    useDeleteComplexTypeMutation
} = complexTypeApi;