import './home.scss'
import React, { useMemo, useRef, useLayoutEffect, useState } from 'react'
import { useGetStatisticComplexesQuery, useGetErrorsCountQuery, useGetErrorsByComplexQuery, useGetFiringAlertsQuery, useLazyGetGraphsDataByComplexesQuery, useLazyGetGraphsDataByRegionsQuery } from "../../services/StatisticService";
import PageTitle from '../../components/PageTitle';
import StatisticsLine from './StatisticsLine/StatisticsLine';
import GraphsContainer from './Graphs/GraphsContainer';
import ErrorsCountTable from './ErrorsCountTable';
import { EErrorTypes } from '../../models/entities/EErrorTypes';
import { useAppSelector } from '../../hooks/redux';
import { defaultPollingInterval } from '../../store/globalVariables';
import useDidMountEffect from '../../hooks/skipFirstUseEffectRun';

function HomePage() {
    const currentUser = useAppSelector(state=>state.user.currentUserState);

    const pollingInterval = useRef(defaultPollingInterval);

    useDidMountEffect(()=>{
        pollingInterval.current = currentUser?.userSettings?.home_autoupdate_interval! ?? defaultPollingInterval;
    }, [currentUser])

    const {
        data: errorsCount,
        isFetching: errorsCountIsFetching,
        refetch: getErrorsCount,
        fulfilledTimeStamp: getErrorsCountReceivingTime
    } = useGetErrorsCountQuery(undefined, {
        pollingInterval: pollingInterval.current
    });

    const {
        data: errorsCountOfLastDay,
        isFetching: errorsCountOfLastDayIsFetching,
        refetch: getErrorsCountOfLastDay,
        fulfilledTimeStamp: getErrorsCountOfLastDayReceivingTime
    } = useGetErrorsCountQuery(24, {
        pollingInterval: pollingInterval.current
    });

    const {
        data: complexes,
        isFetching: complexesIsFetching,
        refetch: getComplexes,
        fulfilledTimeStamp: getComplexesReceivingTime
    } = useGetStatisticComplexesQuery(undefined, {
        pollingInterval: pollingInterval.current
    });

    const {
        data: errorsByComplex,
        isFetching: errorsByComplexIsFetching,
        isSuccess: errorsByComplexIsSuccess,
        refetch: getErrorsByComplex,
        fulfilledTimeStamp: getErrorsByComplexReceivingTime
    } = useGetErrorsByComplexQuery(undefined, {
        pollingInterval: pollingInterval.current
    });

    const {
        data: criticalErrorsByComplex,
        isFetching: criticalErrorsByComplexIsFetching,
        refetch: getCriticalErrorsByComplex,
        fulfilledTimeStamp: getCriticalErrorsByComplexReceivingTime
    } = useGetErrorsByComplexQuery(EErrorTypes['Критическая'], {
        pollingInterval: pollingInterval.current
    });

    const [getGraphsDataByComplexes, {
        data: graphsDataByComplexes,
        isFetching: graphsDataByComplexesIsFetching,
        fulfilledTimeStamp: graphsDataByComplexesReceivingTime
    }] = useLazyGetGraphsDataByComplexesQuery({
        pollingInterval: pollingInterval.current
    });

    const [getGraphsDataByRegions, {
        data: graphsDataByRegions,
        isFetching: graphsDataByRegionsIsFetching,
        fulfilledTimeStamp: graphsDataByRegionsReceivingTime
    }] = useLazyGetGraphsDataByRegionsQuery({
        pollingInterval: pollingInterval.current
    });

    const {
        data: firingAlerts,
        isFetching: firingAlertsIsFetching,
        refetch: getFiringAlerts,
        fulfilledTimeStamp: firingAlertsReceivingTime
    } = useGetFiringAlertsQuery(undefined, {
        skip: !currentUser?.role?.isAdmin && !currentUser?.role?.isSuperAdmin,
        pollingInterval: pollingInterval.current
    });

    function getStatistic() {
        getErrorsCount();
        getErrorsCountOfLastDay();
        getComplexes();
        getErrorsByComplex();
        getCriticalErrorsByComplex();

        if(graphsTypeIsRegions) {
            getGraphsDataByRegions();
        }
        else {
            getGraphsDataByComplexes();
        }
        
        if(currentUser?.role?.isAdmin || currentUser?.role?.isSuperAdmin) getFiringAlerts();
    }

    const isLoading = 
        errorsCountIsFetching ||
        errorsCountOfLastDayIsFetching ||
        complexesIsFetching ||
        errorsByComplexIsFetching ||
        criticalErrorsByComplexIsFetching ||
        graphsDataByComplexesIsFetching ||
        firingAlertsIsFetching;

    const lastReceivingTime = useMemo(()=>{
        if(isLoading) {
            return 0
        }
        else {
            return Math.max(
                getErrorsCountReceivingTime ?? 0,
                getErrorsCountOfLastDayReceivingTime ?? 0,
                getComplexesReceivingTime ?? 0,
                getErrorsByComplexReceivingTime ?? 0,
                getCriticalErrorsByComplexReceivingTime ?? 0,
                graphsDataByComplexesReceivingTime ?? 0,
                firingAlertsReceivingTime ?? 0
            );
        }
    }, [
        getErrorsCountReceivingTime,
        getErrorsCountOfLastDayReceivingTime,
        getComplexesReceivingTime,
        getErrorsByComplexReceivingTime,
        getCriticalErrorsByComplexReceivingTime,
        graphsDataByComplexesReceivingTime,
        firingAlertsReceivingTime
    ])
    
    // useEffect(() => {
    //     const handleFocus = () => {
    //         if (lastReceivingTimeRef.current) {
    //             const diff = new Date().getTime() - new Date(lastReceivingTimeRef.current).getTime();
    //             if (diff > pollingInterval.current) {
    //                 getStatistic();
    //             }
    //         }
    //     }

    //     window.addEventListener('focus', handleFocus);

    //     return ()=>window.removeEventListener('focus', handleFocus);
    // }, []);

    const [graphsTypeIsRegions, setGraphsTypeIsRegions] = useState<boolean>(()=>{
        const localGrapthsType = localStorage.getItem('graphsTypeIsRegions');
        if(localGrapthsType === null) {
            return false;
        }
        else {
            try {
                return Boolean(JSON.parse(localGrapthsType))
            }
            catch {
                return false;
            }
        }
    });

    useDidMountEffect(()=>{
        localStorage.setItem('graphsTypeIsRegions', `${graphsTypeIsRegions}`);
    }, [graphsTypeIsRegions])

    useLayoutEffect(()=>{
        if(graphsTypeIsRegions) {
            getGraphsDataByRegions(undefined, true);
        }
        else {
            getGraphsDataByComplexes(undefined, true);
        }
    }, [graphsTypeIsRegions])

    return (
        <main className="pb-0">
            <PageTitle
                title='Главная'
                reloadPage={() => getStatistic()}
                isLoading={isLoading}
                pageUpdateTime={!isLoading ? new Date(lastReceivingTime).toLocaleString().replace(',', "") : ''}
            />

            <div className="home-page">
                <StatisticsLine
                    complexes={complexes?.data}
                    complexesIsLoading={complexesIsFetching}
                    errorsCountIsLoading={errorsCountIsFetching}
                    errorsCount={errorsCount?.data}
                    errorsCountOfLastDay={errorsCountOfLastDay?.data}
                    errorsCountOfLastDayIsLoading={errorsCountOfLastDayIsFetching}
                    firingAlerts={firingAlerts?.data}
                    firingAlertsIsLoading={firingAlertsIsFetching}
                    errorsByComplex={errorsByComplex?.data}
                    errorsByComplexIsLoading={errorsByComplexIsFetching}
                    criticalErrorsByComplex={criticalErrorsByComplex?.data}
                    criticalErrorsByComplexIsLoading={criticalErrorsByComplexIsFetching}
                />
                    <div className="home-graphs-and-table-container row mx-0">
                        <div className="col-md-6 col-12 h-md-100 ps-md-0 pe-md-2 px-0 pb-md-3">
                            <GraphsContainer
                                graphsData={graphsTypeIsRegions ? graphsDataByRegions?.data : graphsDataByComplexes?.data}
                                graphsDataIsLoading={graphsTypeIsRegions ? graphsDataByRegionsIsFetching :graphsDataByComplexesIsFetching}
                                errorsByComplexType={errorsByComplex?.data?.errorsByComplexType}
                                graphsTypeIsRegions={graphsTypeIsRegions}
                                setGraphsTypeIsRegions={setGraphsTypeIsRegions}
                            />
                        </div>
                        <div className="col-md-6 col-12 h-md-100 h-50 ps-md-2 pe-md-0 pt-md-0 pt-3 px-0 pb-3">
                            <ErrorsCountTable
                                data={errorsByComplex?.data?.errorsByComplex}
                                isLoading={errorsByComplexIsFetching}
                                isSuccess={errorsByComplexIsSuccess}
                            />
                        </div>
                    </div>
            </div>
        </main>
    )
}

export default React.memo(HomePage)