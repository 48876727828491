import {memo} from "react"
import { Navigate } from 'react-router-dom';
import { FormElemTypes } from '../../components/Form/FormElemTypes';
import Form from '../../components/Form/Form';
import monitoringIcon from "../../assets/images/monitoring-icon-180x180.png";
import { MdPerson, MdPlace, MdMail, MdBadge } from 'react-icons/md';
import { useApplyForRegistrationMutation } from '../../services/UserService';
import IApplyForRegistration from '../../models/requests/IApplyForRegistration';
import { useAppSelector } from "../../hooks/redux";
import { fetchDataAlert, nameof } from "../../utils/utils";

function RegistrationPage() {
    const [applyForRegistration, {isLoading}] = useApplyForRegistrationMutation();
    const currentUser = useAppSelector(state=>state.user.currentUserState);

    const handleClick = async (data: IApplyForRegistration) => {
        const result = await applyForRegistration(data);
        fetchDataAlert({
            result: result,
            showSuccessMessage: true,
            successMessage: "data" in result && result.data?.success ?
            `Информация о подтверждении заявки придёт Вам на почту 
                ${result?.data?.data?.email}` : undefined
        })
    }

    if (currentUser) {
        return <Navigate to="/" />
    }
    else {
        return (
            <div className='registration-page'>
                <Form
                    className="authorization-form"
                    header={<div className='authorization-form-header'>
                        <img
                            src={monitoringIcon}
                            alt="monitoring-icon"
                            className='authorization-form-icon'
                        />
                        <div className="authorization-form-title">
                            СЕРВИС МОНИТОРИНГА
                        </div>
                    </div>}
                    elements={[
                        {
                            type: FormElemTypes.text,
                            name: nameof<IApplyForRegistration>('firstName'),
                            icon: <MdBadge />,
                            htmlAttributes: {
                                placeholder: "Имя",
                                required: true
                            }
                        },
                        {
                            type: FormElemTypes.text,
                            name: nameof<IApplyForRegistration>('lastName'),
                            icon: <MdBadge />,
                            htmlAttributes: {
                                placeholder: "Фамилия",
                                required: true
                            }
                        },
                        {
                            type: FormElemTypes.text,
                            name: nameof<IApplyForRegistration>('username'),
                            icon: <MdPerson />,
                            htmlAttributes: {
                                placeholder: "Логин",
                                required: true
                            }
                        },
                        {
                            type: FormElemTypes.text,
                            name: nameof<IApplyForRegistration>('region'),
                            icon: <MdPlace />,
                            htmlAttributes: {
                                placeholder: "Регион",
                                required: true
                            }
                        },
                        {
                            type: FormElemTypes.email,
                            name: nameof<IApplyForRegistration>('email'),
                            icon: <MdMail />,
                            htmlAttributes: {
                                placeholder: "Почта",
                                required: true
                            }
                        }
                    ]}
                    submitButton={{
                        name: "Подать заявку",
                        isLoading: isLoading,
                        isDisabled: isLoading,
                        handleClick: (data: IApplyForRegistration) => handleClick(data)
                    }}
                />
            </div>
        );
    }
}

export default memo(RegistrationPage);