export const iframeStyles = `
    <style>
        .error-info-msg {
            font-size: 1rem; 
            font-family: 'Open Sans', sans-serif;
            white-space: pre-wrap;
            line-height: 1.5;
            word-break: break-word;
        }
        code {
            font-size: 87.5%;
            color: #e83e8c;
            word-break: break-word;
        }
        a {
            color: #007bff;
            text-decoration: none;
            background-color: initial;
        }
        a:not([href]):not([tabindex]), 
        a:not([href]):not([tabindex]):focus, 
        a:not([href]):not([tabindex]):hover {
            color: inherit;
            text-decoration: none;
        }
        *::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background: rgba(45, 45, 45, 0.05);
        }

        *::-webkit-scrollbar:hover,
        *::-webkit-scrollbar:active {
            box-shadow: 1px 0 2px #ddd inset;
        }
          
        *::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            border-radius: 50rem;
        }
        
        *::-webkit-scrollbar-thumb:hover {
            background-color: rgb(182, 181, 181);
        }
        
        *::selection {
            background: #338fff !important;
            color: white !important;
          }          
    </style>`;

export const onlineInterval: number = Number(process.env.REACT_APP_ONLINE_INTERVAL);

export const sortTypes = {
    ascending: 'ascending',
    descending: 'descending',
    none: 'none'
} as const;