import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {defaultBaseQueryParams} from "../constants/defaultBaseQueryParams";
import { IComplex } from "../models/entities/IComplex";
import { IDefaultResponse } from "../models/responses/IDefaultResponse";
import { TComplexRequest } from "../models/requests/TComplexRequest";
import { IDefaultDataResponse } from "../models/responses/IDefaultDataResponse";
import IGetComplexesResponse from "../models/responses/IGetComplexesReponse";

export const complexApi = createApi({
    reducerPath: 'complexApi',
    baseQuery: fetchBaseQuery(defaultBaseQueryParams),
    tagTypes: ['Complex'],
    endpoints: build => ({
        getComplexes: build.query<IDefaultDataResponse<IGetComplexesResponse>, void>({
            query: ()  => ({
                url: `/List/Get?mode=COMPLEXES`
            }),
            providesTags: (result) =>
                result?.data? [
                    ...result.data.complexes.map(({ id }) => ({ type: 'Complex' as const, id })),
                    { type: 'Complex', id: 'LIST' },
                    ]
                : [{ type: 'Complex', id: 'LIST' }]
        }),
        createComplex: build.mutation<IDefaultDataResponse<IComplex>, TComplexRequest>({
            query: (request)  => ({
                url: `/List/CreateComplex`,
                method: "POST",
                body: request
            }),
            invalidatesTags: [{ type: 'Complex', id: 'LIST' }]
        }),
        updateComplex: build.mutation<IDefaultResponse, TComplexRequest>({
            query: (request)  => ({
                url: `/List/UpdateComplex`,
                method: "PUT",
                body: request
            }),
            invalidatesTags: [{ type: 'Complex', id: 'LIST' }]
        }),
        deleteComplex: build.mutation<IDefaultResponse, number>({
            query: (id)  => ({
                url: `/List/DeleteComplex/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: 'Complex', id: 'LIST' }]
        })
    })
})

export const {
    useGetComplexesQuery,
    useCreateComplexMutation,
    useUpdateComplexMutation,
    useDeleteComplexMutation
} = complexApi;