import { memo, useMemo } from 'react'
import './error-triggers.scss'
import PageTitle from '../../components/PageTitle'
import RsuiteTable, { ColumnsList } from '../../components/rsuite-table/RsuiteTable'
import { useCreateTriggerMutation, useDeleteTriggerMutation, useGetErrorTriggersQuery, useSubcribeToTriggerMutation, useUnsubcribeToTriggerMutation, useUpdateTriggerMutation } from '../../services/IssueService'
import { defaultPollingInterval } from '../../store/globalVariables'
import { fetchDataAlert, nameof, showLoadingSwal } from '../../utils/utils'
import { ITrigger } from '../../models/entities/ITrigger'
import { MdAdd, MdDelete, MdEdit, MdNotifications, MdNotificationsActive, MdNotificationsOff } from 'react-icons/md'
import { RSwal } from '../../constants/sweetAlert'
import Form from '../../components/Form/Form'
import { DropDownElem, ElementVariant, FormElemTypes } from '../../components/Form/FormElemTypes'
import { useCheckRocketChatAllowedQuery, useGetAllUsersWithMessengerQuery } from '../../services/UserService'
import { useAppSelector } from '../../hooks/redux'

const getCreateTriggerElems = (usersOptions: DropDownElem['options']): ElementVariant[] => {
    return [
        {
            type: FormElemTypes.text,
            name: nameof<ITrigger>('name'),
            label: "Название",
            htmlAttributes: {
                placeholder: "Ошибка входа",
                required: true
            }
        },
        {
            type: FormElemTypes.text,
            name: nameof<ITrigger>('errorSubstring'),
            label: "Триггер",
            htmlAttributes: {
                placeholder: "Authentication error",
                required: true
            }
        },
        {
            type: FormElemTypes.dropDown,
            name: nameof<ITrigger>('subscribersIds'),
            label: "Подписчики",
            options: usersOptions,
            placeholder: 'Выберите подписчиков'
        }
    ]
};

const getUpdateTriggerElems = (trigger: ITrigger, usersOptions: DropDownElem['options']): ElementVariant[] => {
    return [
        {
            type: FormElemTypes.text,
            name: nameof<ITrigger>('id'),
            label: "id",
            htmlAttributes: {
                placeholder: "1",
                required: true,
                readOnly: true,
                defaultValue: trigger.id
            }
        },
        {
            type: FormElemTypes.text,
            name: nameof<ITrigger>('name'),
            label: "Название",
            htmlAttributes: {
                placeholder: "Ошибка входа",
                required: true,
                defaultValue: trigger.name
            }
        },
        {
            type: FormElemTypes.text,
            name: nameof<ITrigger>('errorSubstring'),
            label: "Триггер",
            htmlAttributes: {
                placeholder: "Authentication error",
                required: true,
                defaultValue: trigger.errorSubstring
            }
        },
        {
            type: FormElemTypes.dropDown,
            name: nameof<ITrigger>('subscribersIds'),
            label: "Подписчики",
            options: usersOptions,
            defaultValues: trigger.subscribersIds,
            placeholder: 'Выберите подписчиков'
        }
    ]
};

const getDeleteTriggerElems = (trigger: ITrigger): ElementVariant[] => {
    return [
        {
            type: FormElemTypes.text,
            name: nameof<ITrigger>('id'),
            label: "id",
            htmlAttributes: {
                placeholder: "1",
                required: true,
                readOnly: true,
                defaultValue: trigger.id
            }
        },
        {
            type: FormElemTypes.text,
            name: nameof<ITrigger>('name'),
            label: "Название",
            htmlAttributes: {
                placeholder: "Ошибка входа",
                required: true,
                defaultValue: trigger.name,
                disabled: true
            }
        },
        {
            type: FormElemTypes.text,
            name: nameof<ITrigger>('errorSubstring'),
            label: "Триггер",
            htmlAttributes: {
                placeholder: "Authentication error",
                required: true,
                defaultValue: trigger.errorSubstring,
                disabled: true
            }
        },
        // {
        //     type: FormElemTypes.dropDown,
        //     name: nameof<ITrigger>('subscribersIds'),
        //     label: "Подписчики",
        //     options: usersOptions,
        //     required: true,
        //     placeholder: 'Выберите подписчиков',
        //     isDisabled: true
        // }
    ]
};

const ErrorTriggersPage = memo(() => {
    const {
        data: errorTriggers,
        refetch: getErrorTriggers,
        isFetching: errorTriggersIsLoading,
        fulfilledTimeStamp: errorTriggersReceivingTime,
        isError: errorTriggersIsError
    } = useGetErrorTriggersQuery(
        undefined,
        {
            pollingInterval: defaultPollingInterval,
        }
    );

    const [createTriggerFetch] = useCreateTriggerMutation();
    const [updateTriggerFetch] = useUpdateTriggerMutation();
    const [deleteTriggerFetch] = useDeleteTriggerMutation();


    const createTrigger = (data: ITrigger) => {
        showLoadingSwal("Создание...");

        createTriggerFetch(data).then(result => {
            fetchDataAlert({
                result: result,
                showSuccessMessage: true
            })
        })
    }

    const updateTrigger = (data: ITrigger) => {
        showLoadingSwal("Изменение...");

        updateTriggerFetch(data).then(result => {
            fetchDataAlert({
                result: result,
                showSuccessMessage: true
            })
        })
    }

    const deleteTrigger = (id: number) => {
        showLoadingSwal("Удаление...");

        deleteTriggerFetch(id).then(result => {
            fetchDataAlert({
                result: result,
                showSuccessMessage: true
            })
        })
    }

    const { data: usersWithMessenger, isFetching: usersIsFetching } = useGetAllUsersWithMessengerQuery(
        undefined,
        {
            pollingInterval: defaultPollingInterval
        }
    );

    const { data: rocketChatIsAllowed } = useCheckRocketChatAllowedQuery();

    const [subscribeToTrigger, { originalArgs: subArgs, isLoading: subLoading }] = useSubcribeToTriggerMutation();
    const [unsubscribeToTrigger, { originalArgs: unsubArgs, isLoading: unsubLoading }] = useUnsubcribeToTriggerMutation();

    const loadingTriggerSubId = useMemo(() => {
        if (subLoading) {
            return subArgs
        }
        else if (unsubLoading) {
            return unsubArgs
        }
    }, [subArgs, unsubArgs, subLoading, unsubLoading])

    const handleSubscribe = (id: number) => {
        if(rocketChatIsAllowed?.data) {
            if(!currentUser?.userSettings?.rocket_chat_nickname) {
                return RSwal.fire({
                    icon: "error",
                    title: "Сперва привяжите логин пользователя RocketChat в настройках",
                    html: <a 
                        href={`${window.location.origin + process.env.PUBLIC_URL}/Settings`}
                        target='_blank'
                    >
                        В настройки
                    </a>
                })
            }
        }

        subscribeToTrigger(id)
            .unwrap()
            .then(result => {
                if(result.success) {
                    RSwal.fire({
                        toast: true,
                        icon: 'success',
                        position: 'top',
                        title: result.message,
                        showConfirmButton: false,
                        timer: 1500,
                        timerProgressBar: true
                    });
                }
                else {
                    RSwal.fire({
                        icon: 'error',
                        title: result.message
                    });
                }
        })
    }

    const handleUnsubscribe = (id: number) => {
        unsubscribeToTrigger(id)
            .unwrap()
            .then(result => {
                if(result.success) {
                    RSwal.fire({
                        toast: true,
                        icon: 'success',
                        position: 'top',
                        title: result.message,
                        showConfirmButton: false,
                        timer: 2500,
                        timerProgressBar: true
                    });
                }
                else {
                    RSwal.fire({
                        icon: 'error',
                        title: result.message
                    });
                }
        })
    }

    const currentUser = useAppSelector(state=>state.user.currentUserState);

    const groupedErrorsColumns: ColumnsList = useMemo(() => {
        const usersOptions: DropDownElem['options'] = usersWithMessenger?.data ? usersWithMessenger.data.map(user => ({
            label: user.fullName,
            value: user.id
        })) : [];

        return [
            {
                columnProps: {
                    width: 80,
                    fixed: "left"
                },
                headerProps: {
                    children: <div className="rs-table-crud-icon">
                        <MdAdd
                            title="Добавить триггер"
                            onClick={() =>
                                RSwal.fire({
                                    title: "Добавление триггера",
                                    html: <Form
                                        isSwalForm
                                        // Элементы формы модального окна
                                        elements={getCreateTriggerElems(usersOptions)}
                                        submitButton={{
                                            name: "Добавить",
                                            handleClick: (data: ITrigger) => createTrigger(data)
                                        }}
                                        denyButton={{
                                            name: "Отменить",
                                            autoFocus: true,
                                            handleClick: () => RSwal.close()
                                        }}
                                    />,
                                    showConfirmButton: false,
                                    showCloseButton: true,
                                    customClass: {
                                        popup: "text-left"
                                    }
                                })} />
                    </div>
                },
                cellProps: {
                    children: (rowData) => {
                        const row = rowData as ITrigger;

                        return <>
                            <div className="rs-table-crud-icon">
                                <MdEdit
                                    title="Изменить триггер"
                                    onClick={() => {
                                        RSwal.fire({
                                            title: "Редактирование триггера",
                                            html: <Form
                                                isSwalForm
                                                // Элементы формы модального окна
                                                elements={getUpdateTriggerElems(row, usersOptions)}
                                                submitButton={{
                                                    name: "Изменить",
                                                    handleClick: (data: ITrigger) => updateTrigger(data)
                                                }}
                                                denyButton={{
                                                    name: "Отменить",
                                                    autoFocus: true,
                                                    handleClick: () => RSwal.close()
                                                }}
                                            />,
                                            showConfirmButton: false,
                                            showCloseButton: true
                                        })
                                    }} />
                            </div>
                            <div className="rs-table-crud-icon">
                                <MdDelete
                                    title="Удалить триггер"
                                    onClick={() =>
                                        RSwal.fire({
                                            title: "Удаление триггера",
                                            html: <Form
                                                isSwalForm
                                                // Элементы формы модального окна
                                                elements={getDeleteTriggerElems(row)}
                                                submitButton={{
                                                    name: "Удалить",
                                                    handleClick: (data: ITrigger) => deleteTrigger(data.id)
                                                }}
                                                denyButton={{
                                                    name: "Отменить",
                                                    autoFocus: true,
                                                    handleClick: () => RSwal.close()
                                                }}
                                            />,
                                            showConfirmButton: false,
                                            showCloseButton: true
                                        })} />
                            </div>
                        </>
                    }
                }
            },
            {
                columnProps: {
                    sortable: true,
                    fixed: true,
                    width: 60
                },
                headerProps: {
                    children: "id"
                },
                cellProps: {
                    dataKey: "id"
                }
            },
            {
                columnProps: {
                    sortable: true,
                    flexGrow: 1,
                    fullText: true
                },
                headerProps: {
                    children: "Название"
                },
                cellProps: {
                    dataKey: nameof<ITrigger>("name")
                },
                searchable: true
            },
            {
                columnProps: {
                    sortable: true,
                    flexGrow: 2,
                    fullText: true
                },
                headerProps: {
                    children: "Триггер"
                },
                cellProps: {
                    dataKey: nameof<ITrigger>("errorSubstring")
                },
                searchable: true
            },
            {
                columnProps: {
                    sortable: true,
                    flexGrow: 1,
                    fullText: true
                },
                headerProps: {
                    children: "Подписчики"
                },
                cellProps: {
                    dataKey: nameof<ITrigger>("subscribersIds"),
                    children: (rowData) => {
                        const row = rowData as ITrigger;

                        if (!row.subscribersIds || row.subscribersIds.length === 0) {
                            return "Нет"
                        }

                        if (usersWithMessenger?.data) {
                            return <select
                                name={"triggerUsers" + row.id}
                                className='mx-2 w-100'
                            >
                                <option className='d-none'>Подписчики</option>

                                {row.subscribersIds.map((userId, idx) => {
                                    const subscriber = usersWithMessenger.data?.find(user => user.id === userId);

                                    if (subscriber) {
                                        return <option
                                            key={idx}
                                            value={subscriber.id}
                                            disabled
                                        >
                                            {subscriber.fullName}
                                        </option>
                                    }
                                    else {
                                        return;
                                    }
                                })}
                            </select>
                        }
                        else if (usersIsFetching) {
                            return <span className='spinner-border spinner-border-sm'></span>
                        }
                        else {
                            return "Неизвестно"
                        }
                    }
                },
                searchable: true
            },
            {
                columnProps: {
                    fixed: true,
                    width: 50
                },
                headerProps: {
                    children: ""
                },
                cellProps: {
                    dataKey: "",
                    children: (rowData)=> {
                        const row = rowData as ITrigger;
                        const subscribed = row.subscribersIds?.some(id=>id === currentUser?.id);
                        if (subscribed) {
                            return loadingTriggerSubId === row.id ?
                                <span className='spinner-border spinner-border-sm mx-auto' /> :
                                <>
                                    <MdNotificationsActive
                                        className='notification notification-active text-success'
                                        title='Отписаться'
                                        onClick={() => handleUnsubscribe(row.id)}
                                    />
                                    <MdNotificationsOff
                                        className='notification notification-off text-danger'
                                        title='Отписаться'
                                        onClick={() => handleUnsubscribe(row.id)}
                                    />
                                </>
                        }
                        else {
                            return loadingTriggerSubId === row.id ?
                                <span className='spinner-border spinner-border-sm mx-auto' /> :
                                <MdNotifications
                                    className='notification notification-none text-secondary'
                                    title='Подписаться'
                                    onClick={() => handleSubscribe(row.id)}
                                />
                        }
                    }
                }
            }
        ]
    }, [usersWithMessenger, usersIsFetching, currentUser, loadingTriggerSubId])

    return (
        <main className='error-triggers-page'>
            <PageTitle
                title='Триггеры'
                reloadPage={() => getErrorTriggers()}
                isLoading={errorTriggersIsLoading}
                pageUpdateTime={!errorTriggersIsLoading && errorTriggersReceivingTime ?
                    new Date(errorTriggersReceivingTime).toLocaleString().replace(',', "") :
                    ""}
            />

            <RsuiteTable
                data={errorTriggers?.data}
                columns={groupedErrorsColumns}
                withTotalRows
                virtualized
                loading={errorTriggersIsLoading}
                isError={errorTriggersIsError}
                shouldUpdateScroll={false}
            /> 

        </main>
    )
})

export default ErrorTriggersPage