import { memo } from 'react'
import { ElementVariant, FormElemTypes } from './FormElemTypes'
import RadioInput from './form-elements/RadioInput'
import Select from './form-elements/Select'
import Checkbox from './form-elements/Checkbox'
import DefaultInputElem from './form-elements/DefaultInput'
import DropDown from './form-elements/DropDown'
import TextAreaFormElem from './form-elements/TextAreaFormElem'
import Captcha from "./form-elements/Captcha";

type FormElemsSwitcherProps = {
    element: ElementVariant,
    containerRef?: React.MutableRefObject<HTMLDivElement | null>
}

const FormElemsSwitcher = memo(({ element, containerRef }: FormElemsSwitcherProps) => {
    switch (element.type) {
        case FormElemTypes.radio:
            return <RadioInput {...element}
                setDefaultThenClickOutsideParams={containerRef ? { containerRef: containerRef } : undefined}
            />
        case FormElemTypes.select:
            return <Select
                {...element}
                setDefaultThenClickOutsideParams={containerRef ? { containerRef: containerRef } : undefined}
            />
        case FormElemTypes.checkbox:
            return <Checkbox
                {...element}
                setDefaultThenClickOutsideParams={containerRef ? { containerRef: containerRef } : undefined}
            />
        case FormElemTypes.dropDown:
            return <DropDown
                {...element}
                setDefaultThenClickOutsideParams={containerRef ? { containerRef: containerRef } : undefined}
            />
        case FormElemTypes.textArea:
            return <TextAreaFormElem {...element}
                setDefaultThenClickOutsideParams={containerRef ? { containerRef: containerRef } : undefined}
            />
        case FormElemTypes.captcha:
            return <Captcha {...element}
                setDefaultThenClickOutsideParams={containerRef ? { containerRef: containerRef } : undefined}
            />

        default:
            return <DefaultInputElem
                {...element}
                setDefaultThenClickOutsideParams={containerRef ? { containerRef: containerRef } : undefined}
            />;
    }
})

export default FormElemsSwitcher