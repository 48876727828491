export enum RegionProperties {
    id="id",
    name="name",
    timezone="timezone",
    baseUrl="baseUrl",
    managerUrl="managerUrl",
    useAuth="useAuth",
    userName="userName",
    userPassword="userPassword"
}

export interface IRegion {
    [RegionProperties.id]: number,
    [RegionProperties.name]: string,
    [RegionProperties.timezone]: string,
    [RegionProperties.baseUrl]: string,
    [RegionProperties.managerUrl]: string,
    [RegionProperties.useAuth]: boolean,
    [RegionProperties.userName]: string,
    [RegionProperties.userPassword]: string
}

export enum RegionPropertiesNames {
    id="id",
    name="Имя",
    timezone="GMT",
    baseUrl="Ссылка",
    managerUrl="Сервер-менеджер",
    useAuth="Авторизация",
    userName="Логин",
    userPassword="Пароль"
}