import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { defaultBaseQueryParams } from "../constants/defaultBaseQueryParams";
import { IRegion } from "../models/entities/IRegion";
import { IDefaultResponse } from "../models/responses/IDefaultResponse";
import { IDefaultDataResponse } from "../models/responses/IDefaultDataResponse";

export const regionApi = createApi({
    reducerPath: 'regionApi',
    baseQuery: fetchBaseQuery(defaultBaseQueryParams),
    tagTypes: ['Region'],
    endpoints: build => ({
        getRegions: build.query<IDefaultDataResponse<IRegion[]>, void>({
            query: () => ({
                url: `/List/Get?mode=REGIONS`,
                method: "GET"
            }),
            providesTags: (result) =>
                result?.data ? [
                    ...result.data.map(({ id }) => ({ type: 'Region' as const, id })),
                    { type: 'Region', id: 'LIST' },
                ]
                    : [{ type: 'Region', id: 'LIST' }]
        }),
        createRegion: build.mutation<IDefaultDataResponse<IRegion>, IRegion>({
            query: (row) => ({
                url: `/List/CreateRegion`,
                method: "POST",
                body: row
            }),
            invalidatesTags: [{ type: 'Region', id: 'LIST' }]
        }),
        updateRegion: build.mutation<IDefaultResponse, IRegion>({
            query: (row) => ({
                url: `/List/UpdateRegion`,
                method: "PUT",
                body: row
            }),
            invalidatesTags: [{ type: 'Region', id: 'LIST' }]
        }),
        deleteRegion: build.mutation<IDefaultResponse, number>({
            query: (id) => ({
                url: `/List/DeleteRegion/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: 'Region', id: 'LIST' }]
        }),
    })
})

export const {
    useGetRegionsQuery,
    useLazyGetRegionsQuery,
    useCreateRegionMutation,
    useUpdateRegionMutation,
    useDeleteRegionMutation
} = regionApi;