import React, { useEffect, useMemo, useRef } from 'react'
import { navItems } from "../../../constants/navItems";
import NavItemWithChilds from "./NavItemWithChilds";
import NavItem from "./NavItem";
import { useAppSelector } from '../../../hooks/redux';
import { useLocation } from 'react-router-dom';
import useShowing from '../../../hooks/showing';
import { MdOutlineFiberNew } from 'react-icons/md';
import { RSwal } from '../../../constants/sweetAlert';
import PaginationList from '../../pagination-list/PaginationList';
import { setCurrentUpdateHistoryIsViewed, useLazyGetUpdateHistoryQuery } from '../../../services/UpdateHistoryService';
import { IUpdateHistoryItem } from '../../../models/entities/IUpdateHistoryItem';
import { dateToHuman } from '../../../utils/utils';

type NavigationProps = {
    navTogglerRef: React.RefObject<HTMLDivElement>
}

function Navigation({ navTogglerRef }: NavigationProps) {
    const navRef = useRef<HTMLDivElement>(null);

    const currentUser = useAppSelector(state => state.user.currentUserState);

    const location = useLocation();

    const currentPath = useMemo(() => location.pathname.replace(process.env.PUBLIC_URL, ""), [location]);

    useShowing({
        togglerRef: navTogglerRef,
        toggleTargetRef: navRef,
        closeOnClickOutside: true,
        overflowAfterTransEnd: 'auto'
    });

    const [getUpdateHistory, { data: updateHistory, isUninitialized }] = useLazyGetUpdateHistoryQuery();

    useEffect(()=>{
        if(currentUser && isUninitialized) {
            getUpdateHistory();
        }
    }, [currentUser])

    const newUpdateHistory = useMemo(()=>{
        if(updateHistory?.success && currentUser) {
            return updateHistory.data.filter(item=>!item.userIds.includes(currentUser.id));
        }
        return [];
    }, [updateHistory])

    const showUpdateHistory = (currentUpdateHistory: IUpdateHistoryItem[], isOldHistory: boolean = false) => {
        RSwal.fire({
            title: 'Что нового?',
            html: <PaginationList
                list={currentUpdateHistory?.map(item => <>
                    <div className='border-theme-color border-bottom pb-1'>
                        {dateToHuman(item.date)}
                    </div>
                    <div className='pagination-list'>
                        {item.change}
                    </div>
                </>)}
            />,
            showCloseButton: true,
            showDenyButton: isOldHistory ? false : true,
            denyButtonText: "Показать последние",
            denyButtonColor: "dodgerblue"
        }).then(res =>{
            if(res.isDenied && updateHistory) {
                showUpdateHistory(updateHistory?.data, true);
            }
            if(!isOldHistory && currentUpdateHistory.length) {
                setCurrentUpdateHistoryIsViewed(currentUpdateHistory.map(item=>item.id));
            }
        })
    }

    return (
        <div
            className="navigation"
            ref={navRef}
        >
            {navItems.map((navItem, idx) =>
                (!navItem.onlyAdmin || (navItem.onlyAdmin && currentUser?.role?.isAdmin)) &&
                (navItem.childs ?
                    <NavItemWithChilds
                        key={`nav_item_${idx}`}
                        navItem={navItem}
                        navigationMenuRef={navRef}
                        currentPath={currentPath}
                    /> :
                    <NavItem
                        key={`nav_item_${idx}`}
                        navItem={navItem}
                        navigationMenuRef={navRef}
                        isActive={navItem.path !== "/" ?
                            (typeof navItem.path === 'string' ?
                                currentPath.toLowerCase().includes(navItem.path?.toLowerCase())
                                : false) :
                            currentPath === navItem.path}
                    />)
            )}

            <div className={"navigation-link last-updates-btn text-secondary " + (newUpdateHistory.length ? "active" : "")}>
                <MdOutlineFiberNew
                    className='navigation-link-icon'
                    title='Что нового?'
                    onClick={()=>showUpdateHistory(newUpdateHistory)}
                />
            </div>
        </div>
    )
}

export default React.memo(Navigation)
