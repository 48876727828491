import React, { ReactNode, memo, useCallback, useMemo, useRef } from 'react'
import { useGetErrorQuery, useLazyGetIdenticalErrosQuery } from '../../../services/ErrorService';
import { useParams } from 'react-router-dom';
import { ErrorTypesIcons } from '../../../models/entities/EErrorTypes';
import { MdContentCopy, MdExpandMore, MdManageSearch, MdMoreVert } from 'react-icons/md';
import { TiArrowBack } from 'react-icons/ti';
import bugzillaIcon from '../../../assets/images/bugzilla.png'
import { RSwal } from '../../../constants/sweetAlert';
import LoadingBlock from '../../../components/LoadingBlock';
import ErrorMsg from './ErrorMsg';
import { IdenticalErrorsRequest } from '../../../models/requests/IdenticalErrorsRequest';
import RsuiteTable, { ColumnsList } from '../../../components/rsuite-table/RsuiteTable';
import { RowDataType } from 'rsuite-table';
import { IError } from '../../../models/entities/IError';
import { history, nameof, parseToTableCells, renderObject } from '../../../utils/utils';
import { IErrorBriefInfo } from '../../../models/responses/IErrorBriefInfo';
import { useAppSelector } from '../../../hooks/redux';
import BugCreationModal from './BugCreationModal';
import { useCreateBugMutation, useGetBugzillaProductInfoQuery, useLazyGetBugzillaUserQuery } from '../../../services/BugzillaService';
import { logout } from '../../../store';
import { ICreateBug } from '../../../models/requests/ICreateBug';
import { defaultPollingInterval } from '../../../store/globalVariables';

const ErrorFullInfo = memo(({ monitoringPageRef }: { monitoringPageRef: React.RefObject<HTMLDivElement> }) => {
    const currentUser = useAppSelector(state => state.user.currentUserState);

    const [getBugzillaUser] = useLazyGetBugzillaUserQuery();

    const [createBug] = useCreateBugMutation();

    const { data: bugzillaProductInfo } = useGetBugzillaProductInfoQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const [getIdenticalErrors] = useLazyGetIdenticalErrosQuery({
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const selectedErrorId = useParams<{ id?: string }>().id;

    const { data: errorFullInfo, isFetching: errorFullInfoIsLoading } = useGetErrorQuery(Number(selectedErrorId), {
        skip: typeof selectedErrorId !== 'string'
    });

    const errorData = errorFullInfo ? { ...errorFullInfo.data } : undefined;

    const columns: ColumnsList = useMemo(() => [
        {
            columnProps: {
                minWidth: 66,
                width: 66
            },
            headerProps: {
                children: ''
            },
            cellProps: {
                dataKey: nameof<IError>('error_type'),
                children: (error: RowDataType | IErrorBriefInfo) => {
                    const errorTypeIcon = ErrorTypesIcons[error.error_type as keyof typeof ErrorTypesIcons];
                    const errorDateTime = error.datemsk.split('T');
                    return (
                        <a
                            href={`${window.location.origin + process.env.PUBLIC_URL}/Monitoring/${error.id}`}
                            target='_blank'
                            className="monitoring-panel-error-content text-center p-1"
                        >
                            {errorTypeIcon}
                            <div className="monitoring-panel-error-date error">
                                {errorDateTime[0]}
                                <br />
                                {errorDateTime[1].split('.')[0]}
                            </div>
                        </a>)
                }
            }
        },
        {
            columnProps: {
                flexGrow: 1
            },
            headerProps: {
                children: ''
            },
            cellProps: {
                dataKey: nameof<IError>('exception_name'),
                children: (error: RowDataType | IError) => {
                    return <a
                        href={`${window.location.origin + process.env.PUBLIC_URL}/Monitoring/${error.id}`}
                        target='_blank'
                        className="monitoring-panel-error-content text-start"
                    >
                        <div className="monitoring-panel-error-exception-name">
                            {error.exception_name}
                        </div>
                        <div className="monitoring-panel-error-complex">
                            <div className="monitoring-panel-error-region">
                                {error.region}
                            </div>
                            <div className="monitoring-panel-error-complex-type">
                                {error.complex_type}
                            </div>
                        </div>
                        <div className="monitoring-panel-error-exception-msg">
                            {error.exception_msg}
                        </div>
                    </a>
                }
            }
        }
    ], [selectedErrorId]);

    const handleGetIdenticalErrors = () => {
        RSwal.fire({
            title: "Введите количество часов, за которые хотите найти",
            input: "number",
            inputValidator: (value) => {
                const currValue: number = Number(value);
                if (!currValue) {
                    return 'Введите количество часов'
                }
                if (currValue < 1) return "Минимальное значение 1";
                if (currValue > 168) return "Максимальное значение 168";
                return null;
            },
            showDenyButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: `Найти`,
            denyButtonText: `Отменить`,
            showCloseButton: true,
            customClass: {
                actions: 'border-0'
            },
            preConfirm: async (period: string) => {
                var reqBody: IdenticalErrorsRequest = {
                    id: Number(selectedErrorId),
                    period: Number(period)
                }
                var response = {
                    period: period,
                    response: await getIdenticalErrors(reqBody, true).unwrap()
                }
                return response
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value?.response.success) {
                    if (result.value.response.data.length <= 1) {
                        RSwal.fire({
                            icon: 'info',
                            title: `Идентичных ошибок за ${result.value.period}ч. не обнаружено`,
                            showCloseButton: true
                        })
                    }
                    else {
                        RSwal.fire({
                            title: `Идентичные ошибки за ${result.value.period}ч. (${result.value.response.data.length}шт)`,
                            html: <div className="monitoring-panel text-start">
                                <RsuiteTable
                                    data={result.value.response.data}
                                    columns={columns}
                                    showHeader={false}
                                    virtualized={true}
                                    rowHeight={66}
                                />
                            </div>,
                            showCloseButton: true
                        })
                    }
                }
                else {
                    RSwal.fire({
                        icon: 'error',
                        title: "Ошибка получения идентичных ошибок",
                        showCloseButton: true
                    })
                }
            }
        });
    }

    const bugNameRef = useRef<HTMLTextAreaElement>(null);
    const bugVersionRef = useRef<HTMLSelectElement>(null);
    const bugComponentRef = useRef<HTMLSelectElement>(null);

    const openBugCreationModal = useCallback(async () => {
        if(bugzillaProductInfo?.data && errorData && currentUser) {
            RSwal.fire({
                title: "Создание бага в Bugzilla",
                showDenyButton: true,
                denyButtonText: "Отменить",
                showLoaderOnConfirm: true,
                confirmButtonText: "Создать",
                showCloseButton: true,
                customClass: {
                    popup: 'swal2-modal-md-html'
                },
                html: <BugCreationModal
                    bugNameRef={bugNameRef}
                    bugVersionRef={bugVersionRef}
                    bugComponentRef={bugComponentRef}
                    bugzillaProductInfo={bugzillaProductInfo}
                    errorData={errorData}
                    currentUser={currentUser}
                />,
                preConfirm: async () => {
                    if (!currentUser) {
                        logout();
                        return null;
                    }
                    
                    if (!bugNameRef.current?.value || !bugVersionRef.current?.value || !bugComponentRef.current?.value) {
                        RSwal.fire({
                            icon: 'warning',
                            title: "Название, Версия и Компонент не должны быть пусты",
                            showCloseButton: true
                        }).then((res) => res.isConfirmed ? openBugCreationModal() : null);
                        return null;
                    }

                    let cfCustomer = "";
                    let bugzillaUserInfo = "";

                    if (!currentUser?.role?.isAdmin) {
                        if (currentUser.userSettings?.bugzilla_email) {
                            const bugzillaUserRoles = await getBugzillaUser(currentUser.userSettings?.bugzilla_email).unwrap();
                            if (!bugzillaUserRoles.success || !bugzillaUserRoles.data) {
                                RSwal.fire({
                                    icon: 'error',
                                    html: "Почта неверна или не зарегистрирована на" +
                                        <a
                                            href='https://portal.keysystems.ru/'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            portal.keysystems.ru
                                        </a>,
                                    confirmButtonText: "К настройкам",
                                    showDenyButton: true,
                                    denyButtonText: "Отменить"
                                }).then(res => res.isConfirmed && history.navigate ?
                                    history.navigate("/Settings")
                                    : null);

                                return null;
                            }
                            
                            const bugzillaUser = bugzillaUserRoles.data[Object.keys(bugzillaUserRoles.data)[0]];
                            cfCustomer = bugzillaUser.org
                            bugzillaUserInfo = Object.keys(bugzillaUser).length > 0 ?
                                `\n\n----------------------------------------\nОт: ${bugzillaUser.realname} // <${bugzillaUser.email}>\nОрганизация: ${bugzillaUser.org}\nРегион: ${bugzillaUser.region_id} - ${bugzillaUser.region_name}\nГород: ${bugzillaUser.city}\n----------------------------------------`
                                : "";
                        }
                        else {
                            RSwal.fire({
                                icon: 'info',
                                title: "Для создания бага нужно привязать почту от аккаунта баг-трекинга Bugzilla в настройках пользователя мониторинга",
                                confirmButtonText: "К настройкам",
                                showDenyButton: true,
                                denyButtonText: `Отменить`,
                                showCloseButton: true
                            }).then(res => res.isConfirmed && history.navigate ?
                                history.navigate("/Settings")
                                : null);

                            return null;
                        }
                    }

                    if (errorData) {
                        const error_headers =
                            (errorData.region ? `\nРегион: ${errorData.region}` : "")
                            + (errorData.complex_type ? `\nСистема: ${errorData.complex_type}` : "")
                            + (errorData.version ? `\nВерсия: ${errorData.version}` : "")
                            + (errorData.complex_user ? `\nПользователь: ${errorData.complex_user}` : "")
                            + (errorData.datemsk ? `\nВремя на сервере Мониторинга (МСК): ${errorData.datemsk}` : "")
                            + (errorData.dater ? `\nВремя на сервере комплекса (Регион): ${errorData.dater}` : "")
                            + (errorData.url ? `\nСсылка: ${errorData.url}` : "")
                            + "\n";



                        const bugDescription = errorData.msg ?
                            (
                                "\n========================================\n" +
                                errorData.msg.replace(/<\/?[&br]+[^>]*>/g, '\n').replace(/<\/?[&b]+[^>]*>/g, '') +
                                "\n========================================\n"
                            )
                            : "";

                        const parsedServiceInfo = renderObject({ obj: JSON.parse(errorData.service_info) });
                        const parsedClientInfo = renderObject({ obj: JSON.parse(errorData.client_info) });
                        const parsedOtherInfo = renderObject({ obj: JSON.parse(errorData.other_details) });

                        const model: ICreateBug = {
                            version: bugVersionRef.current.value,
                            component: bugComponentRef.current.value,
                            summary: bugNameRef.current.value,
                            description: `${window.location.href}\n` +
                                `Пользователь мониторинга: ${currentUser.username}\n` +
                                error_headers +
                                bugDescription +
                                parsedServiceInfo +
                                parsedClientInfo +
                                (errorData.client_error_id ? `ClientErrorID: ${errorData.client_error_id}\n` : "") +
                                parsedOtherInfo +
                                bugzillaUserInfo,
                            cf_customer: cfCustomer,
                            cf_external_user: currentUser.userSettings?.bugzilla_email ?? ''
                        };

                        return await createBug(model).unwrap();
                    }
                }
            }).then((result) => {
                if (result.isConfirmed && result.value) {
                    if (result.value.success && result.value.data) {
                        RSwal.fire({
                            icon: 'success',
                            title: 'Баг создан',
                            html: <a
                                href={result.value.data}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {result.value.data}
                            </a>
                        })
                    }
                    else {
                        RSwal.fire({
                            icon: 'error',
                            title: result.value.message
                        })
                    }
                }
            })
        }
    },[bugzillaProductInfo, errorData, currentUser]);

    const otherDetails = useMemo(()=>{
        if(errorData) {
            const currentDetails: ReactNode[] = [];

            if(errorData.os) {
                currentDetails.push(<tr key={0}>
                    <th>os:</th>
                    <td>{errorData.os}</td>
                </tr>)
            }
            if(errorData.browser) {
                currentDetails.push(<tr key={1}>
                    <th>browser:</th>
                    <td>{errorData.browser}</td>
                </tr>)
            }
            if(errorData.screen_resolution) {
                currentDetails.push(<tr key={2}>
                    <th>screen_resolution:</th>
                    <td>{errorData.screen_resolution}</td>
                </tr>)
            } 
            if(errorData.service_info) {
                try {
                    currentDetails.push(parseToTableCells(errorData.service_info))
                }
                catch {
                    console.error(`Невозможно распарсить serviceInfo ошибки. Значение: ${errorData.service_info}`)
                }
            }
            if(errorData.client_info) {
                try {
                    currentDetails.push(parseToTableCells(errorData.client_info))
                }
                catch {
                    console.error(`Невозможно распарсить clientInfo ошибки. Значение: ${errorData.client_info}`)
                }
            }
            if(errorData.client_error_id) {
                currentDetails.push(<tr key={3}>
                    <th>ClientErrorID:</th>
                    <td>{errorData.client_error_id}</td>
                </tr>)
            }
            if(errorData.other_details) {
                try {
                    currentDetails.push(parseToTableCells(errorData.other_details))
                }
                catch {
                    console.error(`Невозможно распарсить otherDetails ошибки. Значение: ${errorData.other_details}`)
                }
            }

            return currentDetails.length ? currentDetails : undefined;
        } 
    }, [errorData]);

    const showUrlParams = (urlParams: IError['urlParams']) => {
        RSwal.fire({
            showCloseButton: true,
            title: "Параметры ссылки",
            html: <>
                {urlParams?.map((param, idx)=>
                    <div 
                        className={
                            'd-flex-center-unset flex-nowrap w-100 py-2 ' + 
                            (idx !== 0 ? 'border-top border-theme-color' : '')
                        }
                    >
                        <div className='text-nowrap me-3'>
                            {param.key}:
                        </div>
                        <code className='text-pre-wrap text-start mx-auto text-break'>
                            {param.value}
                        </code>
                    </div>)}
            </>
        })
    }
    
    const showUserEvents = (userEvents: IError['user_events']) => {
        RSwal.fire({
            showCloseButton: true,
            title: "Действия пользователя",
            html: <>
                <div className='error-info-user-events'> {userEvents} </div>
            </>,
            width: '55em',
        })
    };

    return (
        <div className='error-full-info'>
            <div
                className="error-full-info-back-btn btn btn-primary justify-content-center mb-3"
                onClick={() => monitoringPageRef.current ? monitoringPageRef.current.scrollLeft = 0 : null}
            >
                <TiArrowBack className='me-1' />
                К списку ошибок
            </div>

            {selectedErrorId && (!errorFullInfo || !errorFullInfo.success===false) ?
                <>
                    <div className="error-full-info-header">
                        {errorData && errorFullInfo?.success && !errorFullInfoIsLoading ?
                            <>
                                <div className="error-full-info-header-icon">
                                    {ErrorTypesIcons[errorData.error_type as keyof typeof ErrorTypesIcons]}
                                </div>
                                <div className="row mx-0 flex-grow-1 overflow-hidden">
                                    <h3
                                        className="col-12 px-0 text-truncate"
                                        title={errorData.exception_name}
                                    >
                                        {errorData.exception_name}
                                    </h3>
                                    <h6
                                        className={"col-12 px-0 text-truncate text-info " + (errorData.exception_msg ? 'my-1' : '')}
                                        title={errorData.exception_msg}
                                    >
                                        {errorData.exception_msg}
                                    </h6>
                                </div>

                                <div className="error-full-info-header-more">
                                    <MdMoreVert
                                        className="dropdown"
                                        type="button"
                                        id="errorFullInfoHeaderMore"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="true"
                                    />
                                    <ul
                                        className="dropdown-menu shadow"
                                        aria-labelledby="errorFullInfoHeaderMore"
                                    >
                                        <li
                                            onClick={() => {
                                                window.navigator.clipboard.writeText(
                                                    `${window.location.origin + process.env.PUBLIC_URL}/Monitoring/${errorData.id}`
                                                );
                                                RSwal.fire({
                                                    toast: true,
                                                    icon: 'success',
                                                    position: 'top',
                                                    title: 'Тикет скопирован!',
                                                    showConfirmButton: false,
                                                    timer: 1500,
                                                    timerProgressBar: true
                                                });
                                            }}
                                        >
                                            Скопировать тикет
                                            <MdContentCopy className='ms-1' />
                                        </li>
                                        <li onClick={() => handleGetIdenticalErrors()}>
                                            Найти идентичные
                                            <MdManageSearch className='ms-1' />
                                        </li>
                                        <li onClick={() => openBugCreationModal()}>
                                            Создать баг
                                            <img src={bugzillaIcon} className='ms-1' />
                                        </li>
                                    </ul>
                                </div>
                            </>
                            : <LoadingBlock
                                height={80}
                                rounded
                            />}
                    </div>

                    <div className="error-full-info-details">
                        {errorData && errorFullInfo?.success && !errorFullInfoIsLoading ?
                            <>
                                <div className="d-flex flex-nowrap">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Система:</th>
                                                <td>{errorData.complex_type}</td>
                                            </tr>
                                            <tr>
                                                <th>Версия:</th>
                                                <td>{errorData.version}</td>
                                            </tr>
                                            <tr>
                                                <th>Пользователь:</th>
                                                <td>{errorData.complex_user}</td>
                                            </tr>
                                            <tr className='d-table-row d-md-none'>
                                                <th>Время на сервере Мониторинга (МСК):</th>
                                                <td>{errorData.datemsk}</td>
                                            </tr>
                                            <tr className='d-table-row d-md-none'>
                                                <th>Время на сервере комплекса (Регион):</th>
                                                <td>{errorData.dater}</td>
                                            </tr>
                                            <tr className='d-table-row d-md-none'>
                                                <th>Регион:</th>
                                                <td>{errorData.region}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table className='d-md-table d-none ms-md-2'>
                                        <tbody>
                                            <tr>
                                                <th>Время на сервере Мониторинга (МСК):</th>
                                                <td>{errorData.datemsk}</td>
                                            </tr>
                                            <tr>
                                                <th>Время на сервере комплекса (Регион):</th>
                                                <td>{errorData.dater}</td>
                                            </tr>
                                            <tr>
                                                <th>Регион:</th>
                                                <td>
                                                    {errorData.region}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                {/* Ссылка ошибки */}
                                {errorData.url &&
                                    <div className="w-100 m-0 row rounded-bottom d-flex-center-between flex-nowrap">
                                        <div className='error-full-info-details-url col-auto p-0 fw-bold'>
                                            Ссылка:
                                        </div>
                                        <div
                                            className='col text-truncate'
                                            title={"Перейти по ссылке " + errorData.url}
                                        >
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={errorData.url ? errorData.url : ''}
                                            >
                                                {errorData.url}
                                            </a>
                                        </div>
                                        {errorData.urlParams &&
                                            <div 
                                                className="btn btn-sm btn-primary max-w-min py-0 px-2"
                                                onClick={()=>showUrlParams(errorData.urlParams)}    
                                                style={{height: 21}}
                                            >
                                                Параметры
                                            </div>
                                        }
                                    </div>}

                                {/*Действия пользователя */}
                                {errorData.user_events &&
                                    <div className="w-100 m-0 rounded-bottom d-flex-center-unset">
                                        <div className='error-full-info-details-url col-auto p-0 mr-1 fw-bold me-2'>
                                            Действия пользователя:
                                        </div>
                                        <div
                                            className="btn btn-sm btn-primary max-w-min py-0 px-2"
                                            onClick={() => showUserEvents(errorData.user_events)}
                                            style={{height: 21}}
                                        >
                                            Показать
                                        </div>
                                    </div>
                                }

                                {/* Остальная информация об ошибке */}
                                {otherDetails &&
                                    <div className="error-full-info-other-details">
                                        <table>
                                            <tbody>
                                                {otherDetails}
                                            </tbody>
                                        </table>
                                </div>}
                            </>
                            : <LoadingBlock
                                rounded
                            />}
                    </div>
                                
                    {/* Кнопка отображения полной информации об ошибке */}
                    {errorData && errorFullInfo?.success && !errorFullInfoIsLoading && otherDetails && 
                        <span 
                            className='show-other-details-btn'
                            title='Отобразить полную информацию'   
                            onClick={(e)=>{
                                const elem = e.currentTarget;
                                elem.classList.toggle("active");
                                
                                if(elem.classList.contains("active")) {
                                    elem.title = 'Скрыть полную информацию';   
                                }
                                else elem.title = 'Отобразить полную информацию';   
                            }} 
                        >
                            <MdExpandMore />
                        </span>}

                    <div className="error-full-info-msg">
                        {errorData && errorFullInfo?.success && !errorFullInfoIsLoading ?
                            <ErrorMsg errorMsg={errorData.msg} />
                            : <LoadingBlock rounded />}
                    </div>
                </>
            : (errorFullInfo?.success === false ? 
                <h5>{errorFullInfo.message}</h5> : 
                <h5>Выберите ошибку из списка</h5>)}
        </div>
    )
})

export default ErrorFullInfo