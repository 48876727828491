import React, { memo, useCallback } from 'react'
import './items-panel.scss'
import { MdFilterAlt, MdRefresh, MdSearch } from 'react-icons/md'
import RsuiteTable, { ColumnsList } from '../rsuite-table/RsuiteTable'
import LoadingBlock from '../LoadingBlock'
import FiltersMenu, { FiltersMenuProps, TSelectFilters } from '../filters-menu/FiltersMenu'
import { TableProps } from 'rsuite-table'

export interface ItemsPanelProps extends TableProps<any, any> {
    filtersMenuTogglerRef: React.RefObject<HTMLDivElement>,
    filtersMenuRef: React.RefObject<HTMLDivElement>,
    panelIsLoading?: boolean,
    filtersIsLoading?: boolean,
    searchStringRef?: React.RefObject<HTMLInputElement>,
    filterMenu: {
        listFilters?: FiltersMenuProps['listFilters'],
        customFilters?: FiltersMenuProps['customFilters'],
        handleRefresh?: () => void,
        handleSubmit: () => void,
        handleCancel: () => void
    },
    panelContent: {
        data?: any[],
        columns: ColumnsList,
        onRowClick?: (row: any, event: React.MouseEvent<Element, MouseEvent>) => void
    },
    rowHeight: number,
    onScrollEnd?: () => void
}

function ItemsPanel({
    filtersMenuTogglerRef,
    filtersMenuRef,
    panelIsLoading,
    filtersIsLoading,
    searchStringRef,
    filterMenu,
    panelContent,
    rowHeight,
    onScrollEnd,
    ...tableProps
}: ItemsPanelProps) {
    return (
        <div className="items-panel">
            <div className="items-panel-nav">
                {!panelIsLoading ?
                    <div
                        className="items-panel-nav-btn"
                        title='Обновить список'
                        onClick={()=>filterMenu.handleSubmit()}
                    >
                        <MdRefresh className='pointer-events-none' />
                    </div>
                    : <div className='items-panel-nav-btn pointer-events-none'>
                        <span className="spinner-border spinner-border-sm" />
                    </div>}

                <div
                    className="items-panel-nav-btn items-panel-nav-btn-filters"
                    ref={filtersMenuTogglerRef}
                >
                    {!filtersIsLoading ?
                        <MdFilterAlt className='pointer-events-none' /> :
                        <span className="spinner-border spinner-border-sm pointer-events-none" />}
                </div>

                {searchStringRef?.current &&
                    <>
                        <input
                            ref={searchStringRef}
                            className='items-panel-nav-search'
                            type="search"
                            placeholder="Поиск"
                            required
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') filterMenu.handleSubmit();
                                if (event.key === 'Escape') {
                                    event.currentTarget.value = "";
                                    filterMenu.handleSubmit();
                                }
                            }}
                            onBlur={(e) => {
                                if (!e.currentTarget.value && e.currentTarget.classList.contains('filtering')) {
                                    filterMenu.handleSubmit();
                                    searchStringRef.current?.classList.remove('filtering')
                                }
                            }}
                        />

                        {!panelIsLoading ?
                            <MdSearch
                                className="items-panel-nav-btn"
                                title='Найти'
                                onClick={()=>filterMenu.handleSubmit()}
                            />
                            : <div className='items-panel-nav-btn pointer-events-none'>
                                <span className="spinner-border spinner-border-sm" />
                            </div>}
                    </>}

            </div>

            <div className="items-panel-content">
                {panelContent.data ?
                    <RsuiteTable
                        data={panelContent.data}
                        columns={panelContent.columns}
                        showHeader={false}
                        virtualized={true}
                        rowHeight={rowHeight ?? 82}
                        onRowClick={panelContent.onRowClick}
                        onScrollEnd={onScrollEnd}
                        shouldUpdateScroll={false}
                        {...tableProps}
                    />
                    : <LoadingBlock />}

                <FiltersMenu
                    filtersMenuRef={filtersMenuRef}
                    filtersMenuTogglerRef={filtersMenuTogglerRef}
                    listFilters={filterMenu.listFilters}
                    customFilters={filterMenu.customFilters}
                    submitButton={{
                        name: "Применить",
                        handle: filterMenu.handleSubmit
                    }}
                    cancelButton={{
                        name: "Отменить",
                        handle: filterMenu.handleCancel
                    }}
                />
            </div>
        </div>
    )
}

export default memo(ItemsPanel)