import { memo, useMemo } from 'react'
import "./update-history.scss"
import PageTitle from '../../components/PageTitle'
import { useCreateUpdateHistoryItemMutation, useGetUpdateHistoryQuery } from '../../services/UpdateHistoryService'
import RsuiteTable, { ColumnsList } from '../../components/rsuite-table/RsuiteTable'
import { useAppSelector } from '../../hooks/redux'
import { dateToHuman, fetchDataAlert, nameof, showLoadingSwal } from '../../utils/utils'
import { IUpdateHistoryItem } from '../../models/entities/IUpdateHistoryItem'
import { MdAdd } from 'react-icons/md'
import { RSwal } from '../../constants/sweetAlert'
import Form from '../../components/Form/Form'
import { useGetAllUsersQuery } from '../../services/UserService'
import { ElementVariant, FormElemTypes } from '../../components/Form/FormElemTypes'
import { RowDataType } from 'rsuite-table'

const createElements: ElementVariant[] = [
    {
        type: FormElemTypes.textArea,
        name: nameof<IUpdateHistoryItem>('change'),
        label: "Изменение",
        htmlAttributes: {
            placeholder: "Что изменилось?",
            required: true
        }
    },
    {
        type: FormElemTypes.radio,
        name: nameof<IUpdateHistoryItem>('forAdmins'),
        label: "Для админов",
        defaultValue: false,
        items: [
            {
                label: 'Да',
                value: true
            },
            {
                label: 'Нет',
                value: false
            }
        ]
    }
]

const UpdateHistoryPage = memo(() => {
    const { data: updateHistory, isFetching, isError, refetch, fulfilledTimeStamp } = useGetUpdateHistoryQuery();

    const { data: users } = useGetAllUsersQuery();

    const [createUpdateHistoryItemFetch] = useCreateUpdateHistoryItemMutation();

    const currentUser = useAppSelector(state => state.user.currentUserState);

    const createUpdateHistoryItem = (data: IUpdateHistoryItem) => {
        showLoadingSwal("Создание...");

        createUpdateHistoryItemFetch(data).then(result => {
            fetchDataAlert({
                result: result,
                showSuccessMessage: true
            })
        })
    }

    const columns: ColumnsList = useMemo(() => {
        if (updateHistory?.data) {
            const currentCols: ColumnsList = [
                {
                    columnProps: {
                        fixed: true,
                        width: 80
                    },
                    headerProps: {
                        children: "id"
                    },
                    cellProps: {
                        dataKey: nameof<IUpdateHistoryItem>('id')
                    }
                },
                {
                    columnProps: {
                        sortable: true,
                        flexGrow: 1,
                        fixed: true
                    },
                    headerProps: {
                        children: "Дата"
                    },
                    cellProps: {
                        dataKey: nameof<IUpdateHistoryItem>('date'),
                        children: (rowData: RowDataType) => dateToHuman((rowData as IUpdateHistoryItem).date)
                    }
                },
                {
                    columnProps: {
                        sortable: true,
                        flexGrow: 2,
                        fullText: true
                    },
                    headerProps: {
                        children: "Изменения"
                    },
                    cellProps: {
                        dataKey: nameof<IUpdateHistoryItem>('change')
                    },
                    searchable: true
                },
                {
                    columnProps: {
                        sortable: true,
                        flexGrow: 1,
                        fullText: true
                    },
                    headerProps: {
                        children: "Просмотрели"
                    },
                    cellProps: {
                        dataKey: nameof<IUpdateHistoryItem>('userIds'),
                        children: (rowData) => {
                            const row = rowData as IUpdateHistoryItem;
    
                            if (!row.userIds || row.userIds.length === 0 || !users?.data) {
                                return "Никто"
                            }
                            else {
                                return <select
                                    name={"updateHistoryUsers" + row.id}
                                    className='mx-2 w-100'
                                >
                                    <option className='d-none'>Просмотрели</option>
    
                                    {row.userIds.map((userId, idx) => {
                                        const user = users.data?.find(user => user.id === userId);
        
                                        if (user) {
                                            return <option
                                                key={idx}
                                                value={user.id}
                                                disabled
                                            >
                                                {`${user.lastName} ${user.firstName}`}
                                            </option>
                                        }
                                        else {
                                            return;
                                        }
                                    })}
                                </select>
                            }
                        }
                    },
                    searchable: true
                },
                {
                    columnProps: {
                        sortable: true,
                        fullText: true
                    },
                    headerProps: {
                        children: "Для админов"
                    },
                    cellProps: {
                        dataKey: nameof<IUpdateHistoryItem>('forAdmins'),
                        children: (rowData: RowDataType) => (rowData as IUpdateHistoryItem).forAdmins ? "Да" : "Нет"
                    },
                    searchable: true
                }
            ];

            if (currentUser?.role.isSuperAdmin) {
                currentCols[0].headerProps = {
                    children: <div className="rs-table-crud-icon">
                        <MdAdd
                            title="Добавить запись"
                            onClick={() =>
                                RSwal.fire({
                                    title: "Добавление записи",
                                    html: <Form
                                        isSwalForm
                                        // Элементы формы модального окна
                                        elements={createElements}
                                        submitButton={{
                                            name: "Добавить",
                                            handleClick: (data: IUpdateHistoryItem) => createUpdateHistoryItem(data)
                                        }}
                                        denyButton={{
                                            name: "Отменить",
                                            autoFocus: true,
                                            handleClick: () => RSwal.close()
                                        }}
                                    />,
                                    showConfirmButton: false,
                                    showCloseButton: true
                                })} 
                        />
                    </div>
                }
            }

            return currentCols;
        }
        else return []
    }, [updateHistory?.data, users]);

    return (
        <main>
            <PageTitle
                title='История обновлений'
                reloadPage={refetch}
                isLoading={isFetching}
                pageUpdateTime={!isFetching && fulfilledTimeStamp ?
                    new Date(fulfilledTimeStamp).toLocaleString().replace(',', "") :
                    ""}
            />

            <RsuiteTable
                data={updateHistory?.data}
                columns={columns}
                isError={isError}
                loading={isFetching}
            />
        </main>
    )
})

export default UpdateHistoryPage