import React, { memo, useCallback, useLayoutEffect, useMemo, useRef } from 'react'
import { MdFilterAlt, MdRefresh, MdSearch } from 'react-icons/md'
import RsuiteTable, { ColumnsList } from '../../../components/rsuite-table/RsuiteTable'
import { IErrorBriefInfo } from '../../../models/responses/IErrorBriefInfo'
import { history, nameof } from '../../../utils/utils'
import { IError } from '../../../models/entities/IError'
import { RowDataType } from 'rsuite-table'
import { ErrorTypesIcons } from '../../../models/entities/EErrorTypes'
import { Link, useParams } from 'react-router-dom'
import { useLazyGetErrorsQuery, useGetFiltersQuery } from '../../../services/ErrorService'
import ErrorFilterMenu from '../ErrorFilterMenu/ErrorFilterMenu'
import LoadingBlock from '../../../components/LoadingBlock'
import { IErrorsFilters } from '../../../models/requests/IErrorsFilters'
import { defaultPollingInterval } from '../../../store/globalVariables'
import { IGetFilters } from '../../../models/responses/IGetFilters'

export interface MonitoringPanelLocState extends IErrorsFilters {
    startDateTypeDate?: Date,
    endDateTypeDate?: Date
}

const errorRowHeight = 82;

const MonitoringPanel = memo(({ monitoringPageRef }: { monitoringPageRef: React.RefObject<HTMLDivElement> }) => {
    const filtersMenuTogglerRef = useRef<HTMLDivElement>(null);
    const filtersMenuRef = useRef<HTMLDivElement>(null);

    const searchStringRef = useRef<HTMLInputElement>(null);
    const startDateRef = useRef<Date | null>(null);
    const endDateRef = useRef<Date | null>(null);
    const regionsRef = useRef<number[]>([]);
    const complexTypesRef = useRef<number[]>([]);
    const errorLevelsRef = useRef<number[]>([]);

    const selectedErrorId = useParams<{ id?: string }>().id;

    const [getErrors, { data: errors, isFetching: errorsIsLoading, fulfilledTimeStamp, isError: getErrorsIsError }] = useLazyGetErrorsQuery();

    const { data: filters, isFetching: filtersIsLoading } = useGetFiltersQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    useLayoutEffect(() => {
        let locState: MonitoringPanelLocState | null = history.location?.state;

        if (locState) {
            if (Array.isArray(locState.regions) && locState.regions.length > 0) {
                regionsRef.current = locState.regions;
            }

            if (Array.isArray(locState.complexTypes) && locState.complexTypes.length > 0) {
                complexTypesRef.current = locState.complexTypes;
            }

            if (Array.isArray(locState.errorLevels) && locState.errorLevels.length > 0) {
                errorLevelsRef.current = locState.errorLevels;
            }

            if (locState.startDateTypeDate instanceof Date) {
                startDateRef.current = locState.startDateTypeDate;
            }

            if (locState.endDateTypeDate instanceof Date) {
                endDateRef.current = locState.endDateTypeDate;
            }

            applyFilters();
        }
        else {
            getErrors({});
        }
    }, []);

    const columns: ColumnsList = useMemo(() => [
        {
            columnProps: {
                minWidth: 56,
                width: 56
            },
            headerProps: {
                children: nameof<IError>('error_type')
            },
            cellProps: {
                dataKey: nameof<IError>('error_type'),
                children: (error: RowDataType | IErrorBriefInfo) => {
                    const ErrorTypeIcon = ErrorTypesIcons[error.error_type as keyof typeof ErrorTypesIcons];
                    const errorDateTime = error.datemsk.split('T');

                    return <Link
                        to={`/Monitoring/${error.id}`}
                        className={`monitoring-panel-error-header ${selectedErrorId && Number(selectedErrorId) === error.id ? "selected-error" : ""}`}
                    >
                        {ErrorTypeIcon}
                        <div className="monitoring-panel-error-date error">
                            {errorDateTime[0]}
                            <br />
                            {errorDateTime[1].split('.')[0]}
                        </div>
                    </Link>
                }
            }
        },
        {
            columnProps: {
                flexGrow: 1
            },
            headerProps: {
                children: nameof<IError>('exception_name')
            },
            cellProps: {
                dataKey: nameof<IError>('exception_name'),
                children: (error: RowDataType | IErrorBriefInfo) =>
                    <Link
                        to={`/Monitoring/${error.id}`}
                        className={`monitoring-panel-error-content ${selectedErrorId && Number(selectedErrorId) === error.id ? "selected-error" : ""}`}
                    >
                        <div className="monitoring-panel-error-exception-name">
                            {error.exception_name}
                        </div>
                        <div className="monitoring-panel-error-complex">
                            <div className="monitoring-panel-error-region">
                                {error.region}
                            </div>
                            <div className="monitoring-panel-error-complex-type">
                                {error.complex_type}
                            </div>
                        </div>
                        <div className="monitoring-panel-error-exception-msg">
                            {error.exception_msg}
                        </div>
                    </Link>
            }
        }
    ], [selectedErrorId]);

    const handleCloseFilters = () => {
        if (filtersMenuRef.current) {
            filtersMenuRef.current.classList.remove('show');
        }
    }

    const applyFilters = useCallback((lastId: number = 0, shouldUpdateScroll: boolean = true) => {
        shouldUpdateScrollRef.current = shouldUpdateScroll;
        let withFilters = false;

        const currentFilters: IErrorsFilters = {
            searchString: '',
            startDate: '',
            endDate: '',
            regions: [],
            complexTypes: [],
            errorLevels: [],
            lastId: lastId ? lastId : undefined
        };

        if (searchStringRef.current?.value) {
            withFilters = true;
            searchStringRef.current.classList.add('filtering');
            currentFilters.searchString = searchStringRef.current.value;
        }

        if (startDateRef.current) {
            withFilters = true;
            currentFilters.startDate = startDateRef.current.toLocaleString().replace(',', '');
        }

        if (endDateRef.current) {
            withFilters = true;
            currentFilters.endDate = endDateRef.current.toLocaleString().replace(',', '');
        }

        if (regionsRef.current && regionsRef.current.length > 0) {
            withFilters = true;
            currentFilters.regions = regionsRef.current;
        }

        if (complexTypesRef.current && complexTypesRef.current.length > 0) {
            withFilters = true;
            currentFilters.complexTypes = complexTypesRef.current;
        }

        if (errorLevelsRef.current && errorLevelsRef.current.length > 0) {
            withFilters = true;
            currentFilters.errorLevels = errorLevelsRef.current;
        }

        handleCloseFilters();

        if ((withFilters || searchStringRef.current?.value) && filtersMenuTogglerRef.current) {
            filtersMenuTogglerRef.current.classList.add('active');
            getErrors(currentFilters, lastId ? true : false);
        }
        else {
            if(lastId) {
                getErrors({lastId: lastId}, true)
            }
            else {
                cancelFilters();
            }
        }
    }, []);

    const cancelFilters = useCallback(() => {
        if(filtersMenuTogglerRef.current) {
            filtersMenuTogglerRef.current.classList.remove('active');
        }

        if (searchStringRef.current) {
            searchStringRef.current.classList.remove('filtering');
            searchStringRef.current.value = "";
        }
        shouldUpdateScrollRef.current = true;
        getErrors({});
    }, []);
    
    const onScrollEnd = useCallback(() => {
        if(fulfilledTimeStamp && (new Date().getTime() - fulfilledTimeStamp < 500)) return;

        if (
            !errorsIsLoading &&
            errors?.data &&
            errors.data[errors.data.length - 1].id !== 1
        ) {
            applyFilters(errors.data[errors.data.length - 1].id, false);
        }
    }, [errors, errorsIsLoading, fulfilledTimeStamp]);
    
    const shouldUpdateScrollRef = useRef<boolean>(false);

    const currentFilters = useMemo(() => {
        if (filters?.data) {
            const prevFilters: IGetFilters = JSON.parse(JSON.stringify(filters.data));

            prevFilters.regions.ref = regionsRef;

            prevFilters.complexTypes.ref = complexTypesRef;

            prevFilters.errorLevels.ref = errorLevelsRef;

            return { ...prevFilters };
        }
    }, [filters]);

    return (
        <div className="monitoring-panel">
            <div className="monitoring-panel-nav">
                {!errorsIsLoading ?
                    <MdRefresh
                        className="monitoring-panel-nav-btn"
                        title='Обновить список'
                        onClick={() => applyFilters()}
                    />
                    : <div className='monitoring-panel-nav-btn pointer-events-none'>
                        <span className="spinner-border spinner-border-sm" />
                    </div>}

                <div
                    className="monitoring-panel-nav-btn"
                    ref={filtersMenuTogglerRef}
                >
                    {!filtersIsLoading ?
                        <MdFilterAlt
                            className='pointer-events-none'
                        /> :
                        <span className="spinner-border spinner-border-sm pointer-events-none" />}
                </div>

                <input
                    ref={searchStringRef}
                    className='monitoring-panel-nav-search'
                    type="search"
                    name="monitoringPanelNavSearch"
                    placeholder="Поиск"
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') applyFilters();
                        if (event.key === 'Escape') {
                            event.currentTarget.value = "";
                            applyFilters();
                        }
                    }}
                    onChange={e=>e.currentTarget.value=e.currentTarget.value.trimStart()}
                    onBlur={(e) => {
                        if (!e.currentTarget.value && e.currentTarget.classList.contains('filtering')) {
                            applyFilters();
                            if(searchStringRef.current) searchStringRef.current.classList.remove('filtering');
                        }
                    }}
                />

                {!errorsIsLoading ?
                    <MdSearch
                        className="monitoring-panel-nav-btn"
                        title='Найти'
                        onClick={() => applyFilters()}
                    />
                    : <div className='monitoring-panel-nav-btn pointer-events-none'>
                        <span className="spinner-border spinner-border-sm" />
                    </div>}
            </div>

            <div className="monitoring-panel-content">
                {errors?.data && errors.success === true && !getErrorsIsError ?
                    <RsuiteTable
                        data={errors.data}
                        columns={columns}
                        showHeader={false}
                        virtualized={true}
                        rowHeight={errorRowHeight}
                        loading={errorsIsLoading}
                        onRowClick={() => {
                            if(monitoringPageRef.current) {
                                monitoringPageRef.current.scrollLeft = monitoringPageRef.current.scrollWidth
                            }
                        }}
                        onScrollEnd={onScrollEnd}
                        shouldUpdateScroll={shouldUpdateScrollRef.current}
                    />
                    : (errors?.success == false || getErrorsIsError ? 
                        <div className='text-danger text-center my-auto'>
                            Ошибка получения списка
                        </div> : 
                        <LoadingBlock />)}

                {currentFilters && 
                    <ErrorFilterMenu
                        filters={currentFilters}
                        filtersMenuRef={filtersMenuRef}
                        filtersMenuTogglerRef={filtersMenuTogglerRef}
                        startDateRef={startDateRef}
                        endDateRef={endDateRef}
                        regionsRef={regionsRef}
                        complexTypesRef={complexTypesRef}
                        errorLevelsRef={errorLevelsRef}
                        submitButton={{
                            name: 'Применить',
                            handle: applyFilters
                        }}
                        cancelButton={{
                            name: 'Отменить',
                            handle: cancelFilters
                        }}
                    />}
            </div>
        </div>
    )
})

export default MonitoringPanel