import React, { memo, useMemo } from 'react'
import PageTitle from '../../components/PageTitle';
import RsuiteTable, { ColumnsList } from '../../components/rsuite-table/RsuiteTable';
import LoadingBlock from '../../components/LoadingBlock';
import { fetchDataAlert, nameof } from '../../utils/utils';
import { IProductMetricType } from '../../models/entities/IProductMetricType';
import { RSwal } from '../../constants/sweetAlert';
import Form from '../../components/Form/Form';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import SwalTable from '../../components/SwalTable';
import { useCreateProductMetricTypeMutation, useDeleteProductMetricTypeMutation, useGetProductMetricsTypesQuery, useUpdateProductMetricTypeMutation } from '../../services/ProductMetricService';
import { FormElemTypes } from '../../components/Form/FormElemTypes';
import { RowDataType } from 'rsuite-table';
import { defaultPollingInterval } from '../../store/globalVariables';

const ProductMetricsTypesPage = memo(() => {
    const { refetch, data: productMetricsTypes, fulfilledTimeStamp, isFetching } = useGetProductMetricsTypesQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const [createProductMetricType] = useCreateProductMetricTypeMutation();
    const [updateProductMetricType] = useUpdateProductMetricTypeMutation();
    const [deleteProductMetricType] = useDeleteProductMetricTypeMutation();
    
    const handleCreateProductMetricType = async (row: IProductMetricType) => {
        const result = await createProductMetricType(row);
        const idKey = nameof<IProductMetricType>('id'); 
        const nameKey = nameof<IProductMetricType>('name'); 

        fetchDataAlert({
            result: result,
            showSuccessMessage: true,
            successMessage: "data" in result && result.data.data ?
                SwalTable({
                    headers: [
                        {
                            key: idKey,
                            name: idKey,
                            width: 60,
                            minWidth: 60
                        },
                        {
                            key: nameKey,
                            name: 'Название',
                            flexGrow: 1
                        }
                    ],
                    rows: [{
                        [idKey]: result.data.data[idKey],
                        [nameKey]: result.data.data[nameKey]
                    }]
                })
                : undefined
        })
    }

    const handleUpdateProductMetricType = async (row: IProductMetricType) => {
        const result = await updateProductMetricType(row);
        fetchDataAlert({
            result: result,
            showSuccessMessage: true
        })
    }

    const handleDeleteProductMetricType = async (id: number) => {
        const result = await deleteProductMetricType(id);
        fetchDataAlert({
            result: result,
            showSuccessMessage: true
        })
    }

    const columns: ColumnsList = useMemo(() => {
        const idKey = nameof<IProductMetricType>('id'); 
        const nameKey = nameof<IProductMetricType>('name'); 

        const currentCols: ColumnsList = [
            {
                columnProps: {
                    width: 80,
                    fixed: "left"
                },
                headerProps: {
                    children: <div className="rs-table-crud-icon">
                        <MdAdd
                            title="Создать тип продуктовой метрики"
                            onClick={() =>
                                RSwal.fire({
                                    title: "Создание типа продуктовой метрики",
                                    html: <Form
                                        isSwalForm
                                        // Элементы формы модального окна
                                        elements={[
                                            {
                                                type: FormElemTypes.text,
                                                name: nameof<IProductMetricType>('name'),
                                                label: "Название",
                                                htmlAttributes: {
                                                    placeholder: "Уведомлений",
                                                    required: true,
                                                    autoFocus: true
                                                }
                                            }
                                        ]}
                                        submitButton={{
                                            name: "Создать",
                                            handleClick: (data: IProductMetricType) => handleCreateProductMetricType(data)
                                        }}
                                        denyButton={{
                                            name: "Отменить",
                                            autoFocus: true,
                                            handleClick: () => RSwal.close()
                                        }}
                                    />,
                                    showConfirmButton: false,
                                    showCloseButton: true
                                })} />
                    </div>
                },
                cellProps: {
                    children: (row: RowDataType | IProductMetricType)=><>
                        <div className="rs-table-crud-icon">
                            <MdEdit
                                title="Изменить тип продуктовой метрики"
                                onClick={() => {
                                    RSwal.fire({
                                        title: "Редактирование типа продуктовой метрики",
                                        html: <Form
                                            isSwalForm
                                            // Элементы формы модального окна
                                            elements={[
                                                {
                                                    type: FormElemTypes.text,
                                                    name: idKey,
                                                    label: idKey,
                                                    htmlAttributes: {
                                                        defaultValue: row.id,
                                                        readOnly: true,
                                                        required: true
                                                    }
                                                },
                                                {
                                                    type: FormElemTypes.text,
                                                    name: nameKey,
                                                    label: "Название",
                                                    htmlAttributes: {
                                                        defaultValue: row.name,
                                                        placeholder: "Уведомлений",
                                                        required: true,
                                                        autoFocus: true
                                                    }
                                                }
                                            ]}
                                            submitButton={{
                                                name: "Изменить",
                                                handleClick: (data: IProductMetricType) => handleUpdateProductMetricType(data)
                                            }}
                                            denyButton={{
                                                name: "Отменить",
                                                autoFocus: true,
                                                handleClick: () => RSwal.close()
                                            }}
                                        />,
                                        showConfirmButton: false,
                                        showCloseButton: true
                                    })
                                }} />
                        </div>
                        <div className="rs-table-crud-icon">
                            <MdDelete
                                title="Удалить тип продуктовой метрики"
                                onClick={() =>
                                    RSwal.fire({
                                        title: "Удаление типа продуктовой метрики",
                                        html: <Form
                                            isSwalForm
                                            // Элементы формы модального окна
                                            elements={[
                                                {
                                                    type: FormElemTypes.text,
                                                    name: idKey,
                                                    label: idKey,
                                                    htmlAttributes: {
                                                        defaultValue: row.id,
                                                        readOnly: true,
                                                        required: true
                                                    }
                                                },
                                                {
                                                    type: FormElemTypes.text,
                                                    name: nameKey,
                                                    label: "Название",
                                                    htmlAttributes: {
                                                        defaultValue: row.name,
                                                        disabled: true
                                                    }
                                                }
                                            ]}
                                            submitButton={{
                                                name: "Удалить",
                                                handleClick: (data: IProductMetricType) => handleDeleteProductMetricType(data.id)
                                            }}
                                            denyButton={{
                                                name: "Отменить",
                                                autoFocus: true,
                                                handleClick: () => RSwal.close()
                                            }}
                                        />,
                                        showConfirmButton: false,
                                        showCloseButton: true
                                    })} />
                        </div>
                    </>
                }
            },
            {
                columnProps: {
                    sortable: true,
                    fixed: true,
                    width: 60
                },
                headerProps: {
                    children: idKey
                },
                cellProps: {
                    dataKey: idKey
                }
            },
            {
                columnProps: {
                    sortable: true,
                    flexGrow: 1,
                    fullText: true
                },
                headerProps: {
                    children: 'Название'
                },
                cellProps: {
                    dataKey: nameKey
                },
                searchable: true
            }
        ];

        return currentCols;
    }, []);

    return (
        <main>
            <PageTitle
                title='Типы продуктовых метрик'
                reloadPage={() => refetch()}
                isLoading={isFetching}
                pageUpdateTime={fulfilledTimeStamp && !isFetching ? new Date(fulfilledTimeStamp).toLocaleString().replace(',', '') : ""}
            />

            {productMetricsTypes?.data ?
                <RsuiteTable
                    data={productMetricsTypes.data}
                    columns={columns}
                    withTotalRows
                    virtualized
                /> :
                <LoadingBlock rounded />}
        </main>
    )
})

export default ProductMetricsTypesPage