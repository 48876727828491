import { RefObject } from 'react';
import { useEffect } from 'react';

type Props = {
    code: string,
    target: RefObject<HTMLElement | null> | null,
    onKeyDown: (target: HTMLElement | null | undefined) => void
}

export function useOnKeyDown({ code, target, onKeyDown }: Props) {
    const onKeyDownHandle = (e: KeyboardEvent) => {
        if(e.code === code) {
            onKeyDown(target?.current);
        }
    }

    useEffect(() => {
        if (target?.current) {
            target.current.addEventListener("keydown", onKeyDownHandle)
        }

        return () => {
            if (target?.current) {
                target.current.removeEventListener("keydown", onKeyDownHandle)
            }
        }
    }, [target?.current])
}