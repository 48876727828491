import React, { memo, useMemo, useLayoutEffect, useRef } from 'react'
import { IError } from '../../../models/entities/IError'
import { IUser } from '../../../models/entities/IUser'
import { iframeStyles } from '../../../constants/others'
import DOMPurify from 'dompurify'
import { renderObject } from '../../../utils/utils'
import { IDefaultDataResponse } from '../../../models/responses/IDefaultResponse'
import { IGetBugzillaProductInfo } from '../../../models/responses/IGetBugzillaProductInfo'

type BugCreationModalProps = {
    bugNameRef: React.RefObject<HTMLTextAreaElement>,
    bugVersionRef: React.RefObject<HTMLSelectElement>,
    bugComponentRef: React.RefObject<HTMLSelectElement>,
    bugzillaProductInfo: IDefaultDataResponse<IGetBugzillaProductInfo> | undefined,
    errorData: IError | undefined,
    currentUser: IUser | null
}

const BugCreationModal = memo(({
    bugNameRef,
    bugVersionRef,
    bugComponentRef,
    bugzillaProductInfo,
    errorData,
    currentUser
}: BugCreationModalProps) => {
    const defaultVersion = useMemo(() => {
        if (bugzillaProductInfo) {
            let currentVersion = bugzillaProductInfo.data.defaultVersion;

            bugzillaProductInfo.data.versions.forEach(version => {
                if (errorData?.version) {
                    if (errorData.version?.includes(version.name)) currentVersion = version.name
                }
                else {
                    if (bugzillaProductInfo.data.defaultVersion === version.name) {
                        currentVersion = version.name
                    }
                }
            })
            return currentVersion;
        }
    }, [bugzillaProductInfo, errorData]);

    const isDarkTheme = useMemo(() => !!document.querySelector("html.dark-theme"), []);

    const modalData = useMemo(() => {
        if (errorData) {
            return {
                sanitaizedErrorMsg: DOMPurify.sanitize(errorData.msg),
                parsedServiceInfo: renderObject({ obj: JSON.parse(errorData.service_info) }),
                parsedClientInfo: renderObject({ obj: JSON.parse(errorData.client_info) }),
                parsedOtherInfo: renderObject({ obj: JSON.parse(errorData.other_details) })
            }
        }
    }, [errorData]);

    return (
        <div className="bug-content">
            {errorData && <>
                <table>
                    <tbody>
                        <tr>
                            <th>
                                Название
                            </th>
                            <td>
                                <textarea
                                    maxLength={250}
                                    ref={bugNameRef}
                                    defaultValue={
                                        errorData.exception_msg?.substring(0, 250) ??
                                        errorData.exception_name?.substring(0, 250) ??
                                        errorData.msg?.substring(0, 100) ??
                                        ""
                                    }
                                    className='form-custom-control w-100 px-1'
                                    placeholder='Введите название бага'
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Версия
                            </th>
                            <td>
                                {bugzillaProductInfo?.data?.versions ?
                                    <select
                                        ref={bugVersionRef}
                                        defaultValue={defaultVersion}
                                        disabled={!!bugzillaProductInfo.data.versions && bugzillaProductInfo.data.versions.length === 0}
                                    >
                                        {bugzillaProductInfo.data.versions.length === 0 &&
                                            <option className="d-none">
                                                {defaultVersion}
                                            </option>}

                                        {bugzillaProductInfo.data.versions.map(version =>
                                            <option key={version.id}>
                                                {version.name}
                                            </option>
                                        )}
                                    </select>
                                    : <span className='spinner-border spinner-border-sm' />}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Компонент
                            </th>
                            <td>
                                {bugzillaProductInfo?.data?.components ?
                                    <select
                                        ref={bugComponentRef}
                                        defaultValue={bugzillaProductInfo.data.defaultComponent}
                                        disabled={bugzillaProductInfo.data.components.length === 0}
                                    >
                                        {bugzillaProductInfo.data.components.length === 0 &&
                                            <option className="d-none">
                                                {bugzillaProductInfo.data.defaultComponent}
                                            </option>}

                                        {bugzillaProductInfo.data.components.map((component, index) =>
                                            <option key={"component" + index}>
                                                {component.name}
                                            </option>
                                        )}
                                    </select>
                                    : <span className='spinner-border spinner-border-sm' />}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="bug-msg">
                    <a href={window.location.href}>
                        {window.location.href}
                    </a>
                    <div>
                        Пользователь мониторинга:&nbsp;{currentUser?.username}
                    </div>
                    <br />
                    {errorData.region && `Регион: ${errorData.region}\n`}
                    {errorData.complex_type && `Система: ${errorData.complex_type}\n`}
                    {errorData.version && `Версия: ${errorData.version}\n`}
                    {errorData.complex_user && `Пользователь: ${errorData.complex_user}\n`}
                    {errorData.datemsk && `Время на сервере Мониторинга (МСК): ${errorData.datemsk}\n`}
                    {errorData.dater && `Время на сервере комплекса (Регион): ${errorData.dater}\n`}
                    {errorData.url && <div>
                        Ссылка:&nbsp;
                        <a href={errorData.url}>
                            {errorData.url}
                        </a>
                    </div>}

                    <iframe
                        className='w-100 flex-grow-1 border-top border-bottom my-3'
                        srcDoc={
                            (isDarkTheme ?
                                "<style>body{color: white !important}</style>"
                                : "") +
                            iframeStyles +
                            `<div class="error-info-msg">` +
                            modalData?.sanitaizedErrorMsg
                            + "</div>"}
                    />
                    
                    {/* Информация о сервисе */}
                    {errorData.service_info && <div>
                        {modalData?.parsedServiceInfo}
                        <br />
                    </div>}

                    {/* Информация о клиенте */}
                    {errorData.client_info && <div>
                        {modalData?.parsedClientInfo}
                        {errorData?.client_error_id ? `ClientErrorID: ${errorData?.client_error_id}` : ""}
                        <br />
                    </div>}
                    
                    {/* Другая информация */}
                    {errorData.other_details && <div>
                        {modalData?.parsedOtherInfo}
                    </div>}
                </div>
            </>}
        </div>
    )
})

export default BugCreationModal