import { memo, useCallback } from 'react'
import { useFormContext } from "react-hook-form";
import { DefaultInputElem } from '../FormElemTypes';
import ClickOutside from '../../ClickOutside';
import { MdRefresh } from 'react-icons/md';
import { generatePassword } from '../../../utils/utils';
import useDidMountEffect from '../../../hooks/skipFirstUseEffectRun';

const DefaultInput = memo(({ type, id, label, name, icon, htmlAttributes, setDefaultThenClickOutsideParams, withGeneratePassword }: DefaultInputElem) => {
    const { register, setValue } = useFormContext();

    const elemId = 'form_elem_' + (id ?? name);

    const { ref, ...rest } = register(name);

    useDidMountEffect(()=>{
        setUpdatedValues()
    }, [htmlAttributes])

    // Костыль для обновление defaultValue
    const setUpdatedValues = ()=> {
        if (htmlAttributes?.ref?.current) {
            setValue(name, htmlAttributes.ref.current.defaultValue);
        }
    }

    const handleClickOutside = (target: HTMLDivElement) => {
        if (
            setDefaultThenClickOutsideParams?.containerRef?.current &&
            !setDefaultThenClickOutsideParams.containerRef.current.contains(target)
        ) {
            setUpdatedValues();
        }
    }

    return (
        <ClickOutside
            className="form-field"
            handleClickOutside={(e) => setDefaultThenClickOutsideParams ? handleClickOutside(e.target as HTMLDivElement) : null}
        >
            {label &&
                <label
                    htmlFor={elemId}
                    className='text-truncate'
                    title={label}
                >
                    {label}
                </label>
            }

            {icon &&
                <span className="form-field-icon">
                    {icon}
                </span>
            }

            <input
                className="form-field-elem"
                autoComplete={htmlAttributes?.autoComplete ?? undefined}
                type={type}
                id={elemId}
                onInvalid={(e) => htmlAttributes?.invalidMsg ?
                    (e.target as HTMLInputElement).setCustomValidity(htmlAttributes.invalidMsg)
                    : null}
                {...htmlAttributes}
                {...rest}
                ref={(e) => {
                    ref(e);
                    if (htmlAttributes?.ref) htmlAttributes.ref.current = e;
                }}
            />

            {withGeneratePassword && 
                <MdRefresh 
                    className='generate-password-btn dynamic-btn' 
                    onClick={()=> setValue(name, generatePassword())} 
                />}
        </ClickOutside>
    )
})

export default DefaultInput