import React, { memo } from 'react'
import NavItem from "./NavItem";
import { MdArrowRight } from 'react-icons/md';
import { INavItem } from '../../../constants/navItems';

type NavItemWithChildsProps = {
    navItem: INavItem;
    navigationMenuRef: React.RefObject<HTMLDivElement>,
    currentPath: string
};

const NavItemWithChilds = memo(({ navItem, navigationMenuRef, currentPath }: NavItemWithChildsProps) => {
    return (
        <div className="navigation-link navigation-link-with-childs">
            <div className="navigation-childs-container-title">
                <div className="navigation-link-icon">
                    {navItem.icon}
                </div>

                <div className="navigation-childs-container-name">
                    {navItem.name}
                </div>

                <div className="expand-more">
                    <MdArrowRight />
                </div>
            </div>

            <div className='navigation-link-childs-container'>
                {navItem.childs?.map((child, idx) =>
                    <NavItem 
                        key={`navigation_link_${idx}`} 
                        navItem={child}  
                        navigationMenuRef={navigationMenuRef}
                        isActive={child.path !== "/" ?
                            currentPath.toLowerCase().includes(child.path?.toLowerCase()) :
                            currentPath === child.path}
                    />
                )}
            </div>
        </div>
    );
})

export default NavItemWithChilds;