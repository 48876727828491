import { MdLightMode, MdDarkMode } from 'react-icons/md';
import { toggleDarkTheme } from '../../../store/reducers/UserReducer';
import { useAppSelector } from '../../../hooks/redux';

export default function ThemeToggler() {
    const isDarkTheme = useAppSelector(state => state.user.isDarkTheme);
    return (
        <div className="navigation-head-button">
            <div
                className="theme-icon transparent-hover"
                onClick={toggleDarkTheme}
                title="Сменить тему"
            >
                {isDarkTheme ?
                    <MdDarkMode /> :
                    <MdLightMode />}
            </div>
        </div>
    )
}
