export interface IPrometheusQueryRangeResponse {
    data?: IPrometheusQueryData,
    error?: string,
    errorType?: 'bad_data',
    status: 'error' | 'success'
}

export const ResultTypes = {
    matrix: 'matrix',
    vector: 'vector'
} as const;

export interface IPrometheusGraphMetric {
    metric: {
        instance?: string,
        job?: string,
        service?: string,
        device?: string,
        fstype?: string,
        mountpoint?: string
    }
}

export interface IPrometheusGraphValue {
    [T: number]: string | number
}

export type IPrometheusQueryData =
    {
        resultType: typeof ResultTypes['matrix'],
        result: (IPrometheusGraphMetric & {values?: IPrometheusGraphValue[]})[]
    } |
    {
        resultType: typeof ResultTypes['vector'],
        result: (IPrometheusGraphMetric & {value: IPrometheusGraphValue})[]
    }