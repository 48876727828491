import React, { memo, useRef, useMemo, useState, useCallback, useLayoutEffect } from 'react'
import ItemsPanel from '../../components/items-panel/ItemsPanel'
import { IComplex } from '../../models/entities/IComplex'
import { IProductMetric } from '../../models/entities/IProductMetric'
import { Link, useParams } from 'react-router-dom'
import { ColumnsList } from '../../components/rsuite-table/RsuiteTable'
import { RowDataType, TableProps } from 'rsuite-table'
import { MdPieChart } from 'react-icons/md'
import FiltersMenuItem from '../../components/filters-menu/FiltersMenuItem'
import { IProductMetricType } from '../../models/entities/IProductMetricType'

interface ProductMetricsPanelProps extends TableProps<any, any> {
    productMetricsPageRef: React.RefObject<HTMLDivElement>,
    filtersMenuTogglerRef: React.RefObject<HTMLDivElement>,
    filtersMenuRef: React.RefObject<HTMLDivElement>,
    panelIsLoading: boolean,
    complexes: IComplex[] | undefined,
    productMetricsTypes: IProductMetricType[] | undefined,
    productMetrics: IProductMetric[] | undefined,
    productMetricsReceivingTime: number | undefined,
    searchStringRef: React.RefObject<HTMLInputElement>,
    complexesRef: React.MutableRefObject<number[]>,
    productMetricsTypesRef: React.MutableRefObject<number[]>,
    startRecDateRef: React.MutableRefObject<Date | null>,
    endRecDateRef: React.MutableRefObject<Date | null>,
    startCollDateRef: React.MutableRefObject<Date | null>,
    endCollDateRef: React.MutableRefObject<Date | null>,
    applyFilters: (lastIdForNextMetrics?: number, shouldUpdateScroll?: boolean) => void,
    cancelFilters: () => void,
}

const ProductMetricsPanel = memo(({
    productMetricsPageRef,
    filtersMenuTogglerRef,
    filtersMenuRef,
    panelIsLoading, 
    complexes, 
    productMetricsTypes,
    productMetrics, 
    productMetricsReceivingTime,
    complexesRef,
    productMetricsTypesRef,
    startRecDateRef,
    endRecDateRef,
    startCollDateRef,
    endCollDateRef,
    applyFilters,
    cancelFilters,
    ...tableProps
}: ProductMetricsPanelProps) => {
    const selectedProductMetricId = useParams<{ id?: string }>().id;

    const columns: ColumnsList = useMemo(() => {
        if (!productMetrics) return [];

        return [
            {
                columnProps: {
                    sortable: true,
                    fixed: true,
                    width: 60
                },
                headerProps: {
                    children: ''
                },
                cellProps: {
                    children: (row: IProductMetric | RowDataType) =>
                        <Link
                            to={`/ProductMetrics/${row.id}`} 
                            className='items-panel-elem-icon'
                        >
                            <MdPieChart />
                            <div className="text-wrap text-center">
                                {row.receivingDate}
                            </div>
                        </Link>
                }
            },
            {
                columnProps: {
                    sortable: true,
                    flexGrow: 1
                },
                headerProps: {
                    children: ''
                },
                cellProps: {
                    children: (row: IProductMetric | RowDataType) =>
                        <Link
                            to={`/ProductMetrics/${row.id}`}
                            className={'items-panel-elem-content ' + (Number(selectedProductMetricId) === row.id ? 'active' : '')}
                        >
                            <div className="items-panel-elem-complex">
                                <div className="items-panel-elem-region">
                                    {row.region}
                                </div>
                                <div className="items-panel-elem-complex-type">
                                    {row.complex_type}
                                </div>
                            </div>
                            <div className="flex-grow-1 d-flex-center-center">
                                {`${row.startDate} – ${row.endDate}`}
                            </div>
                        </Link>
                },
                searchable: true
            }
        ]
    }, [productMetrics, selectedProductMetricId]);

    useLayoutEffect(()=>{
        setTimeout(() => {
            if(selectedProductMetricId && productMetricsPageRef.current && window.innerWidth <= 600) {
                productMetricsPageRef.current.scrollLeft = productMetricsPageRef.current.scrollWidth;
            }
        });
    }, [selectedProductMetricId])

    const [startRecDate, setStartRecDate] = useState<Date | null>(null);
    const [endRecDate, setEndRecDate] = useState<Date | null>(null);

    const [startCollDate, setStartCollDate] = useState<Date | null>(null);
    const [endCollDate, setEndCollDate] = useState<Date | null>(null);

    const handleSetStartRecDate = (value: Date | null) => {
        setStartRecDate(value);
        startRecDateRef.current = value;
    }

    const handleSetEndRecDate = (value: Date | null) => {
        setEndRecDate(value);
        endRecDateRef.current = value;
    }

    const handleSetStartCollDate = (value: Date | null) => {
        setStartCollDate(value);
        startCollDateRef.current = value;
    }

    const handleSetEndCollDate = (value: Date | null) => {
        setEndCollDate(value);
        endCollDateRef.current = value;
    }

    const handleCancel = () => {
        complexesRef.current = [];
        productMetricsTypesRef.current = [];
        handleSetStartRecDate(null);
        handleSetEndRecDate(null);
        handleSetStartCollDate(null);
        handleSetEndCollDate(null);
        applyFilters()
        cancelFilters();
    }

    const onScrollEnd = useCallback(() => {
        if(productMetricsReceivingTime && (new Date().getTime() - productMetricsReceivingTime < 500)) return;

        if (
            !panelIsLoading &&
            productMetrics &&
            productMetrics[productMetrics.length - 1] &&
            productMetrics[productMetrics.length - 1].id !== 1
        ) {
            applyFilters(productMetrics[productMetrics.length-1].id, false)
        }
    }, [productMetrics, panelIsLoading, productMetricsReceivingTime]);

    return (
        <div className="product-metrics-panel">
            <ItemsPanel
                filtersMenuTogglerRef={filtersMenuTogglerRef}
                filtersMenuRef={filtersMenuRef}
                panelIsLoading={panelIsLoading}
                filtersIsLoading={false}
                filterMenu={{
                    listFilters: {
                        complexes: {
                            name: 'Системы',
                            items: complexes?.map(complex => ({
                                name: `${complex.region} | ${complex.type}`,
                                value: complex.id
                            })),
                            ref: complexesRef
                        },
                        productMetricsTypes: {
                            name: 'Типы метрик',
                            items: productMetricsTypes?.map(pmt => ({
                                name: pmt.name,
                                value: pmt.id
                            })),
                            ref: productMetricsTypesRef
                        }
                    },
                    customFilters: [
                        <FiltersMenuItem
                            key='receivingPeriodFilter'
                            filter={{
                                type: 'datePicker',
                                content: {
                                    name: 'Период приема',
                                    setStartDate: handleSetStartRecDate,
                                    setEndDate: handleSetEndRecDate,
                                    startDate: startRecDate,
                                    endDate: endRecDate,
                                    positionStartDate: 'auto',
                                    positionEndDate: 'auto'
                                }
                            }}
                        />,
                        <FiltersMenuItem
                            key='collectionPeriodFilter'
                            filter={{
                                type: 'datePicker',
                                content: {
                                    name: 'Период сбора',
                                    setStartDate: handleSetStartCollDate,
                                    setEndDate: handleSetEndCollDate,
                                    startDate: startCollDate,
                                    endDate: endCollDate,
                                    positionStartDate: 'auto',
                                    positionEndDate: 'auto'
                                }
                            }}
                        />
                    ],
                    handleSubmit: applyFilters,
                    handleCancel: handleCancel
                }}
                panelContent={{
                    data: productMetrics,
                    columns: columns
                }}                
                onScrollEnd={onScrollEnd}
                {...tableProps}
                rowHeight={84}
            />

        </div>
    )
})

export default ProductMetricsPanel