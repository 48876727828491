import React, { ReactNode, memo } from 'react'

type FormButtonContentProps = {
    name?: string,
    icon?: ReactNode,
    handleClick?: () => void
}

const FormButtonContent = memo(({ name, icon, handleClick }: FormButtonContentProps) => {
    return (
        <div
            className='form-button-content'
            onClick={() => handleClick ? handleClick() : null}
        >
            {name ?? name}
            <div className="form-button-icon">
                {icon}
            </div>
        </div>
    )
})

export default FormButtonContent