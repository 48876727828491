import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { defaultBaseQueryParams } from "../constants/defaultBaseQueryParams";
import IAuthenticate from "../models/requests/IAuthenticate";
import IApplyForRegistration from "../models/requests/IApplyForRegistration";
import { IUser } from "../models/entities/IUser";
import { currentUser } from "../store/globalVariables";
import { IDefaultDataResponse, IDefaultResponse } from "../models/responses/IDefaultResponse";
import { fetchReauth, setCurrentUser } from "../store/reducers/UserReducer";
import { dispatch } from "../store";
import { IUserSettings } from "../models/entities/IUserSettings";
import { IUserWithMessenger } from "../models/entities/IUserWithMessenger";

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery(defaultBaseQueryParams),
    tagTypes: ['User', 'UserWithMessenger', 'ApplicationForRegistr'],
    endpoints: build => ({
        authenticate: build.mutation<IDefaultDataResponse<IUser | null>, IAuthenticate>({
            query: ({username, password, isLDAPAuthenticate, captchaField}: IAuthenticate)  => ({
                url: '/Users/Authenticate',
                body: {
                    username: username,
                    password: password,
                    isLDAPAuthenticate : isLDAPAuthenticate,
                    captchaField : captchaField
                },
                method: "POST"
            }),
            transformResponse: async (response: IDefaultDataResponse<IUser | null>) => {
                if(response.success && response.data) {
                    setCurrentUser(response.data);
                }
                return response;
            }
        }),
        reauth: build.mutation<IUser, void | string>({
            query: (token)  => ({
                url: '/Users/reauth',
                body: {
                    token: token ?? currentUser.value?.jwtToken
                },
                method: "POST"
            })
        }),
        applyForRegistration: build.mutation<IDefaultDataResponse<IApplyForRegistration>, IApplyForRegistration>({
            query: ({firstName, lastName, username, region, email}: IApplyForRegistration)  => ({
                url: '/Users/ApplyForRegistration',
                body: {
                    firstName,
                    lastName,
                    username,
                    region,
                    email
                },
                method: "POST"
            })
        }),
        getAllApplicationsForRegistr: build.query<IDefaultDataResponse<IApplyForRegistration[]>, void>({
            query: ()  => ({
                url: `/Users/GetAllApplicationsForRegistr`,
                method: "GET"
            }),
            providesTags: (result) =>
                result?.data? [
                    ...result.data.map(({ id }) => ({ type: 'ApplicationForRegistr' as const, id })),
                    { type: 'ApplicationForRegistr', id: 'LIST' },
                    ]
                : [{ type: 'ApplicationForRegistr', id: 'LIST' }]
        }),
        deleteApplicationForRegistr: build.mutation<IDefaultDataResponse<IApplyForRegistration>, number>({
            query: (id)  => ({
                url: `/Users/DeleteApplication/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: 'ApplicationForRegistr', id: 'LIST' }]
        }),
        getAllUsers: build.query<IDefaultDataResponse<IUser[]>, void>({
            query: ()  => ({
                url: `/Users/GetAll`,
                method: "GET"
            }),
            providesTags: (result) =>
                result?.data? [
                    ...result.data.map(({ id }) => ({ type: 'User' as const, id })),
                    { type: 'User', id: 'LIST' },
                    ]
                : [{ type: 'User', id: 'LIST' }]
        }),
        getAllUsersWithMessenger: build.query<IDefaultDataResponse<IUserWithMessenger[]>, void>({
            query: ()  => ({
                url: `/Users/GetAllUsersWithMessenger`,
                method: "GET"
            }),
            providesTags: ['UserWithMessenger']
        }),
        createUser: build.mutation<IDefaultResponse, IUser>({
            query: (user)  => ({
                url: "/Users/Register",
                body: user,
                method: "POST"
            }),
            invalidatesTags: [{ type: 'User', id: 'LIST' }]
        }),
        updateUser: build.mutation<IDefaultResponse, IUser>({
            query: (user)  => ({
                url: `/Users/${user.id}`,
                body: user,
                method: "PUT"
            }),
            transformResponse: async (response: IDefaultResponse, meta, arg) => {
                if(currentUser.value?.id === arg.id && response.success) {
                    await dispatch(fetchReauth()).unwrap();
                }
                return response;
            },
            invalidatesTags: [{ type: 'User', id: 'LIST' }]
        }),
        deleteUser: build.mutation<IDefaultResponse, number>({
            query: (id)  => ({
                url: `/Users/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: 'User', id: 'LIST' }, 'UserWithMessenger']
        }),
        checkRocketChatAllowed: build.query<IDefaultDataResponse<boolean>, void>({
            query: ()  => ({
                url: "/Users/CheckRocketChatAllowed",
                method: "GET"
            })
        }),        
        setRocketChatNickname: build.mutation<IDefaultResponse, IUserSettings['rocket_chat_nickname']>({
            query: (nickname)  => ({
                url: `/Users/SetRocketChatNickname?nickname=${nickname}`,
                method: "GET"
            }),
            invalidatesTags: ['UserWithMessenger'],
            transformResponse: async (response: IDefaultResponse) => {
                await dispatch(fetchReauth()).unwrap();
                return response;
            }
        }),
        setBugzillaEmail: build.mutation<IDefaultResponse, IUserSettings["bugzilla_email"]>({
            query: (bugzillaEmail)  => ({
                url: `/Users/SetBugzillaEmail?email=${bugzillaEmail}`,
                method: "GET"
            }),
            transformResponse: async (response: IDefaultResponse) => {
                await dispatch(fetchReauth()).unwrap();
                return response;
            }
        }),
        setAutoUpdateSettings: build.mutation<IDefaultResponse, Pick<IUserSettings,"home_autoupdate_interval">>({
            query: (interval)  => ({
                url: "/Users/SetAutoUpdateSettings",
                body: interval,
                method: "POST"
            }),
            transformResponse: async (response: IDefaultResponse) => {
                await dispatch(fetchReauth()).unwrap();
                return response;
            }
        }),
        setCurrentOnlineDateTime: build.mutation<IDefaultResponse, void>({
            query: ()  => ({
                url: "/Users/SetCurrentOnlineDateTime",
                method: "PUT"
            }),
            transformResponse: async (response: IDefaultResponse) => {
                if(response.success) {
                    await dispatch(fetchReauth()).unwrap();
                }
                return response;
            }
        }),
    })
})

export const {
    useAuthenticateMutation,
    useReauthMutation,
    useApplyForRegistrationMutation,
    useGetAllApplicationsForRegistrQuery,
    useDeleteApplicationForRegistrMutation,
    useGetAllUsersQuery,
    useGetAllUsersWithMessengerQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useCheckRocketChatAllowedQuery,
    useSetAutoUpdateSettingsMutation,
    useSetBugzillaEmailMutation,
    useSetRocketChatNicknameMutation,
    useSetCurrentOnlineDateTimeMutation,
    
} = userApi;