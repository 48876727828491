import { memo, ReactNode, RefObject } from 'react'

type FiltersHeaderProps = FiltersHeaderPropsWithItems | FiltersHeaderPropsWithTile | FiltersHeaderPropsWithAll

type FiltersHeaderPropsWithItems = {
    title?: never,
    items: {
        btn: {
            content: ReactNode,
            innerRef: RefObject<HTMLDivElement>
        },
        menu: {
            content: ReactNode,
            innerRef: RefObject<HTMLDivElement>
        }
    }[]
}

type FiltersHeaderPropsWithTile = {
    title: string,
    items?: never
}

type FiltersHeaderPropsWithAll = {
    title: string,
    items: {
        btn: {
            content: ReactNode,
            innerRef: RefObject<HTMLDivElement>
        },
        menu: {
            content: ReactNode,
            innerRef: RefObject<HTMLDivElement>
        }
    }[]
}

const FiltersHeader = memo(({ title, items }: FiltersHeaderProps) => {
    return (
        <div className="filters-header">
            {title &&
                <div className="filters-header-title">
                    {title}
                </div>}

            {items?.map((item, idx) => <div
                className="filters-header-item-container"
                key={idx}
            >
                <div 
                    className="filters-header-item"
                    ref={item.btn.innerRef}
                >
                    {item.btn.content}
                </div>

                {item.menu.content}
            </div>)}

        </div>
    )
});

export default FiltersHeader