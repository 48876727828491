import React, { memo, createRef, useState, useRef, useLayoutEffect, useEffect, useMemo } from 'react'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { nameof } from '../../utils/utils';
import { selectColor, selectDarkColor } from '../../store/globalVariables';
import RsuiteTable, { ColumnsList } from '../../components/rsuite-table/RsuiteTable';
import { CustomLegend, CustomLegendProps } from '../home-page/Graphs/CustomLegend';
import { CSItem, CSItemChildren } from '../../models/responses/ClientsStatistic';
import { RowDataType } from 'rsuite-table';
import { MdExpandMore } from 'react-icons/md';

const columns: ColumnsList = [
    {
        columnProps: {
            sortable: true,
            flexGrow: 1,
            fullText: true
        },
        headerProps: {
            children: 'Название'
        },
        cellProps: {
            dataKey: nameof<CSItem>('name'),
            children: ((row: RowDataType | CSItem) => <div
                style={row.children ? { backgroundColor: selectColor(row.id) } : {}}
                className='clients-statistic-row-name'
            >
                {row.name}
            </div>),
            className: 'p-0'
        },
        searchable: true
    },
    {
        columnProps: {
            flexGrow: 1,
            fullText: true
        },
        headerProps: {
            children: "Регион"
        },
        cellProps: {
            dataKey: nameof<CSItemChildren>('region'),
            children: (rowData) => {
                const row = rowData as CSItemChildren;
                return (row.region && <div 
                    className="w-100 h-100 p-2" 
                    style={{ backgroundColor: selectColor(row.parent_id) }}
                >
                    {row.region}
                </div>)
            }
        }
    },
    {
        columnProps: {
            flexGrow: 1,
            fullText: true
        },
        headerProps: {
            children: "Система"
        },
        cellProps: {
            dataKey: nameof<CSItemChildren>('complex_type'),
            children: (rowData) => {
                const row = rowData as CSItemChildren;
                return (row.complex_type && 
                    <div 
                        className="w-100 h-100 p-2" 
                        style={{ backgroundColor: selectColor(row.parent_id) }}
                    >
                        {row.complex_type}
                    </div>)
            }
        }
    },
    {
        columnProps: {
            sortable: true,
            fullText: true,
            width: 90
        },
        headerProps: {
            children: "Кол-во"
        },
        cellProps: {
            dataKey: nameof<CSItemChildren>('count'),
            children: (rowData) => {
                const row = rowData as (CSItemChildren | CSItem);
                return <div 
                    className="w-100 h-100 p-2" 
                    style={
                        !("children" in row) ? 
                            { backgroundColor: selectColor(row.parent_id) } : 
                            { backgroundColor: selectColor(row.id) }}
                >
                    {row.count}
                </div>
            }
        }
    }
];

const ClientsStatisticCol = memo(({ data }: { data: CSItem[] }) => {
    const [deletedKeys, setDeletedKeys] = useState<string[]>([]);
    const [filteredData, setFilteredData] = useState([...data]);

    const keys = useRef<string[]>([]);

    const legendItems = useRef<CustomLegendProps['data']>([]);

    useLayoutEffect(() => {
        const currentKeys: string[] = [];
        const currentLegendItems: CustomLegendProps['data'] = [];
        data.forEach(item=>{
            currentKeys.push(item.name);
            currentLegendItems.push({
                name: item.name,
                value: item.count
            })
        })
        keys.current = [...currentKeys];
        legendItems.current = [...currentLegendItems];
    }, [data])

    const handleLegendClick = (dataKey: string) => {
        setDeletedKeys(prevKeys => {
            if (prevKeys.includes(dataKey)) {
                return prevKeys.filter(item => item !== dataKey);
            }
            else return [...prevKeys, dataKey];
        })
        setFilteredData(prevData => {
            if (prevData.some(item => item.name === dataKey)) {
                return prevData.filter(item => item.name !== dataKey)
            }
            else {
                const currData = prevData.concat(data.find(item => item.name === dataKey) ?? [])
                return currData;
            }
        })
    }

    const handleLegendRightClick = (dataKey: string) => {
        setFilteredData(prevData => {
            if (prevData.length === 1 && prevData[0].name === dataKey) {
                return [...data]
            }
            else {
                const currDataItem = data.find(item => item.name === dataKey);
                return currDataItem ? [currDataItem] : [];
            }
        })
        setDeletedKeys(prevKeys => {
            if (JSON.stringify(prevKeys) === JSON.stringify(keys.current.filter(key => key !== dataKey))) {
                return [];
            }
            else return keys.current.filter(key => key !== dataKey);
        })
    }

    return (
        <div className="clients-statistic-col">
            <div className="clients-statistic-graph">
                <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            animationDuration={300}
                            animationBegin={0}
                            data={filteredData}
                            dataKey={nameof<CSItem>('count')}
                            nameKey={nameof<CSItem>('name')}
                            innerRadius={40}
                            outerRadius='70%'
                            label={({ percent }) => Math.round(Number(percent * 100)) + "%"}
                            fontSize={14}
                        >
                            {filteredData.map(item => (
                                <Cell
                                    cursor='help'
                                    key={'browserCell_' + item.id}
                                    fill={selectColor(item.id)}
                                    onMouseEnter={(e) => {
                                        if (e.currentTarget) e.currentTarget.style.fill = selectDarkColor(selectColor(item.id))
                                    }}
                                    onMouseLeave={(e) => {
                                        if (e.currentTarget) e.currentTarget.style.fill = selectColor(item.id)
                                    }}
                                />
                            ))}
                        </Pie>
                        <Tooltip contentStyle={{ padding: '0 .5rem', borderRadius: '.25rem' }} />
                        <Legend
                            layout='vertical'
                            align='right'
                            verticalAlign='top'
                            wrapperStyle={{
                                height: '100%',
                                overflow: 'auto',
                                fontSize: '14px'
                            }}
                            iconType='square'
                            iconSize={11}
                            content={() =>
                                <CustomLegend
                                    data={legendItems.current}
                                    layout='vertical'
                                    deletedKeys={deletedKeys}
                                    handleLegendClick={handleLegendClick}
                                    handleLegendRightClick={handleLegendRightClick}
                                />}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>

            <div
                className="clients-statistic-table"
                onTouchStart={e => e.currentTarget.classList.add('touched')}
                onTouchEnd={e => e.currentTarget.classList.remove('touched')}
            >
                <RsuiteTable
                    data={data}
                    columns={columns}
                    isTree
                    rowKey={nameof<CSItem>('name')}
                    renderTreeToggle={() => <MdExpandMore className='expand-more' />}
                    affixHorizontalScrollbar={false}
                    shouldUpdateScroll={false}

                // onScroll={()=>{
                //     kek.current?.classList.add('scrolling');
                //     setTimeout(() => {
                //         kek.current?.classList.remove('scrolling');
                //     }, 500);
                // }}
                />
            </div>
        </div>
    )
})

export default ClientsStatisticCol