import React, { memo } from 'react'
import { MdError } from 'react-icons/md'

type RenderErrorProps = {
    className?: string,
    showIcon?: boolean,
    statusCode?: number,
    statusMsg?: number | "FETCH_ERROR" | "PARSING_ERROR" | "TIMEOUT_ERROR" | "CUSTOM_ERROR",
    errorMsg?: string
    endpointName?: string
}

const RenderError = memo(({className, showIcon, statusCode, statusMsg, errorMsg, endpointName}:RenderErrorProps) => {
    return (
        <div className={`text-danger fs-1-25 d-flex-center-center ${className ?? ""}`}>
            {showIcon && <MdError className='text-danger me-1 fs-2' />}
            Ошибка
            {endpointName ? ` запроса ${endpointName}` : ""}
            <br />
            {statusCode ? ` ${statusCode}` : ""}
            {statusMsg ? ` ${statusMsg}:` : ""}
            <br />
            {errorMsg && errorMsg}
        </div>
    )
})

export default RenderError