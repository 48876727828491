import React from 'react';
import PageTitle from '../../components/PageTitle';

function ServerManagerPage() {
    return (
        <main>
            <PageTitle
                title='Обновление систем'
                // reloadPage={() => refetch()}
                // isLoad={!isFetching}
                pageUpdateTime={new Date().toLocaleString().replace(',', "")}
            />

            

        </main>
    );
}

export default React.memo(ServerManagerPage);