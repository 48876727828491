import { memo } from 'react'
import { useFormContext } from "react-hook-form";
import { SelectElem } from '../FormElemTypes';

const SelectInput = memo(({ name, label, icon, options, emptyOption, optionsDisabled, htmlAttributes }: SelectElem) => {
    const methods = useFormContext();

    const elemId = 'form_elem_' + name;

    return (
        <div className="form-field">
            {label &&
                <label htmlFor={elemId}>
                    {label}
                </label>
            }

            {icon &&
                <span className="form-field-icon">
                    {icon}
                </span>
            }

            <select
                {...methods.register(name)}
                className="form-field-elem"
                name={name}
                id={elemId}
                onInvalid={(e) => htmlAttributes?.invalidMsg ?
                    (e.target as HTMLInputElement).setCustomValidity(htmlAttributes.invalidMsg)
                    : null}
                {...htmlAttributes}
            >
                {htmlAttributes?.defaultValue === undefined && 
                    <option 
                        className='d-none'
                        value={typeof emptyOption === 'object' ? emptyOption.value : undefined}
                    >
                        {emptyOption ? 
                            (typeof emptyOption === 'object' ? emptyOption.name : emptyOption) : 
                            null}
                    </option>}
                {options.map((option, index) =>
                    typeof option === 'object' ?
                        <option
                            key={option.name + index}
                            value={option.value}
                            disabled={optionsDisabled}
                        >
                            {option.name}
                        </option>
                        : <option
                            key={option + index}
                            value={option}
                            disabled={optionsDisabled}
                        >
                            {option}
                        </option>
                )}
            </select>
        </div>
    )
})

export default SelectInput