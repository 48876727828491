import { useCallback, memo, useMemo, useLayoutEffect, useState } from 'react';
import PageTitle from '../../components/PageTitle';
import { useLazyGetAlertsHistoryQuery, useGetTargetsQuery } from '../../services/ServerLoadService';
import { defaultPollingInterval } from '../../store/globalVariables';
import './server-load.scss'
import ServersAvailability from './ServersAvailability';
import AlertsHistory from './AlertsHistory';
import { GetAlertsHistoryRequest } from '../../models/requests/IGetAlertsHistoryRequest';

function ServerLoadPage() {
    const { data: targets, refetch: getTargets, isFetching: targetsIsLoading, fulfilledTimeStamp: targetsFullfilledTimeStamp } = useGetTargetsQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const [getAlertsHistory, { data: alerts, isFetching: alertsIsLoading, fulfilledTimeStamp: alertsFullfilledTimeStamp }] = useLazyGetAlertsHistoryQuery({
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const pageIsLoading =
        targetsIsLoading ||
        alertsIsLoading;

    const lastReceivingTimeRef = useMemo(() => Math.max(
        targetsFullfilledTimeStamp ?? 0,
        alertsFullfilledTimeStamp ?? 0
    ), [targetsFullfilledTimeStamp, alertsFullfilledTimeStamp]);

    const reloadPage = useCallback(() => {
        getTargets();
        getAlertsHistory({});
    }, []);

    useLayoutEffect(()=>{
        getAlertsHistory({});
    }, [])

    return (
        <main className='server-load-page'>
            <PageTitle
                title='Нагрузки серверов'
                reloadPage={reloadPage}
                isLoading={pageIsLoading}
                pageUpdateTime={
                    !pageIsLoading && lastReceivingTimeRef ?
                        new Date(lastReceivingTimeRef).toLocaleString().replace(',', "") :
                        ""}
            />

            <div className="server-load-content row mx-0 w-100 h-100">
                <ServersAvailability 
                    getTargets={getTargets}
                    targets={targets?.data}
                    targetsIsLoading={targetsIsLoading}
                />
                <AlertsHistory 
                    getAlertsHistory={(filters?: GetAlertsHistoryRequest) => getAlertsHistory(filters ? filters : {})}
                    alerts={alerts?.data}
                    alertsIsLoading={targetsIsLoading}
                />
            </div>

        </main>
    );
}

export default memo(ServerLoadPage);