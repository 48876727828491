import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CaptchaState {
    captchaImg: string | undefined;
    isCaptcha: boolean;
}

const initialState: CaptchaState = {
    captchaImg: JSON.parse(localStorage.getItem('captchaImg') ?? "null"),
    isCaptcha: JSON.parse(localStorage.getItem('isCaptcha') ?? "true")
};

export const captchaSlice = createSlice({
    name: 'captcha',
    initialState,
    reducers: {
        setCaptchaImg(state, action: PayloadAction<string | undefined>) {
            state.captchaImg = action.payload;
            if (action.payload !== undefined) {
                localStorage.setItem('captchaImg', JSON.stringify(state.captchaImg));
            }
        },
        setIsCaptcha(state, action: PayloadAction<boolean>) {
            state.isCaptcha = action.payload;
            localStorage.setItem('isCaptcha', JSON.stringify(state.isCaptcha));
        },
        removeLocalStorage(state) {
            localStorage.removeItem('captchaImg');
            state.captchaImg = JSON.parse(localStorage.getItem('captchaImg') ?? "null");
            localStorage.removeItem('isCaptcha');
            state.captchaImg = JSON.parse(localStorage.getItem('isCaptcha') ?? "true");
        },
    }
});

export const { setIsCaptcha, setCaptchaImg, removeLocalStorage } = captchaSlice.actions;
export const captchaReducer = captchaSlice.reducer;
