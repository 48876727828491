import { useRef, useState } from 'react';
import PageTitle from '../../components/PageTitle';
import { FormElemTypes } from '../../components/Form/FormElemTypes';
import { useAppSelector } from '../../hooks/redux';
import { fetchDataAlert, hmsToMS, msToHMS, nameof, tryCatchHandler } from '../../utils/utils';
import './settings.scss'
import SettingsBlock from './SettingsBlock';
import { useCheckRocketChatAllowedQuery, useSetAutoUpdateSettingsMutation, useSetBugzillaEmailMutation, useSetRocketChatNicknameMutation } from '../../services/UserService';
import { dispatch, logout } from '../../store';
import { RSwal } from '../../constants/sweetAlert';
import { fetchReauth } from '../../store/reducers/UserReducer';
import useDidMountEffect from '../../hooks/skipFirstUseEffectRun';
import { useLazyGetBugzillaUserQuery } from '../../services/BugzillaService';
import { defaultPollingInterval } from '../../store/globalVariables';
import { IUserSettings } from '../../models/entities/IUserSettings';

const hmsDefaultPageUpdateInterval = msToHMS(defaultPollingInterval);
const hmsPageUpdateIntervalMin = msToHMS(Number(process.env.REACT_APP_PAGE_UPDATE_INTERVAL_MIN));
const hmsPageUpdateIntervalMax = msToHMS(Number(process.env.REACT_APP_PAGE_UPDATE_INTERVAL_MAX));

function SettingsPage() {
    const currentUser = useAppSelector(state => state.user.currentUserState);
    const [currentUserIsLoading, setCurrentUserLoading] = useState<boolean>(false);
    const [pageUpdateTime, setPageUpdateTime] = useState<string>("");

    const { data: rocketChatIsAllowed, isFetching: rocketChatIsAllowedIsLoading, refetch: checkRocketChatAllowed } = useCheckRocketChatAllowedQuery();

    useDidMountEffect(() => {
        setPageUpdateTime(new Date().toLocaleString().replace(',', ""))
    }, [rocketChatIsAllowed])

    const [getBugzillaUser] = useLazyGetBugzillaUserQuery();
    const [setAutoUpdateSettings] = useSetAutoUpdateSettingsMutation();
    const [setBugzillaEmail] = useSetBugzillaEmailMutation();
    const [setRocketChatUser] = useSetRocketChatNicknameMutation();

    const getHomeAutoUpdateInterval = () => {
        if (currentUser) {
            var userSettings = currentUser.userSettings;
            if (userSettings && userSettings.home_autoupdate_interval) {
                return msToHMS(userSettings.home_autoupdate_interval);
            }
            else {
                return hmsDefaultPageUpdateInterval;
            }
        }
    };

    const homeAutoupdateIntervalRef = useRef<HTMLInputElement | null>(null);
    const bugzillaEmailRef = useRef<HTMLInputElement | null>(null);
    const rocketChatUserIdRef = useRef<HTMLInputElement | null>(null);

    const handleSetAutoUpdateSettings = async (homeAutoupdateInterval: string) => {
        if (currentUser) {
            tryCatchHandler(async () => {
                RSwal.fire({ didOpen: () => RSwal.showLoading() })
                const ms = hmsToMS(homeAutoupdateInterval);

                const result = await setAutoUpdateSettings({
                    home_autoupdate_interval: ms
                });

                fetchDataAlert({
                    result: result,
                    showSuccessMessage: true
                })

                if("data" in result && result.data.success && homeAutoupdateIntervalRef.current) {
                    homeAutoupdateIntervalRef.current.defaultValue = homeAutoupdateInterval
                }
            })
        }
        else {
            logout();
        }
    }
                
    const setBugzillaEmailHandler = async (bugzillaEmail: string) => {
        const result = await setBugzillaEmail(bugzillaEmail);
        RSwal.hideLoading();

        fetchDataAlert({
            result: result,
            showSuccessMessage: true
        })

        if("data" in result && result.data.success && bugzillaEmailRef.current) {
            bugzillaEmailRef.current.defaultValue = bugzillaEmail
        }
    }

    const handleSetBugzillaEmail = (bugzillaEmail: string) => {
        if (currentUser) {
            tryCatchHandler(async () => {
                RSwal.fire({ didOpen: () => RSwal.showLoading() })

                if (bugzillaEmail) {
                    const bugzillaUser = await getBugzillaUser(bugzillaEmail).unwrap();

                    if (bugzillaUser.success && bugzillaUser.data) {
                        setBugzillaEmailHandler(bugzillaEmail);
                    }
                    else {
                        RSwal.fire({
                            icon: "warning",
                            title: "Что-то пошло не так",
                            html: <div>
                                Возможно, Ваша почта <b>{bugzillaEmail}</b> не зарегистрирована на&nbsp;
                                <a
                                    href='https://portal.keysystems.ru/'
                                    target='_blank'
                                >
                                    portal.keysystems.ru
                                </a>
                            </div>,
                            showCloseButton: true,
                            heightAuto: false
                        });
                    }
                }
                else {
                    setBugzillaEmailHandler(bugzillaEmail);
                }
            });
        }
        else {
            logout();
        }
    }    
    
    const handleSetRocketChatUser = (username: string) => {
        tryCatchHandler(async () => {
            RSwal.fire({ didOpen: () => RSwal.showLoading() })
            const result = await setRocketChatUser(username);
            
            fetchDataAlert({
                result: result,
                showSuccessMessage: true
            })

            if("data" in result && result.data.success && rocketChatUserIdRef.current) {
                rocketChatUserIdRef.current.defaultValue = username
            }
        })
    }

    async function reloadPage() {
        setCurrentUserLoading(true);
        await dispatch(fetchReauth());
        setPageUpdateTime(new Date().toLocaleString().replace(',', ""));
        setCurrentUserLoading(false);
        checkRocketChatAllowed();
    }

    return (
        <main className="settings-page">
            <PageTitle
                title='Настройки'
                reloadPage={reloadPage}
                isLoading={currentUserIsLoading || rocketChatIsAllowedIsLoading}
                pageUpdateTime={!currentUserIsLoading ? pageUpdateTime : ""}
            />

            <div className="settings-page-content">
                <SettingsBlock
                    label='Автообновление страниц'
                    description='Задайте время для автообновления страниц'
                    elements={[{
                        type: FormElemTypes.time,
                        name: nameof<IUserSettings>('home_autoupdate_interval'),
                        label: 'Главная страница',
                        htmlAttributes: {
                            defaultValue: getHomeAutoUpdateInterval(),
                            min: hmsPageUpdateIntervalMin,
                            max: hmsPageUpdateIntervalMax,
                            step: '1',
                            required: true,
                            ref: homeAutoupdateIntervalRef
                        }
                    }]}
                    submitButton={{
                        name: "Задать",
                        className: "btn-primary",
                        handleClick: (data: Pick<IUserSettings, 'home_autoupdate_interval'>) => handleSetAutoUpdateSettings(String(data.home_autoupdate_interval))
                    }}
                    cancelButton={currentUser?.userSettings?.home_autoupdate_interval !== defaultPollingInterval ?
                        {
                            name: "Сбросить",
                            className: "btn-danger ms-3",
                            handleClick: () => handleSetAutoUpdateSettings(hmsDefaultPageUpdateInterval)
                        }
                        : undefined}
                />

                {(!currentUser?.role.isAdmin || currentUser?.role.isSuperAdmin) &&
                    <SettingsBlock
                        label='Почта от аккаунта в Bugzilla'
                        description='Привяжите почту от аккаунта в Bugzilla для того, чтобы Вы смогли создавать баги'
                        elements={[{
                            type: FormElemTypes.email,
                            name: nameof<IUserSettings>('bugzilla_email'),
                            htmlAttributes: {
                                defaultValue: currentUser?.userSettings?.bugzilla_email,
                                placeholder: "Введите почту",
                                required: true,
                                ref: bugzillaEmailRef
                            }
                        }]}
                        submitButton={{
                            name: "Привязать",
                            className: "btn-primary",
                            handleClick: (data: Pick<IUserSettings, 'bugzilla_email'>) => handleSetBugzillaEmail(data.bugzilla_email)
                        }}
                        cancelButton={currentUser?.userSettings?.bugzilla_email ?
                            {
                                name: "Отвязать",
                                className: "btn-danger ms-3",
                                handleClick: () => handleSetBugzillaEmail("")
                            }
                            : undefined}
                    />}
                
                    {rocketChatIsAllowed?.data && currentUser?.role.isAdmin &&
                        <SettingsBlock
                            label='Логин пользователя RocketChat'
                            description={`Вам будет приходить следующая информация: \n - Сработанные триггеры ошибок.`}
                            elements={[{
                                type: FormElemTypes.text,
                                name: nameof<IUserSettings>('rocket_chat_nickname'),
                                htmlAttributes: {
                                    defaultValue: currentUser?.userSettings?.rocket_chat_nickname,
                                    placeholder: "Введите ник",
                                    required: true,
                                    ref: rocketChatUserIdRef
                                }
                            }]}
                            submitButton={{
                                name: "Привязать",
                                className: "btn-primary",
                                handleClick: (data: Pick<IUserSettings, 'rocket_chat_nickname'>) => handleSetRocketChatUser(data.rocket_chat_nickname)
                            }}
                            cancelButton={currentUser?.userSettings?.rocket_chat_nickname ?
                                {
                                    name: "Отвязать",
                                    className: "btn-danger ms-3",
                                    handleClick: () => handleSetRocketChatUser("")
                                }
                                : undefined}
                        />}
            </div>

        </main>
    );
}

export default SettingsPage;