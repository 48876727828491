import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {defaultBaseQueryParams} from "../constants/defaultBaseQueryParams";
import { IDefaultDataResponse, IDefaultResponse } from "../models/responses/IDefaultResponse";
import { GroupedError } from "../models/entities/IIssue";
import { ITrigger } from "../models/entities/ITrigger";

export const issueApi = createApi({
    reducerPath: 'issueApi',
    baseQuery: fetchBaseQuery(defaultBaseQueryParams),
    tagTypes: ["GroupedError", "Trigger"],
    endpoints: build => ({
        getGroupedErrors: build.query<IDefaultDataResponse<GroupedError[]>, void>({
            query: () => ({
                url: "Issues/GetGroupedErrors",
                method: "GET"
            }),
            providesTags: (result) =>
                result?.data ? [
                    ...result.data.map(({ id }) => ({ type: 'GroupedError' as const, id })),
                    { type: 'GroupedError', id: 'LIST' },
                ]
                    : [{ type: 'GroupedError', id: 'LIST' }]
        }),
        getErrorTriggers: build.query<IDefaultDataResponse<ITrigger[]>, void>({
            query: () => ({
                url: "Issues/GetTriggers",
                method: "GET"
            }),
            providesTags: (result) =>
                result?.data ? [
                    ...result.data.map(({ id }) => ({ type: 'Trigger' as const, id })),
                    { type: 'Trigger', id: 'LIST' },
                ]
                    : [{ type: 'Trigger', id: 'LIST' }]
        }),
        createTrigger: build.mutation<IDefaultResponse, ITrigger>({
            query: (request) => ({
                url: "Issues/CreateTrigger",
                method: "POST",
                body: request
            }),
            invalidatesTags: ["Trigger"]
        }),
        updateTrigger: build.mutation<IDefaultResponse, ITrigger>({
            query: (request) => ({
                url: "Issues/UpdateTrigger",
                method: "PUT",
                body: request
            }),
            invalidatesTags: ["Trigger"]
        }),
        deleteTrigger: build.mutation<IDefaultResponse, number>({
            query: (id) => ({
                url: `Issues/DeleteTrigger/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Trigger"]
        }),
        subcribeToTrigger: build.mutation<IDefaultResponse, number>({
            query: (id) => ({
                url: `Issues/SubcribeToTrigger/${id}`,
                method: "GET"
            }),
            invalidatesTags: ["Trigger"]
        }),
        unsubcribeToTrigger: build.mutation<IDefaultResponse, number>({
            query: (id) => ({
                url: `Issues/UnsubcribeToTrigger/${id}`,
                method: "GET"
            }),
            invalidatesTags: ["Trigger"]
        })
    })
})

export const {
    useGetGroupedErrorsQuery,
    useGetErrorTriggersQuery,
    useCreateTriggerMutation,
    useUpdateTriggerMutation,
    useDeleteTriggerMutation,
    useSubcribeToTriggerMutation,
    useUnsubcribeToTriggerMutation
} = issueApi;