import { useRef } from 'react'
import { ElementVariant, FormButton } from '../../components/Form/FormElemTypes'
import Form, { FormProps } from '../../components/Form/Form'
import { MdInfo } from 'react-icons/md'

type SettingsBlockProps = {
    label: string,
    elements: ElementVariant[],
    submitButton: FormProps['submitButton'],
    description: string,
    cancelButton?: FormProps['cancelButton']
}

const SettingsBlock = ({ label, elements, submitButton, cancelButton, description }: SettingsBlockProps) => {  
    const ref = useRef<HTMLDivElement>(null);

    return (
        <div 
            className="settings-block row mx-0"
            ref={ref}
        >
            <label
                htmlFor={elements[0].name}
                className='settings-label'
            >
                {label}
                <MdInfo 
                    className='text-info ms-1 small cursor-help'
                    title={description} 
                />
            </label>
            <Form
                className='col-xxl-4 col-lg-6 col-12'
                elements={elements}
                submitButton={submitButton}
                cancelButton={cancelButton}
                containerRef={ref}
            />
        </div>
    )
}

export default SettingsBlock