import { memo } from 'react'
import { useFormContext } from "react-hook-form";
import { RadioElem } from '../FormElemTypes';

const RadioInput = memo(({ type, name, label, icon, items, defaultValue, htmlAttributes }: RadioElem) => {
    const methods = useFormContext();

    return (
        <div className="form-field">
            {label &&
                <label htmlFor='none'>
                    {label}
                </label>
            }

            {icon &&
                <span className="form-field-icon">
                    {icon}
                </span>
            }

            <div className="form-radios-container">
                {items.map((item, index) =>
                    <div
                        className='form-radio'
                        key={String(item.value) + index}
                    >
                        <input
                            {...methods.register(name)}
                            className="form-field-elem"
                            type={type}
                            name={name}
                            value={String(item.value)}
                            id={name + "_" + item.value}
                            disabled={item.disabled}
                            readOnly={item.readOnly}
                            defaultChecked={item.value === defaultValue}
                            autoFocus={item.autoFocus}
                            onInvalid={(e) => htmlAttributes?.invalidMsg ?
                                (e.target as HTMLInputElement).setCustomValidity(htmlAttributes.invalidMsg)
                                : null}
                            {...htmlAttributes}
                        />
                        <label htmlFor={name + "_" + item.value}>
                            {item.label}
                        </label>
                    </div>
                )}
            </div>
        </div>
    )
})

export default RadioInput