import React, { ReactNode, memo, useRef } from 'react'
import { MdExpandMore, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'
import { IMenuFilter } from '../../models/responses/IGetFilters'
import useShowing from '../../hooks/showing';
import DateTimeRangePicker, { DateTimeRangePickerProps } from '../date-time-picker/DateTimeRangePicker';
import { TSelectFilters } from './FiltersMenu';

type FilterList = {
    type: "list",
    filterKey: string,
    content: IMenuFilter
}

type FilterDatePicker = {
    type: "datePicker",
    content: {
        name: string
    } & DateTimeRangePickerProps
}

export type FilterRadioContent = {
    name: string,
    innerRef: React.RefObject<HTMLInputElement>,
    defaultChecked: boolean
}

type FilterRadio = {
    type: "switch",
    content: FilterRadioContent
}

type FiltersMenuItemProps = {
    filter: FilterList | FilterDatePicker | FilterRadio,
    handleListSelect?: TSelectFilters
}

const FiltersMenuItem = memo(({ filter, handleListSelect }: FiltersMenuItemProps) => {
    const filterItemsTogglerRef = useRef<HTMLDivElement>(null);
    const filterItemsRef = useRef<HTMLDivElement>(null);

    useShowing({
        togglerRef: filterItemsTogglerRef,
        toggleTargetRef: filterItemsRef,
        closeOnClickOutside: true,
        overflowAfterTransEnd: 'auto'
    });

    return (
        <div
            className="filters-menu-filter"
        >
            {filter.type === 'list' &&
                <>
                    <div
                        ref={filterItemsTogglerRef}
                        className="filters-menu-filter-name"
                    >
                        {filter.content.name}

                        {filter.content.selectedItems?.length! > 0 &&
                            <span className="filters-count show">
                                {filter.content.selectedItems?.length}
                            </span>}

                        <MdExpandMore className='expand-more' />
                    </div>
                    <div
                        ref={filterItemsRef}
                        className="filters-menu-filter-items"
                    >
                        {filter.content.items?.map(item =>
                            <div
                                key={item.value ? item.value : item.name}
                                className="filters-menu-filter-item"
                                onClick={() => handleListSelect ? handleListSelect(filter.filterKey, item.value ? item.value : item.name) : null}
                            >
                                {filter.content.selectedItems?.includes(item.value ? item.value : item.name) ?
                                    <MdOutlineCheckBox className='active text-success' /> :
                                    <MdOutlineCheckBoxOutlineBlank />}

                                <span className="filters-menu-filter-item-name">
                                    {item.name}
                                </span>
                            </div>)}
                    </div>
                </>}

            {filter.type === 'datePicker' &&
                <>
                    <div
                        ref={filterItemsTogglerRef}
                        className="filters-menu-filter-name"
                    >
                        {filter.content.name}
                        {(filter.content.startDate || filter.content.endDate) && 
                            <span className="filters-count">
                                ✔
                            </span>}

                        <MdExpandMore className='expand-more' />
                    </div>
                    <div
                        ref={filterItemsRef}
                        className="filters-menu-filter-items"
                    >
                        <DateTimeRangePicker
                            setStartDate={filter.content.setStartDate}
                            setEndDate={filter.content.setEndDate}
                            startDate={filter.content.startDate}
                            endDate={filter.content.endDate}
                            positionStartDate='right-start'
                            positionEndDate='right-start'
                        />
                    </div>
                </>}

            {filter.type === 'switch' &&
                <div
                    ref={filterItemsTogglerRef}
                    className="filters-menu-filter-name pe-0"
                >
                    <label
                        htmlFor="uniqUsers"
                        className='d-flex w-100 flex-nowrap align-items-center justify-content-between cursor-pointer'
                    >
                        {filter.content.name}
                        <div className="form-check form-switch mb-0">
                            <input
                                id="uniqUsers"
                                className="form-check-input cursor-pointer"
                                type="checkbox"
                                ref={filter.content.innerRef}
                                defaultChecked={filter.content.defaultChecked}
                            />
                        </div>
                    </label>
                </div>}
        </div>
    )
})

export default FiltersMenuItem