import React, {ReactElement, useLayoutEffect} from 'react';
import {Navigate} from 'react-router-dom';
import { history } from '../utils/utils';
import { useAppSelector } from '../hooks/redux';
import { fetchReauth } from '../store/reducers/UserReducer';
import { dispatch, logout } from '../store';

export type PrivateRouteProps = {
    Component: React.ElementType;
    onlyAdmin: boolean;
};

export const PrivateRoute = ({Component, onlyAdmin}: PrivateRouteProps): ReactElement => {
    const currentUser = useAppSelector(state=>state.user.currentUserState);

    // useLayoutEffect(()=>{
    //     if(currentUser?.jwtToken && !['/Login','/Registration'].includes(history.location?.pathname.toLowerCase() ?? '')) {
    //         dispatch(fetchReauth());
    //     }
    // },[history.location]);

    useLayoutEffect(() => {
        if (!currentUser) {
            logout();
        }
    }, []);

    if (!currentUser) {
        return <Navigate to="/Login" state={history.location?.pathname} />
    }
    
    if(!currentUser.role?.isAdmin && onlyAdmin) {
        return <Navigate to="/" state={history.location?.pathname} />
    }
    
    return <Component />
};

