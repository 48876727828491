import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../models/entities/IUser";
import { userApi } from "../../services/UserService";
import { dispatch } from "..";
import { darkTheme } from "../globalVariables";

interface UserState {
    currentUserState: IUser | null,
    isDarkTheme: boolean
}

const initialState: UserState = {
    currentUserState: JSON.parse(localStorage.getItem('currentUser') ?? "null"),
    isDarkTheme: darkTheme.value
}

export const fetchReauth = createAsyncThunk(
    'reauth',
    (token: void | string) => {
        const response = dispatch(userApi.endpoints.reauth.initiate(token)).unwrap();
        return response;
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCurrentUser(state, { payload }) {
            const currentUser: IUser | void = payload;

            if (currentUser) {
                // Записываем в локальное хранилище
                localStorage.setItem('currentUser', JSON.stringify(currentUser));

                // Записываем в состояние
                state.currentUserState = currentUser;
            }
            else {
                dispatch(fetchReauth())
            }
        },
        logout(state) {
            localStorage.removeItem('currentUser');
            state.currentUserState = null;
        },
        toggleDarkTheme(state) {
            if (!state.isDarkTheme) {
                state.isDarkTheme = true;
                localStorage.setItem('isDarkTheme', `${true}`);
                document.querySelector("html")?.classList.add("dark-theme");
            }
            else {
                state.isDarkTheme = false;
                localStorage.setItem('isDarkTheme', `${false}`);
                document.querySelector("html.dark-theme")?.classList.remove("dark-theme");
            }
        },
        setDarkTheme(state, {payload}) {
            state.isDarkTheme = payload ? true : false;
            localStorage.setItem('isDarkTheme', `${state.isDarkTheme}`);

            if (state.isDarkTheme) {
                document.querySelector("html")?.classList.add("dark-theme");
            }
            else {
                document.querySelector("html.dark-theme")?.classList.remove("dark-theme");
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchReauth.fulfilled, (state, {payload}) => {
            // Записываем в локальное хранилище
            localStorage.setItem('currentUser', JSON.stringify(payload));

            // Записываем в состояние
            state.currentUserState = payload;
        });
    },
})

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;

export const setCurrentUser = (user: IUser | void) => dispatch(userSlice.actions.setCurrentUser(user));
export const toggleDarkTheme = () => dispatch(userSlice.actions.toggleDarkTheme());
export const setDarkTheme = (isDarkTheme: boolean) => dispatch(userSlice.actions.setDarkTheme(isDarkTheme));
