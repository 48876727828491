import { Location, NavigateFunction, Params } from "react-router-dom";
import { windowPath } from "../store/globalVariables";
import { IDefaultResponse } from "../models/responses/IDefaultResponse";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import RenderError from "../components/render-condition/RenderError";
import { logout } from "../store";
import { memo, ReactNode } from "react";
import { RSwal } from "../constants/sweetAlert";

export const sortArrayByProperty = (array: any[], key: string) => {
    return [...array].sort((a, b) => {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
    });
}

// Выбрать уникальные значения из списка по свойствам
export const sortArrayByUniqueProperties = (array: any[], keys: string[], resultKey: string) => {
    let uniqueProperties: any[] = [];
    let result: any[] = [];
    for (let str of array) {
        let searchString: string = "";
        keys.forEach((key: string) => {
            searchString += String(str[key]) + "_"
        })
        if (!uniqueProperties.includes(searchString)) {
            uniqueProperties.push(searchString);
            result.push(resultKey ? str[resultKey] : str);
        }
    }
    return result;
}

// Получить кол-во одинаковых записей
export const countsOfUniqueElements = (array: any[], key: string) => {
    let uniqueProperties: any[] = [];
    let result: any = {};

    array.forEach((elem: any) => {
        if (!uniqueProperties.includes(elem[key])) {
            uniqueProperties.push(elem[key]);
            result[elem[key]] = 1;
        }
        else {
            result[elem[key]] += 1
        }
    });

    return result;
}

//
export const statusResponseHandler = (response: Response) => {
    if (response.status === 401 && windowPath.value.toLowerCase() != "/login") {
        logout();
    }
    return response.json()
}

export const addMaxHeightShowing = (element: HTMLDivElement | null, event: TransitionEvent) => {
    if (event.target === element && element && event.propertyName === "max-height"  ) {
        element.classList.add('showing')
    }
}

export const removeMaxHeightShowing = (element: HTMLDivElement | null, event: TransitionEvent) => {
    if (event.target === element && element && event.propertyName === "max-height"  ) {
        element.classList.remove('showing')
    }
}

type historyType = {
    navigate: NavigateFunction | null,
    location: Location | null,
    params: Params | null
}

export let history: historyType = {
    navigate: null,
    location: null,
    params: null,
};

export const alertCatchError = (error: any) => {
    RSwal.fire({
        icon: 'error',
        title: error
    })
};

export const tryCatchHandler = (funct: () => void) => {
    try {
        funct();
    }
    catch (error) {
        alertCatchError(error);
    }
};

export type fetchDataAlertProps = {
    result: {
        data: IDefaultResponse;
    } | {
        error: FetchBaseQueryError | SerializedError;
    },
    onSuccess?: ()=>void,
    onError?: (msg: string)=>void,
    onException?: (msg: string)=>void,
    showSuccessMessage?: boolean,
    showErrorMessage?: boolean,
    showException?: boolean,
    successMessage?: HTMLElement | string | JQuery,
    otherInfo?: HTMLElement | string | JQuery
};

export const fetchDataAlert = ({ 
    result, 
    onSuccess,
    onError,
    onException,
    showSuccessMessage = false, 
    showErrorMessage = true, 
    showException = true, 
    successMessage, 
    otherInfo 
}: fetchDataAlertProps) => {
    if ("data" in result) {
        const msg = result.data.message;

        if (result.data.success) {
            if(showSuccessMessage) { 
                RSwal.fire({
                    icon: "success",
                    title: successMessage ?? msg,
                    html: otherInfo
                });
            }

            if(onSuccess) onSuccess();
        }

        if (!result.data.success) {
            if(showErrorMessage) {
                RSwal.fire({
                    icon: "error",
                    title: msg,
                    html: otherInfo
                });
            }
            
            if(onError) onError(msg);
        }
    }
    else if ("error" in result && (showException || onException)) {
        const error = result.error;
        const customMsg: string | undefined = 'data' in error ? trim(JSON.stringify(error.data), '"') : undefined;
        const statusCode = 'originalStatus' in error ? error.originalStatus : undefined;
        const statusMsg = 'status' in error ? error.status : undefined;
        const errorMsg = 'error' in error ? error.error : undefined;

        if(showException) {
            RSwal.fire({
                icon: "error",
                title: customMsg ? `Ошибка ${statusMsg}: ${customMsg}` : <RenderError
                    className="text-body"
                    showIcon={false}
                    statusCode={statusCode}
                    statusMsg={statusMsg}
                    errorMsg={errorMsg}
                />
            });
        }
            
        if(onException) onException(customMsg ? customMsg : (errorMsg ?? "Ошибка"));
    }
}

export const showLoadingSwal = (title?: string) => {
    RSwal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        title: title,
        didOpen: () => RSwal.showLoading()
    });
}

export const trim = (str: string, chars: string) => {
    return ltrim(rtrim(str, chars), chars);
}

const ltrim = (str: string, chars: string) => {
    chars = chars || "\\s";
    return str.replace(new RegExp("^[" + chars + "]+", "g"), "");
}

const rtrim = (str: string, chars: string) => {
    chars = chars || "\\s";
    return str.replace(new RegExp("[" + chars + "]+$", "g"), "");
}

// Преобразование миллисекунд в формат hh:mm:ss
export const msToHMS = (ms: number): string => {
    let seconds: number = ms / 1000;
    const hours = Number((seconds / 3600).toFixed());
    seconds = seconds % 3600;
    const minutes = Number((seconds / 60).toFixed());
    seconds = seconds % 60;
    return (
        String(hours).padStart(2, '0') +
        ":" +
        String(minutes).padStart(2, '0') +
        ":" +
        String(seconds).padStart(2, '0')
    );
}

// Преобразование из формата hh:mm:ss в миллисекунды
export const hmsToMS = (hms: string): number => {
    const time = hms.split(":");
    const ms: number =
        (Number(time[0]) * 1000 * 60 * 60) +
        (Number(time[1]) * 1000 * 60) +
        (Number(time[2]) * 1000);
    return ms;
};

// Преобразование из формата Date в понятный формат
export const dateToHuman = (date: Date | string): string => {
    let humanDate = "";

    if(date instanceof Date) {
        humanDate = date.toLocaleString().split('.')[0].replace('T', ' ');
    }
    else {
        humanDate = date.split('.')[0].replace('T', ' ');
    }

    return humanDate;
};

export const isDateValid = (dateStr: string) => {
    const currentDate = new Date(dateStr);
    return !isNaN(currentDate.getTime());
}

export const genericMemo: <T>(component: T) => T = memo;

export const nameof = <T,>(name: keyof T) => name;

export const getDateOfStartLastDay = () => new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);

export const renderObject = ({obj, withEmpty = false}: {obj: object, withEmpty?: boolean}): string => {
    let result: string = "";
    
    if(obj && Object.keys(obj)?.length > 0) {
        const findValues = (subObj: any) => Object.keys(subObj).forEach(key => {
            var currValue = subObj[key];
            
            if (currValue || withEmpty) {
                if (
                    currValue !== null &&
                    typeof (currValue) === 'object'
                ) {
                    findValues(currValue)
                }
                else {
                    result+=`${key}: ${subObj[key]}\n`
                }
            }
        });
        findValues(obj);
    }

    return result;
} 

export const generatePassword = (
    length = 20,
    characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
): string => {
    return Array.from(crypto.getRandomValues(new Uint32Array(length)))
        .map((x) => characters[x % characters.length])
        .join('')
}

export const parseToTableCells = (value: string | object): ReactNode[] => { 
    try {
        const parsedValue = typeof value === 'string' ? JSON.parse(value) : value;

        const parsedCells: ReactNode[] = [];

        Object.keys(parsedValue).forEach((key, idx)=>{
            const value = parsedValue[key];
            if(value) {
                if(typeof value === 'object') {
                    parsedCells.push(parseToTableCells(value));
                }
                else {
                    parsedCells.push(<tr key={key+idx}>
                        <th>{key}:</th>
                        <td>{value}</td>
                    </tr>)
                }
            }
        });

        return parsedCells;
    }
    catch (error) {
        console.error(error);
        return [];
    }
}