import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { defaultBaseQueryParams } from "../constants/defaultBaseQueryParams";
import { IDefaultDataResponse } from "../models/responses/IDefaultResponse";

export const captchaApi = createApi({
    reducerPath: 'captchaApi',
    baseQuery: fetchBaseQuery(defaultBaseQueryParams),
    tagTypes: ['Captcha'],
    endpoints: build => ({
        getCaptcha: build.mutation<IDefaultDataResponse<any>, void>({
            query: () => ({
                url: `/Captcha/GenerateCaptcha`,
                method: "POST"
            })
        })
    })
});

export const {
    useGetCaptchaMutation
} = captchaApi;
