import { logout } from '.';
import { IUser } from '../models/entities/IUser';

export const currentUser = {
    get value(): IUser | null {
        try {
            const userFromLS = localStorage.getItem('currentUser');
            if (userFromLS) {
                try {
                    return JSON.parse(localStorage.getItem('currentUser') ?? 'null');
                }
                catch {
                    logout();
                    return null;
                }
            }
            else {
                return null;
            }
        }
        catch {

            return null;
        }
    }
}

export const defaultPollingInterval = Number(process.env.REACT_APP_DEFAULT_PAGE_UPDATE_INTERVAL);

export const darkTheme = {
    get value(): boolean {
        const valueFromLS:boolean = JSON.parse(localStorage.getItem('isDarkTheme') ?? `${false}`);
        return valueFromLS;
    }
}

export const windowPath = {
    get value(): string {
        return window.location.pathname.replace(process.env.PUBLIC_URL, "");
    }
}

// Дефолтные цвета
const colors = [
    '#1e90ff', //синий
    '#ff6347', //красный
    '#9932cc', //фиолетовый
    '#ffa500',  //оранжевый
    '#20b2aa', //темно-берюзовый
    '#3cb371', //зеленый
    '#ee82ee',  //розовый
    '#7b68ee'  //светло-фиолетовый
];

// Получить случайный цвет
export const selectColor = (index: number) => {
    if(colors[index]) {
        return colors[index];
    }
    else {
        let letters = '0123456789ABCDEF'.split('');
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.round(Math.random() * 15)];
        }
        colors.push(color);
        return color;
    }
}

export const selectDarkColor = (color: string) => {
    let usePound = false;
    
    if (color[0] == "#") {
        color = color.slice(1);
        usePound = true;
    }
 
    let num = parseInt(color,16);
 
    let r = (num >> 16) - 10;
 
    if (r > 255) r = 255;
    else if  (r < 0) r = 0;
 
    let b = ((num >> 8) & 0x00FF) - 10;
 
    if (b > 255) b = 255;
    else if  (b < 0) b = 0;
 
    let g = (num & 0x0000FF) - 10;
 
    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    let darkColor = (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
 
    return darkColor;  
}



