import React, { memo, useRef, useEffect, useState, useCallback, MutableRefObject, useMemo } from 'react'
import { Table, Column, HeaderCell, Cell, TableProps, ColumnProps, HeaderCellProps, CellProps, SortType, TableInstance } from 'rsuite-table';
import { InnerCellProps } from 'rsuite-table/lib/Cell';
import { sortArrayByProperty } from '../../utils/utils';
import RsuiteSearchInput from './RsuiteSearchInput';
import useDidMountEffect from '../../hooks/skipFirstUseEffectRun';
import { MdArrowUpward, MdFileDownload } from 'react-icons/md';

export type ColumnsList = {
    columnProps: ColumnProps,
    headerProps: HeaderCellProps,
    cellProps: InnerCellProps,
    searchable?: boolean
}[];

interface RsuiteTableProps extends TableProps<any, any> {
    data: any | undefined,
    columns: ColumnsList,
    loading?: boolean,
    isError?: boolean,
    withTotalRows?: boolean,
    renderFooter?: React.ReactNode,
    onScrollEnd?: () => void,
    setSortValueRef?: MutableRefObject<((sortColumn: string | undefined, sortType: SortType | undefined) => void) | undefined>
}

export type Filter = {
    name: string,
    condition: string
}

const defaultHeaderHeight = 40;
const defaultRowHeight = 35;

const RsuiteTable = memo(({
    data,
    columns,
    loading,
    isError,
    withTotalRows,
    renderFooter,
    onScrollEnd,
    setSortValueRef,
    ...TableProps
}: RsuiteTableProps) => {
    const [sortColumnState, setSortColumn] = useState<string | undefined>();
    const [sortTypeState, setSortType] = useState<SortType | undefined>('desc');
    const [filteredData, setData] = useState(data);
    const [filters, setFilters] = useState<Filter[] | undefined>(undefined);
    let filterTimer: NodeJS.Timeout | undefined = undefined;

    const handleSortColumn = (sortColumn: string) => {
        let sortType: SortType | undefined = undefined;

        if (sortColumn === sortColumnState) {
            switch (sortTypeState) {
                case 'asc':
                    sortType = 'desc';
                    break;
                case 'desc':
                    sortType = undefined;
                    break;
                default:
                    sortType = 'asc';
                    break;
            }
        }
        else {
            sortType = 'asc';
        }

        if (sortType) {
            setSort(sortColumn, sortType)
        }
        else {
            setSort(undefined, undefined)
        }
    };

    const setSort = (sortColumn: string | undefined, sortType: SortType | undefined) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    }

    useEffect(() => {
        if (setSortValueRef) {
            setSortValueRef.current = setSort;
        }
    }, [])


    const sortColumns = (sortColumn: string, sortType: SortType | undefined, filters: Filter[] | undefined) => {
        if(!filteredData || !data) return;

        if (sortType && ['asc', 'desc'].indexOf(sortType) >= 0) {
            let sortedRows = [];

            if (filters) {
                sortedRows = sortArrayByProperty(filteredData, sortColumn);
            }
            else {
                sortedRows = sortArrayByProperty(data, sortColumn);
            }

            if (sortType === "asc") {
                return sortedRows;
            }
            if (sortType === "desc") {
                return sortedRows.reverse()
            }
        }
        else {
            const currentData = filters ? filteredData : (data ? [...data] : undefined);
            return currentData;
        }
    }

    const handleFilterColumn = (target: HTMLInputElement) => {
        if (target.classList.contains('active')) {
            target.classList.remove('active')
        }
        else {
            target.classList.add('active')
        }
    }

    const filteringRows = ({ name, condition }: Filter) => {
        clearTimeout(filterTimer);

        filterTimer = setTimeout(() => {
            let currentFilters: Filter[] = [];

            if (filters) {
                if (!condition) {
                    setFilters(prevFilters => prevFilters?.filter(filter => filter.name !== name));
                    return;
                }

                currentFilters = [...filters];

                let identicalFilter = filters.find(filter => filter.name === name);

                if (identicalFilter) {
                    identicalFilter.condition = condition
                }
                else {
                    currentFilters.push({ name, condition })
                }
            }
            else {
                currentFilters.push({ name, condition })
            }

            setFilters(currentFilters);
        }, 500);
    }

    const filterRows = (rows: any[]) => {
        const filteredRows = [...rows].filter(elem => {
            let conditionIsFits: boolean = true;
            filters?.forEach(filter => {
                if (conditionIsFits) {
                    conditionIsFits = String(elem[filter.name]).toLowerCase().includes(String(filter.condition.toLowerCase()));
                }
                else {
                    return;
                }
            })
            return conditionIsFits;
        });
        return filteredRows;
    }

    const cancelFilter = (dataKey: string) => {
        if (filters) {
            let currentFilters = [...filters].filter(filter => filter.name !== dataKey);
            setFilters(currentFilters);
            clearTimeout(filterTimer);
        }
    }

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<TableInstance<any, any>>(null);

    const scrollToTopRef = useRef<HTMLDivElement>(null);
    const downloadMoreRef = useRef<HTMLDivElement>(null);

    const handleScroll = useCallback((x: number, y: number) => {
        if (!tableContainerRef.current || !filteredData || filteredData.length === 0) return;

        const top = Math.abs(y);

        if (scrollToTopRef.current) {
            if (top > 1000) {
                scrollToTopRef.current.classList.add('show');
            }
            else {
                scrollToTopRef.current.classList.remove('show');
            }
        }

        if (downloadMoreRef.current) {
            const currentRowHeight = TableProps.rowHeight ? Number(TableProps.rowHeight) : defaultRowHeight;

            const contextHeight = filteredData.length * currentRowHeight;

            if ((top + tableContainerRef.current.clientHeight) / contextHeight > 0.95) {
                downloadMoreRef.current.classList.add('show')
            }
            else {
                downloadMoreRef.current.classList.remove('show');
            }
        }
    }, [filteredData]);

    useDidMountEffect(() => {
        if (downloadMoreRef.current) downloadMoreRef.current.classList.remove('show');
        if (TableProps.shouldUpdateScroll && onScrollEnd) {
            tableRef.current?.scrollTop(0)
        }
    }, [data])

    useDidMountEffect(() => {
        let currentData = [];

        if (sortColumnState && sortTypeState) {
            currentData = sortColumns(sortColumnState, sortTypeState, undefined) ?? [];
        }
        else {
            currentData = data ? [...data] : [];
        }

        if (filters) {
            currentData = filterRows([...currentData]);
        }

        setData(currentData)

    }, [sortColumnState, sortTypeState, filters, data]);

    const headerIsShow = useMemo(()=>{
        return (!loading || !!data) && TableProps.showHeader !== false;
    }, [loading, data, TableProps])

    return (
        <div
            className='rs-table-container'
            style={{ height: (withTotalRows || renderFooter) && headerIsShow && !isError ? `calc(100% - ${defaultHeaderHeight}px)` : "100%" }}
            ref={tableContainerRef}
        >
            {isError ?
                <div className='rs-table-error'>
                    Ошибка получения данных
                </div> :
                (<>
                    <Table
                        className={TableProps.onRowClick ? "rs-table-clickable-rows" : ""}
                        data={filteredData}
                        fillHeight={true}
                        bordered
                        cellBordered
                        hover={false}
                        sortColumn={sortColumnState}
                        sortType={sortTypeState}
                        // onSortColumn={(dataKey, sortType) => handleSortColumn(dataKey, sortType)}
                        // handleSortColumn(column.cellProps.dataKey, "asc")
                        loading={loading}
                        headerHeight={defaultHeaderHeight}
                        rowHeight={defaultRowHeight}
                        locale={{
                            loading: "Загрузка",
                            emptyMessage: "Данных не найдено"
                        }}
                        {...TableProps}
                        ref={tableRef}
                        onScroll={(x, y) => {
                            if (onScrollEnd) handleScroll(x, y);
                            if (TableProps.onScroll) TableProps.onScroll(x, y);
                        }}
                    >
                        {columns.map((column, index) => {
                            const childrenLength = column.headerProps.children?.toString().length ?? 3;
                            const minWidth = column.columnProps.minWidth ?
                                column.columnProps.minWidth :
                                (
                                    (childrenLength * 9.5) +
                                    (column.columnProps.sortable ? 24 : 0) +
                                    (column.searchable ? 24 : 0) +
                                    24 +
                                    5
                                );

                            const width = column.columnProps.width === undefined && column.columnProps.flexGrow === undefined ? {
                                width: minWidth
                            } : null;

                            return <Column
                                key={`rsuite_column_${index}`}
                                minWidth={129}
                                {...width}
                                {...column.columnProps}
                            >
                                <HeaderCell
                                    {...column.headerProps}
                                    data-filtered={filters && !!filters.find(elem => elem.name === column.cellProps.dataKey)}
                                    onMouseUp={(e) => {
                                        if (column.columnProps.sortable) {
                                            const target = e.target as HTMLInputElement;
                                            if (
                                                !target.classList.contains("rs-table-cell-header-search-wrapper") &&
                                                !target.classList.contains("rs-table-cell-header-search-input") &&
                                                !target.parentElement?.classList.contains("rs-table-cell-header-search-input")
                                            ) {
                                                handleSortColumn(column.cellProps.dataKey ?? "");
                                            }
                                        }
                                    }}
                                >
                                    {column.headerProps.children}
                                    {column.searchable &&
                                        <RsuiteSearchInput
                                            dataKey={column.cellProps.dataKey ?? ""}
                                            showInput={(target) => {
                                                handleFilterColumn(target)
                                            }}
                                            filterRows={(filter: Filter) => filteringRows(filter)}
                                            cancelFilter={(dataKey: string) => cancelFilter(dataKey)}
                                        />}
                                </HeaderCell>
                                <Cell {...column.cellProps} />
                            </Column>
                        })}
                    </Table>

                    <div className='rs-table-footer-buttons'>
                        {onScrollEnd &&
                            <div
                                ref={downloadMoreRef}
                                className="rs-table-download-other-btn"
                                title='Загрузить еще'
                                onClick={onScrollEnd}
                            >
                                <MdFileDownload />
                            </div>}
                        <div
                            ref={scrollToTopRef}
                            className="rs-table-scroll-to-top"
                            title='Прокрутить вверх'
                            onClick={() => {
                                if (tableRef.current) {
                                    tableRef.current.scrollTop(0);
                                }
                            }}
                        >
                            <MdArrowUpward />
                        </div>
                    </div>

                    {(withTotalRows || renderFooter) &&
                        <div
                            className="rs-table-footer"
                            style={{ height: `${defaultHeaderHeight}px` }}
                        >
                            {renderFooter &&
                                <div className="rs-render-footer">
                                    {renderFooter}
                                </div>}

                            {(withTotalRows && !!data) &&
                                <div className='rs-total-rows'>
                                    {`Строк: ${data.length}`}
                                </div>}
                        </div>}
                </>)}
        </div>
    )
})

export default RsuiteTable