import { useEffect, RefObject } from "react";
import { addMaxHeightShowing, removeMaxHeightShowing } from "../utils/utils";

const transEnd = (toggleTarget: HTMLDivElement | null, event: TransitionEvent, overflowAfterTransEnd?: 'unset' | 'auto') => {
    removeMaxHeightShowing(toggleTarget, event);
    if (event.propertyName === "max-height" && toggleTarget === event.target) {
        if ((event.currentTarget as HTMLElement).classList.contains('show')) {
            (event.currentTarget as HTMLElement).style.overflow = overflowAfterTransEnd ?? 'unset';
        }
        else {
            (event.currentTarget as HTMLElement).style.overflow = 'hidden';
        }
    }
}

const handleToggle = (toggleTarget: HTMLDivElement | null, event: Event) => {
    setTimeout(() => {
        if (toggleTarget && !toggleTarget.contains(event?.target as Node)) {
            toggleTarget.classList.add('showing')
            toggleTarget.classList.toggle('show');
            if (toggleTarget.classList.contains('show')) {
                toggleTarget.focus();
            }
        }
    });
}

const clickOutside = (event: Event, toggler: HTMLDivElement | null, toggleTarget: HTMLDivElement | null) => {
    setTimeout(() => {
        if (
            !toggleTarget || 
            !toggleTarget.classList.contains("show") ||
            toggleTarget.contains((event?.target as Node)) || 
            toggler?.contains((event?.target as Node)) || 
            event.target === toggler
        ) {
            return;
        }
    
        if (toggleTarget.classList.contains('show')) {
            toggleTarget.classList.add('showing');
        }
        toggleTarget.classList.remove('show');
    });
};

type useShowingProps = {
    togglerRef: RefObject<HTMLDivElement>,
    toggleTargetRef: RefObject<HTMLDivElement>,
    overflowAfterTransEnd?: 'unset' | 'auto',
    closeOnClickOutside?: boolean
}

export default function useShowing({
    togglerRef,
    toggleTargetRef,
    overflowAfterTransEnd,
    closeOnClickOutside
}: useShowingProps) {
    useEffect(() => {
        if (togglerRef.current && toggleTargetRef.current) {
            togglerRef.current.addEventListener('click', (e) => handleToggle(toggleTargetRef.current, e));

            return () => {
                togglerRef.current?.removeEventListener('click', (e) => handleToggle(toggleTargetRef.current, e));
            }
        }
    }, [togglerRef]);

    useEffect(() => {
        if (toggleTargetRef.current) {
            toggleTargetRef.current.addEventListener("transitionrun", (e) => addMaxHeightShowing(toggleTargetRef.current, e));
            toggleTargetRef.current.addEventListener("transitionend", (e) => transEnd(toggleTargetRef.current, e, overflowAfterTransEnd));

            return () => {
                toggleTargetRef.current?.removeEventListener("transitionrun", (e) => addMaxHeightShowing(toggleTargetRef.current, e))
                toggleTargetRef.current?.removeEventListener("transitionend", (e) => transEnd(toggleTargetRef.current, e, overflowAfterTransEnd));
            };
        }
    }, [toggleTargetRef]);

    useEffect(() => {
        if (toggleTargetRef?.current && togglerRef?.current && closeOnClickOutside) {
            document.addEventListener("click", (e)=>clickOutside(e, togglerRef.current, toggleTargetRef.current));

            return () => {
                document.removeEventListener("click", (e)=>clickOutside(e, togglerRef.current, toggleTargetRef.current));
            };
        }
    }, [closeOnClickOutside]);
}