import { RowDataType } from "rsuite-table";
import { DropDownElem, ElementVariant, FormElemTypes } from "../../components/Form/FormElemTypes";
import { IRole } from "../../models/entities/IRole";
import { nameof } from "../../utils/utils";
import { ICountryRole } from "../../models/entities/ICountryRole";

export const getCreateRoleElements = (rolesOptions: DropDownElem['options']): ElementVariant[] => [
    {
        type: FormElemTypes.text,
        name: nameof<IRole>('name'),
        label: "Название",
        htmlAttributes: {
            placeholder: "Админ",
            required: true
        }
    },
    {
        type: FormElemTypes.dropDown,
        name: 'regions',
        label: "Регионы",
        options: rolesOptions,
        required: false,
        placeholder: 'Выберите регионы'
    },
    {
        type: FormElemTypes.radio,
        name: nameof<IRole>('isAdmin'),
        label: "Админ",
        items: [
            {
                label: 'Да',
                value: true,
            },
            {
                label: 'Нет',
                value: false
            }
        ],
        defaultValue: false,
        htmlAttributes: {
            required: true
        }
    }
];

export const getCreateRoleSAElements = (rolesOptions: DropDownElem['options']): ElementVariant[] =>
    getCreateRoleElements(rolesOptions).concat({
        type: FormElemTypes.radio,
        name: nameof<IRole>('isSuperAdmin'),
        label: "Супер-Админ",
        items: [
            {
                label: 'Да',
                value: true,
            },
            {
                label: 'Нет',
                value: false
            }
        ],
        defaultValue: false,
        htmlAttributes: {
            required: true
        }
    });

export const getUpdateRoleElements = (data: IRole | RowDataType, rolesOptions: DropDownElem['options']): ElementVariant[] => [
    {
        type: FormElemTypes.text,
        name: nameof<IRole>('id'),
        label: "id",
        htmlAttributes: {
            defaultValue: String(data.id),
            readOnly: true,
            required: true
        }
    },
    {
        type: FormElemTypes.text,
        name: nameof<IRole>('name'),
        label: "Название",
        htmlAttributes: {
            defaultValue: data.name,
            placeholder: data.name,
            required: true
        }
    },
    {
        type: FormElemTypes.radio,
        name: nameof<IRole>('isAdmin'),
        label: "Админ",
        items: [
            {
                label: 'Да',
                value: true,
            },
            {
                label: 'Нет',
                value: false
            }
        ],
        defaultValue: data.isAdmin,
        htmlAttributes: {
            required: true
        }
    }
];

export const getUpdateRoleSAElements = (
    data: IRole | RowDataType,
    rolesOptions: DropDownElem['options']
): ElementVariant[] => getUpdateRoleElements(data, rolesOptions).concat([{
    type: FormElemTypes.radio,
    name: nameof<IRole>('isSuperAdmin'),
    label: "Супер-Админ",
    items: [
        {
            label: 'Да',
            value: true,
        },
        {
            label: 'Нет',
            value: false
        }
    ],
    defaultValue: data.isSuperAdmin,
    htmlAttributes: {
        required: true
    }
}]);

export const getDeleteRoleElements = (data: IRole | RowDataType, rolesOptions: DropDownElem['options']): ElementVariant[] => [
    {
        type: FormElemTypes.text,
        name: nameof<IRole>('id'),
        label: "id",
        htmlAttributes: {
            defaultValue: String(data.id),
            readOnly: true,
            required: true
        }
    },
    {
        type: FormElemTypes.text,
        name: nameof<IRole>('name'),
        label: "Название",
        htmlAttributes: {
            defaultValue: data.name,
            disabled: true
        }
    },
    {
        type: FormElemTypes.dropDown,
        name: 'regions',
        label: "Регионы",
        options: rolesOptions,
        placeholder: data.isAdmin || data.isSuperAdmin ? 'Все регионы' : 'Регионы',
        isDisabled: true,
        value: !data.isAdmin && !data.isSuperAdmin && data.countryRoles?.map((role: ICountryRole) => ({
            label: role.region,
            value: String(role.regionId)
        }))
    },
    {
        type: FormElemTypes.text,
        name: nameof<IRole>('isAdmin'),
        label: "Админ",
        htmlAttributes: {
            disabled: true,
            defaultValue: data.isAdmin ? "Да" : "Нет"
        }
    }
];

export const getDeleteRoleSAElements = (
    data: IRole | RowDataType,
    rolesOptions: DropDownElem['options']
): ElementVariant[] => getDeleteRoleElements(data, rolesOptions).concat([{
    type: FormElemTypes.text,
    name: nameof<IRole>('isSuperAdmin'),
    label: "Супер-Админ",
    htmlAttributes: {
        required: true,
        disabled: true,
        defaultValue: data.isSuperAdmin ? "Да" : "Нет"
    }
}]);