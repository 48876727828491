import { ReactElementOr } from "sweetalert2-react-content";
import { ItemType } from "../utils/utilsTypes";

type SwalTableProps = {
    headers: {
        key: string
        name: string,
        width?: number,
        minWidth?: number,
        flexGrow?: number
    }[];
    rows: {
        [key: string]: React.ReactNode
    }[];
};

const SwalTable = ({ headers, rows }: SwalTableProps): ReactElementOr<'html'> => {
    let fixedWidth = 0;
    let flexGrowCount = 0;
    let tableMinWidth = 0;

    const getWidth = (header: ItemType<SwalTableProps['headers']>):string => {
        if (header.width) {
            return `${header.width}px`;
        }

        if (header.flexGrow) {
            return `calc((100% - ${fixedWidth}px) * ${header.flexGrow / flexGrowCount})`;
        }

        return ""
    }

    const getMinWidthNum = (header: ItemType<SwalTableProps['headers']>):number => {
        if (header.minWidth) {
            return header.minWidth;
        }
        else {
            return header.name.length * 11 + 10
        }
    }

    headers.forEach(header => {
        if (header.width) {
            fixedWidth += header.width;
        }
        if (header.flexGrow) {
            flexGrowCount += header.flexGrow;
        }
        if (!header.width && !header.flexGrow) {
            header.width = getMinWidthNum(header);
            fixedWidth += header.width;
        }
        if(!header.minWidth) {
            header.minWidth = getMinWidthNum(header);
        }
        tableMinWidth+=header.minWidth
    })

    return (
        <div 
            className="swal2-table" 
            style={{minWidth: `${tableMinWidth + 1}px`}}
        >
            <div className="swal2-table-header">
                {headers.map((header,idx) =>
                    <div
                        key={`swal2_header_${header.name + idx}`}
                        className="swal2-table-cell"
                        style={{ 
                            width: getWidth(header),
                            minWidth: `${header.minWidth}px`
                        }}
                    >
                        {header.name}
                    </div>
                )}
            </div>
            <div className="swal2-table-body">
                {rows.map((row, rowIdx) =>
                    <div 
                        className="swal2-table-row"
                        key={`swal2_row_${rowIdx}`}
                    >
                        {headers.map((header, headerIdx) =>
                            <div
                                key={`swal2_cell_${rowIdx+headerIdx}`}
                                className="swal2-table-cell"
                                style={{ 
                                    width: getWidth(header),
                                    minWidth: `${header.minWidth}px`
                                }}
                            >
                                {row[header.key]}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default SwalTable