import React from 'react';

function NotFoundedPage() {
    return (
        <main>
            <h2 className='p-3'>Такая страница не найдена</h2>
        </main>
    );
}

export default React.memo(NotFoundedPage);