import React, { memo } from 'react'

type NoDataProps = {
  message?: string,

}

const NoData = memo(({message}: NoDataProps) => {
  return (
    <div className='no-data'>
        {message ?? "Данных не найдено"}
    </div>
  )
})

export default NoData