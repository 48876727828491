import { memo, useLayoutEffect } from 'react';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PrivateRoute } from "./components/PrivateRoute";
import Header from './components/Header/Header';
import { routes } from './constants/routes';
import './styles/index.scss'
import "./styles/dark-theme.scss"
import './pages/authorization-pages/authorization.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'rsuite-table/lib/less/index.less';
import './components/rsuite-table/rsuite-table.scss'
import '@splidejs/react-splide/css';
import "react-datepicker/dist/react-datepicker.css";
import { history } from './utils/utils';
import { currentUser, darkTheme } from './store/globalVariables';
import { dispatch } from './store';
import { fetchReauth, setDarkTheme } from './store/reducers/UserReducer';
import { useIsOnline } from './hooks/isOnline';

function App() {
    const setCurrentVH = () => {
        document.documentElement.style.setProperty('--100vh', `${window.innerHeight}px`);
    }

    const setIsTouch = () => {
        let isTouchDevice =
            'ontouchstart' in document.documentElement ||
            navigator.maxTouchPoints > 0;

        if (isTouchDevice) {
            document.querySelector("html")?.classList.add("is-touch");
        }
    }

    const setIsMobile = () => {
        let deviceAgent = navigator.userAgent.toLowerCase();

        let isMobileDevice =
            deviceAgent.match(/(iphone|ipod|ipad)/) ||
            deviceAgent.match(/(android)/) ||
            deviceAgent.match(/(iemobile)/) ||
            deviceAgent.match(/iphone/i) ||
            deviceAgent.match(/ipad/i) ||
            deviceAgent.match(/ipod/i) ||
            deviceAgent.match(/blackberry/i) ||
            deviceAgent.match(/bada/i);

        if (isMobileDevice) {
            document.querySelector("html")?.classList.add("is-mobile");
        }
    }

    const setTheme = () => {
        if (darkTheme.value) {
            dispatch(setDarkTheme(true));
        }
        else if (localStorage.getItem('isDarkTheme') === null) {
            dispatch(setDarkTheme(false));
        }
    }

    useLayoutEffect(() => {
        setCurrentVH();

        setIsTouch();
        setIsMobile();
        setTheme();

        window.addEventListener("resize", setCurrentVH);

        return () => {
            window.removeEventListener("resize", setCurrentVH);
        }
    }, []);

    useLayoutEffect(() => {
        if (currentUser.value) dispatch(fetchReauth(currentUser.value.jwtToken));
    }, [])

    useIsOnline();

    history.navigate = useNavigate();
    history.location = useLocation();
    history.params = useParams();

    return (
        <div className="app">
            <Header />
            {/* <Suspense fallback={<div>Loading...</div>}> */}
            <Routes>
                {routes.map(({ path, isPrivateRoute, Component, onlyAdmin }, index) =>
                    <Route
                        key={index}
                        path={path}
                        element={
                            isPrivateRoute ?
                                <PrivateRoute
                                    Component={Component}
                                    onlyAdmin={onlyAdmin}
                                /> :
                                <Component />
                        } />
                )}
            </Routes>
            {/* </Suspense> */}
        </div>
    );
}

export default memo(App);
