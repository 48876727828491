export const timeZones = [
    "",
    "+02:00",
    "+03:00",
    "+04:00",
    "+05:00",
    "+06:00",
    "+07:00",
    "+08:00",
    "+09:00",
    "+10:00",
    "+11:00",
    "+12:00"
]