import React, { createRef, memo, useRef } from 'react'
import { history, nameof } from '../../utils/utils';
import IErrorCountByComplex from '../../models/responses/IErrorCountByComplex';
import RsuiteTable, { ColumnsList } from '../../components/rsuite-table/RsuiteTable';
import RenderData from '../../components/render-condition/RenderData';
import LoadingBlock from '../../components/LoadingBlock';
import { MonitoringPanelLocState } from '../monitoring-page/MonitoringPanel/MonitoringPanel';

type ErrorsCountTableProps = {
    data: IErrorCountByComplex[] | undefined
    isLoading: boolean
    isSuccess: boolean
}

const columns: ColumnsList = [
    {
        columnProps: {
            sortable: true,
            flexGrow: 1,
            fullText: true
        },
        headerProps: {
            children: 'Регион'
        },
        cellProps: {
            dataKey: nameof<IErrorCountByComplex>('region')
        },
        searchable: true
    },
    {
        columnProps: {
            sortable: true,
            flexGrow: 1,
            fullText: true
        },
        headerProps: {
            children: "Система"
        },
        cellProps: {
            dataKey: nameof<IErrorCountByComplex>('complex_type')
        },
        searchable: true
    },
    {
        columnProps: {
            sortable: true,
            fullText: true
        },
        headerProps: {
            children: "Ошибок"
        },
        cellProps: {
            dataKey: nameof<IErrorCountByComplex>('count')
        },
        searchable: true
    }
];

const ErrorsCountTable = memo(({ data, isLoading, isSuccess }: ErrorsCountTableProps) => {
    return (
        <div className='home-errors-count-table'>
            {!!data && !isLoading ?
                <RenderData
                    component={<RsuiteTable
                        virtualized={true}
                        data={data}
                        columns={columns}
                        withTotalRows
                        onRowClick={(row: IErrorCountByComplex) => {
                            const locState: MonitoringPanelLocState = {
                                regions: [row.region_id],
                                complexTypes: [row.complex_type_id],
                                startDateTypeDate: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24)
                            }
                            if (history.navigate) {
                                history.navigate(
                                    '/Monitoring',
                                    {
                                        state: locState
                                    }
                                )
                            }
                        }}
                        locale={{
                            emptyMessage: "Данных за 24 часа не найдено"
                        }}
                    />}
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                /> : <LoadingBlock rounded />}
        </div>
    )
})

export default ErrorsCountTable