import { RefObject } from 'react';
import { useEffect } from 'react';

export function useShowContextMenu(container: RefObject<HTMLElement | null> | null, contextMenu: RefObject<HTMLDivElement | null>, ignoringBlock?: RefObject<HTMLDivElement | null>) {
    const showContextMenu = (e: MouseEvent) => {
        if (ignoringBlock?.current?.contains(e.target as Node)) {
            return;
        }

        if (contextMenu.current) {
            e.preventDefault();
            const x = e.pageX;
            const y = e.pageY;

            contextMenu.current.classList.add("show")
            contextMenu.current.style.top = `${y}px`;
            contextMenu.current.style.left = `${x}px`;
        }
    }

    useEffect(() => {
        if (container?.current && contextMenu.current) {
            container.current.addEventListener("contextmenu", showContextMenu)
        }

        return () => {
            if (container?.current && contextMenu.current) {
                container.current.removeEventListener("contextmenu", showContextMenu)
            }
        }
    }, [container?.current, contextMenu.current, ignoringBlock?.current])
}