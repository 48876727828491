import { memo } from 'react'
import DatePicker, { registerLocale, ReactDatePickerProps } from "react-datepicker";
import './DateTimePicker.scss'
import ru from "date-fns/locale/ru";

registerLocale("ru", ru);

export type DateTimeRangePickerProps = {
    setStartDate: (value: Date | null) => void,
    setEndDate: (value: Date | null) => void,
    startDate: Date | null,
    endDate: Date | null,
    positionStartDate: ReactDatePickerProps['popperPlacement'],
    positionEndDate: ReactDatePickerProps['popperPlacement']
}

const DateTimeRangePicker = memo(({
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    positionStartDate,
    positionEndDate
}: DateTimeRangePickerProps) => {
    return (
        <div className="date-time-range-picker">
            <DatePicker
                dateFormat="dd.MM.yyyy pp"
                selected={startDate}
                onChange={(value) => setStartDate(value)}
                locale='ru'
                timeIntervals={60}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                showTimeInput
                maxDate={endDate}
                isClearable
                placeholderText="От"
                // withPortal={datePickerView === 'portal' ? true : false}
                className="w-100 text-truncate text-center"
                popperPlacement={positionStartDate}
                shouldCloseOnSelect={false}
                preventOpenOnFocus={true}
                name="startDateTimePicker"
            />

            <DatePicker
                dateFormat="dd.MM.yyyy pp"
                selected={endDate}
                onChange={(value) => setEndDate(value)}
                locale='ru'
                timeIntervals={60}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                showTimeInput
                minDate={startDate}
                isClearable
                placeholderText="До"
                // withPortal={datePickerView === 'portal' ? true : false}
                className="w-100 text-truncate text-center"
                popperPlacement={positionEndDate}
                shouldCloseOnSelect={false}
                preventOpenOnFocus={true}
                name="endDateTimePicker"
            />
        </div>
    )
})

export default DateTimeRangePicker