import { memo, useMemo, useRef, useCallback, ReactNode, Suspense, lazy } from 'react'
import FiltersHeader from '../../components/filters-header-line/FiltersHeader'
import { MdError, MdExpandMore, MdSort } from 'react-icons/md'
import { IPrometheusActiveTarget } from '../../models/entities/IPrometheusActiveTarget'
import LoadingBlock from '../../components/LoadingBlock'
import { GetAlertsHistoryRequest } from '../../models/requests/IGetAlertsHistoryRequest'
import RsuiteTable, { ColumnsList } from '../../components/rsuite-table/RsuiteTable'
import { RowDataType, SortType } from 'rsuite-table'
import { RSwal } from '../../constants/sweetAlert'
import { useLazyGetDiskUsageQuery, useLazyGetGraphsDataQuery } from '../../services/ServerLoadService'
import IPrometheusQueryRange from '../../models/entities/IPrometheusQueryRange'
import { selectColor } from '../../store/globalVariables'
import CustomXAxisTick, { CustomXAxisTickProps } from '../home-page/Graphs/CustomXAxisTick'
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'
import { IPrometheusQueryData, IPrometheusGraphValue, ResultTypes } from '../../models/responses/IPrometheusQueryRangeResponse'
import FiltersMenu, { Sort } from '../../components/filters-menu/FiltersMenu'
import { nameof } from '../../utils/utils'

const ResponsiveContainer = lazy(() => import('recharts').then(module => ({ default: module.ResponsiveContainer })));
// const LineChart = lazy(() => import('recharts').then(module => ({ default: module.LineChart })));
// const Line = lazy(() => import('recharts').then(module => ({ default: module.Line })));
// const Legend = lazy(() => import('recharts').then(module => ({ default: module.Legend })));
// const XAxis = lazy(() => import('recharts').then(module => ({ default: module.XAxis })));
// const YAxis = lazy(() => import('recharts').then(module => ({ default: module.YAxis })));
// const CartesianGrid = lazy(() => import('recharts').then(module => ({ default: module.CartesianGrid })));
// const Tooltip = lazy(() => import('recharts').then(module => ({ default: module.Tooltip })));

type ServersAvailabilityProps = {
    getTargets: (filters?: GetAlertsHistoryRequest) => void,
    targets: IPrometheusActiveTarget[] | undefined,
    targetsIsLoading: boolean
}

const getLoadChart = (data: IPrometheusQueryData) => {
    if (ResultTypes.matrix !== data.resultType) {
        return;
    }

    const graphs = data.result;

    const currentData: IPrometheusGraphValue[] = JSON.parse(JSON.stringify(graphs[0].values));

    for (let index = 1; index < graphs.length; index++) {
        const currentGraphValues = graphs[index].values;

        if (currentGraphValues) {
            currentGraphValues.forEach((value, idx) => {
                const currentItem = currentData[idx];
                currentItem[Object.keys(currentItem).length] = value[1];
            });
        }
    }

    return <Suspense fallback={<div>Загрузка...</div>}>
        <ResponsiveContainer
            className='line-chart'
            width='100%'
            height={450}
        >
            <LineChart
                data={currentData}
                margin={{
                    right: 15
                }}
            >
                <Legend
                    verticalAlign="top"
                    wrapperStyle={{ top: 0, right: 0, width: '100%' }}
                />
                <CartesianGrid stroke="#ccc" strokeWidth={1} />
                <XAxis
                    dataKey={0}
                    angle={-45}
                    dy={10}
                    dx={-10}
                    minTickGap={3}
                    height={50}
                    fontSize={12}
                    tick={(tickProps: CustomXAxisTickProps['tickProps']) => {
                        const currentTickValue = new Date(Number(currentData[tickProps.payload.index][0] + '000'));

                        tickProps.payload.value = `${currentTickValue.getHours()}:${currentTickValue.getMinutes().toString().padStart(2, '0')}`
                        return <CustomXAxisTick
                            tickProps={tickProps}
                        />
                    }}
                />
                <YAxis
                    dataKey={1}
                    tickCount={9}
                    domain={[0, 100]}
                    unit="%"
                />
                <Tooltip content={(content) => {
                    if (content.payload && content.payload.length) {
                        const currentPayload = content.payload;
                        const currentDate = new Date(Number(currentPayload[0].payload[0] + '000'));
                        const currentTime = `${currentDate.getHours()}:${currentDate.getMinutes().toString().padStart(2, '0')}`

                        return <div
                            className='text-light p-2 border rounded'
                            style={{ backgroundColor: 'var(--dark)', opacity: 0.85 }}
                        >
                            <div>{currentDate.toLocaleDateString()}</div>

                            <div className='border-bottom'>
                                {currentTime}
                            </div>

                            {currentPayload.map((item, idx) =>
                                <div key={idx}>
                                    {item.name}: {Number(item.value).toFixed(2)}%
                                </div>)}
                        </div>
                    }
                }} />
                <Line
                    type="monotone"
                    name='ЦПУ'
                    dataKey={1}
                    isAnimationActive={false}
                    stroke={selectColor(0)}
                    strokeWidth={2}
                    activeDot={{ r: 8 }}
                    dot={{
                        fill: selectColor(0)
                    }}
                />
                <Line
                    type="monotone"
                    name='ОЗУ'
                    dataKey={2}
                    isAnimationActive={false}
                    stroke={selectColor(1)}
                    strokeWidth={2}
                    activeDot={{ r: 8 }}
                    dot={{
                        fill: selectColor(1)
                    }}
                />
            </LineChart>

        </ResponsiveContainer>
    </Suspense>;
}

const getDiskUsageHtml = (data: IPrometheusQueryData) => {
    if (ResultTypes.vector !== data.resultType) {
        return <div className="text-danger">
            Ошибка типа результата для данных памяти
        </div>
    }

    return <table className='float-none'>
        <tbody>
            {data.result.map((item,idx) =>
                <tr key={idx}>
                    <th className='pe-2'>{item.metric.mountpoint}:</th>
                    <td>{Number(item.value[1]).toFixed(2)}%</td>
                </tr>
            )}
        </tbody>
    </table>
}

const ServersAvailability = memo(({ getTargets, targets, targetsIsLoading }: ServersAvailabilityProps) => {
    const availableSortRef = useRef<HTMLDivElement>(null);
    const availableSortMenuRef = useRef<HTMLDivElement>(null);

    const columns: ColumnsList = useMemo(() => [
        {
            columnProps: {
                flexGrow: 1
            },
            headerProps: {
                children: ''
            },
            cellProps: {
                dataKey: '',
                children: (targetData: RowDataType) => {
                    const target = targetData as IPrometheusActiveTarget;

                    return <div
                        onClick={() => showServerDetails(target)}
                        className="w-100 h-100 row mx-0 d-flex flex-nowrap align-items-center"
                    >
                        <div 
                            className='col-sm-4 col fs-09 text-truncate'
                            title='Название сервера'
                        >
                            {target.labels.service}
                        </div>

                        {target.alerts.length > 0 &&
                            <div className='col-sm-4 col-auto d-flex-center-center'>
                                {target.alerts.length > 0 &&
                                    <div
                                        className="badge bg-danger d-flex-center-center w-min"
                                        title='Количество проблем сервера'
                                    >
                                        {target.alerts.length}
                                    </div>}
                            </div>}

                        <div className="col-sm-4 col-auto ms-auto d-flex align-items-center justify-content-end">
                            {target.health === "up" ?
                                <span
                                    className='badge bg-success px-2'
                                    title='Сервер доступен'
                                >
                                    Доступен
                                </span> :
                                <span
                                    className='badge bg-danger px-2'
                                    title='Сервер недоступен'
                                >
                                    Недоступен
                                </span>}
                        </div>
                    </div>
                }
            }
        }
    ], []);

    const [getDiskUsage] = useLazyGetDiskUsageQuery();
    const [getGraphsData] = useLazyGetGraphsDataQuery();

    const showServerDetails = useCallback(async (target: IPrometheusActiveTarget) => {
        RSwal.showLoading();

        const diskUsage = await getDiskUsage(target.labels.instance, true).unwrap();

        const graphFilters: IPrometheusQueryRange = {
            instance: target.labels.instance,
            job: target.labels.job,
            service: target.labels.service,
            step: 300,
            start: new Date(new Date().setHours(new Date().getHours() - 12)).toLocaleString(),
            end: new Date().toLocaleString()
        };

        const graphsData = await getGraphsData(graphFilters, true).unwrap();

        RSwal.fire({
            customClass: {
                popup: 'swal2-modal-lg-html'
            },
            showCloseButton: true,
            title: target.labels.service,
            html: <div className="accordion" id="serverLoadAccordion">
                {/* Проблемы */}
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button btn btn-primary"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#problems"
                            aria-expanded={graphsData.data.data?.result.length ? false : true}
                            aria-controls="problems"
                        >
                            Проблемы
                            {target.alerts.length > 0 &&
                                <div className="badge bg-danger ms-1">
                                    {target.alerts.length}
                                </div>}

                            <MdExpandMore className='ms-auto expand-more' />
                        </button>
                    </h2>
                    <div
                        id="problems"
                        className={"collapse " + (graphsData.data.data?.result.length ? "" : "show")}
                        data-bs-parent="#serverLoadAccordion"
                    >
                        <div className="accordion-body text-center">
                            {target.health === 'up' ?
                                (target.alerts.length > 0 ?
                                    target.alerts.map(alert => <div>
                                        <MdError className='text-danger me-1' />
                                        {alert.name}
                                    </div>) :
                                    "Проблем не обнаружено") :
                                <div className='text-danger'>
                                    {target.lastError}
                                </div>}
                        </div>
                    </div>
                </div>

                {/* Графики нагрузок */}
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#loadGraph"
                            aria-expanded={graphsData.data.data?.result.length ? true : false}
                            aria-controls="loadGraph"
                        >
                            Графики нагрузок

                            <MdExpandMore className='ms-auto expand-more' />
                        </button>
                    </h2>
                    <div
                        id="loadGraph"
                        className={"collapse " + (graphsData.data.data?.result.length ? "show" : "")}
                        data-bs-parent="#serverLoadAccordion"
                    >
                        <div className="accordion-body text-center">
                            {graphsData.data.data?.result.length ?
                                getLoadChart(graphsData.data.data) :
                            <div className='text-danger'>Ошибка получения графиков</div>}
                        </div>
                    </div>
                </div>

                {/* Память */}
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#memory"
                            aria-expanded="false"
                            aria-controls="memory"
                        >
                            Память

                            <MdExpandMore className='ms-auto expand-more' />
                        </button>
                    </h2>
                    <div
                        id="memory"
                        className="collapse"
                        data-bs-parent="#serverLoadAccordion"
                    >
                        <div className="accordion-body">
                            {diskUsage.data.data?.result.length ?
                                getDiskUsageHtml(diskUsage.data.data) :
                                <div className='text-danger text-center'>Ошибка получения данных памяти</div>}
                        </div>
                    </div>
                </div>
            </div>
        })
    }, [])

    const sortValueRef = useRef<Sort>();

    const setSortValueRef = useRef<(sortColumn: string | undefined, sortType: SortType | undefined)=>void>();

    return (
        <div className="server-load-table col-12 col-md px-0 h-100">
            <FiltersHeader
                title='Работоспособность'
                items={[{
                    btn: {
                        content: !false ?
                            <MdSort /> :
                            <span className="spinner-border spinner-border-sm pointer-events-none" />,
                        innerRef: availableSortRef
                    },
                    menu: {
                        content: <FiltersMenu
                            filtersMenuTogglerRef={availableSortRef}
                            filtersMenuRef={availableSortMenuRef}
                            listSorts={[
                                {
                                    name: "По названию",
                                    value: `${nameof<IPrometheusActiveTarget>('labels')}.${nameof<IPrometheusActiveTarget['labels']>('service')}`
                                },
                                {
                                    name: "По доступности",
                                    value: nameof<IPrometheusActiveTarget>('health')
                                },
                                {
                                    name: "По проблемам",
                                    value: nameof<IPrometheusActiveTarget>('alerts')
                                },
                            ]}
                            sortValueRef={sortValueRef}
                            setSortValueRef={setSortValueRef}
                        />,
                        innerRef: availableSortMenuRef
                    }
                }]}
            />

            {!targetsIsLoading ?
                <RsuiteTable
                    data={targets}
                    columns={columns}
                    virtualized
                    showHeader={false}
                    rowHeight={60}
                    bordered={false}
                    rowClassName="alert-item"
                    setSortValueRef={setSortValueRef}
                /> :
                <LoadingBlock />
            }
        </div>
    )
})

export default ServersAvailability