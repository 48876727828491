import { memo, DetailedHTMLProps, useState, ReactNode } from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import "./pagination.scss"

type PaginationListProps = {
    list: ReactNode[] | undefined
} & DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const PaginationList = memo(({ list, ...htmlDivProps }: PaginationListProps) => {
    const [selectedItem, selectItem] = useState<number>(0);

    const handleSelectItem = (type: "next" | "prev") => {
        if (type === 'next') {
            selectItem(prev => {
                if (prev !== list?.length! - 1) {
                    return ++prev;
                }

                return prev;
            })
        }
        else {
            selectItem(prev => {
                if (prev !== 0) {
                    return --prev;
                }

                return prev;
            })
        }
    }
    
    return (
        <div
            {...htmlDivProps}
            className={'pagination ' + (htmlDivProps.className ?? "")}
        >

            {list?.length ?
                <>
                    <div className="pagination-page-content">
                        {list[selectedItem]}
                    </div>

                    <div className='pagination-line'>
                        <button
                            className="pagination-indicator dynamic-btn"
                            onClick={() => handleSelectItem("prev")}
                            disabled={selectedItem === 0}
                        >
                            <MdKeyboardArrowLeft />
                        </button>

                        <div className="pagination-pages-number">
                            {selectedItem + 1}/{list.length}
                        </div>

                        <button
                            className="pagination-indicator dynamic-btn"
                            onClick={() => handleSelectItem("next")}
                            disabled={selectedItem === list?.length! - 1}
                        >
                            <MdKeyboardArrowRight />
                        </button>
                    </div>
                </> :
                <div className='text-danger h-100 w-100 d-flex-center-center'>
                    Данных не найдено
                </div>}
        </div>
    )
})

export default PaginationList