import React, { useRef } from 'react'
import './monitoring.scss'
import MonitoringPanel from './MonitoringPanel/MonitoringPanel';
import ErrorFullInfo from './ErrorFullInfo/ErrorFullInfo';

function MonitoringPage() {
    const monitoringPageRef = useRef<HTMLDivElement>(null);
    
    return (
        <div 
            className="monitoring-page row mx-0"
            ref={monitoringPageRef}
        >
            <div className="col-md-4 col-xl-3 col-12 p-3 h-100">
                <MonitoringPanel monitoringPageRef={monitoringPageRef} />
            </div>

            <div className="col-md-8 col-xl-9 col-12 px-0 h-100">
                <ErrorFullInfo monitoringPageRef={monitoringPageRef} />
            </div>
        </div>
    )
}

export default React.memo(MonitoringPage)