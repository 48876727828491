import {
    MdAdminPanelSettings,
    MdDns,
    MdHome,
    MdLegendToggle,
    MdLocalAtm,
    MdQueryStats,
    MdSystemUpdateAlt,
    MdTaskAlt,
    MdWysiwyg
} from "react-icons/md";
import { FaLayerGroup } from "react-icons/fa";



export interface INavItem {
    name: string;
    path?: string;
    onlyAdmin?: boolean;
    icon?: JSX.Element;
    childs?: INavItemChild[];
}

export interface INavItemChild {
    name: string;
    path: string;
}

export const navItems : INavItem[] = [
    {
        name: "Главная страница",
        path: "/",
        icon: <MdHome />
    },
    {
        onlyAdmin: true,
        name: "Системы",
        icon: <MdWysiwyg />,
        childs: [
            {
                name: "Регионы",
                path: "/Regions"
            },
            {
                name: "Типы систем",
                path: "/ComplexType"
            },
            {
                name: "Подключенные системы",
                path: "/ComplexList"
            },
            {
                name: "Типы продуктовых метрик",
                path: "/ProductMetricTypes"
            },
            {
                name: "История обновлений",
                path: "/UpdateHistory"
            }
        ]
    },
    {
        name: "Мониторинг Live",
        path: "/Monitoring",
        icon: <MdLegendToggle />
    },
    {
        onlyAdmin: true,
        name: "Клиентская статистика",
        path: "/ClientsStatistic",
        icon: <MdQueryStats />
    },
    {
        onlyAdmin: true,
        name: "Продуктовые метрики",
        path: "/ProductMetrics",
        icon: <MdLocalAtm />
    },
    {
        onlyAdmin: true,
        name: "Нагрузки серверов",
        path: "/ServerLoad",
        icon: <MdDns />
    },
    {
        onlyAdmin: true,
        name: "Группировка ошибок",
        icon: <FaLayerGroup style={{width: 20, height: 20}}/>,
        childs: [
            {
                name: "Группы ошибок",
                path: "/ErrorGroups"
            },
            {
                name: "Триггеры",
                path: "/ErrorTriggers"
            }
        ]
    },
    // {
    //     onlyAdmin: true,
    //     name: "Группы ошибок",
    //     path: "/ErrorGroups",
    //     icon: <FaLayerGroup style={{width: 20, height: 20}} />
    // },
    // {
    //     onlyAdmin: true,
    //     name: "Обновление систем",
    //     path: "/ServerManager",
    //     icon: <MdSystemUpdateAlt />
    // },
    {
        onlyAdmin: true,
        name: "Администрирование",
        icon: <MdAdminPanelSettings />,
        childs: [
            {
                name: "Пользователи",
                path: "/Users"
            },
            {
                name: "Роли",
                path: "/Roles"
            }]
    }
];