import React, { memo } from 'react'

type LoadingBlockProps = {
    width?: string | number,
    height?: string | number
    rounded?: boolean 
}

const LoadingBlock = memo(({ width = '100%', height = '100%', rounded = false }: LoadingBlockProps) => {
    const currentWidth = typeof width === 'number' ? `${width}px` : width;
    const currentHeight = typeof height === 'number' ? `${height}px` : height;

    return (
        <div
            className={`loading-block ${rounded ? 'rounded' : ''}`}
            style={{
                width: currentWidth,
                height: currentHeight
        }}
        />
    )
})

export default LoadingBlock