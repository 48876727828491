import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {defaultBaseQueryParams} from "../constants/defaultBaseQueryParams";
import { IDefaultDataResponse, IDefaultResponse } from "../models/responses/IDefaultResponse";
import { IRole } from "../models/entities/IRole";
import { currentUser } from "../store/globalVariables";
import { dispatch } from "../store";
import { fetchReauth } from "../store/reducers/UserReducer";
import { IUser } from "../models/entities/IUser";

export const roleApi = createApi({
    reducerPath: 'roleApi',
    baseQuery: fetchBaseQuery(defaultBaseQueryParams),
    tagTypes: ['Role'],
    endpoints: build => ({
        getAllRoles: build.query<IDefaultDataResponse<IRole[]>, void>({
            query: ()  => ({
                url: `/Users/GetRoles`,
                method: "GET"
            }),
            providesTags: (result) =>
                result?.data? [
                    ...result.data.map(({ id }) => ({ type: 'Role' as const, id })),
                    { type: 'Role', id: 'LIST' },
                    ]
                : [{ type: 'Role', id: 'LIST' }]
        }),
        createRole: build.mutation<IDefaultResponse, IRole>({
            query: (user)  => ({
                url: "/Users/AddRole",
                body: user,
                method: "POST"
            }),
            invalidatesTags: [{ type: 'Role', id: 'LIST' }]
        }),
        updateRole: build.mutation<IDefaultResponse, IRole>({
            query: (user)  => ({
                url: `/Users/UpdateRole/${user.id}`,
                body: user,
                method: "PUT"
            }),
            transformResponse: async (response: IDefaultResponse, meta, arg) => {
                if(currentUser.value?.id === arg.id && response.success) {
                    await dispatch(fetchReauth()).unwrap();
                }
                return response;
            },
            invalidatesTags: [{ type: 'Role', id: 'LIST' }]
        }),
        deleteRole: build.mutation<IDefaultResponse | IDefaultDataResponse<{relatedRoles: IUser[]}>, number>({
            query: (id)  => ({
                url: `/Users/DeleteRole/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: 'Role', id: 'LIST' }]
        }),
    })
})

export const {
    useGetAllRolesQuery,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useDeleteRoleMutation
} = roleApi;