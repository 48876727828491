import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {defaultBaseQueryParams} from "../constants/defaultBaseQueryParams";
import { IErrorBriefInfo } from "../models/responses/IErrorBriefInfo";
import { IErrorsFilters } from "../models/requests/IErrorsFilters";
import { IError } from "../models/entities/IError";
import { IDefaultDataResponse } from "../models/responses/IDefaultResponse";
import { IdenticalErrorsRequest } from "../models/requests/IdenticalErrorsRequest";
import { IGetFilters } from "../models/responses/IGetFilters";
import { RSwal } from "../constants/sweetAlert";

export const errorApi = createApi({
    reducerPath: 'errorApi',
    baseQuery: fetchBaseQuery(defaultBaseQueryParams),
    endpoints: build => ({
        getErrors: build.query<IDefaultDataResponse<IErrorBriefInfo[]>, IErrorsFilters>({
            query: (filters)  => ({
                url: '/PageData/GetErrors',
                method: "POST",
                body: filters
            }),
            // Only have one cache entry because the arg always maps to one string
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
              return endpointName
            },
            // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, other) => {
                if (other.arg.lastId) {
                    currentCache.data.push(...newItems.data)
                }
                else {
                    currentCache.data = newItems.data
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ previousArg, currentArg }) {
                return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
            },
            transformResponse: (errors: IDefaultDataResponse<IErrorBriefInfo[]>) => {
                if(errors?.data && errors.data.length === 0) {
                    RSwal.fire({
                        icon: 'info',
                        title: 'Таких ошибок больше не найдено. Увеличьте период отбора в фильтрах (по умолчанию за последние 5 дней) и попробуйте снова'
                    })
                }
                return errors;
            }
        }),
        getError: build.query<IDefaultDataResponse<IError>, number>({
            query: (id)  => ({
                url: `/PageData/GetError/${id}`,
                method: "GET"
            }),
            transformResponse: (res: IDefaultDataResponse<IError>) => {
                if(res.success) {
                    res.data.datemsk = res.data.datemsk.split('.')[0].replace('T', ' ');
                    res.data.dater = res.data.dater.split('.')[0].replace('T', ' ');
                }
                
                return res;
            }
        }),
        getFilters: build.query<IDefaultDataResponse<IGetFilters>, void>({
            query: ()  => ({
                url: '/Filter/GetFilters',
                method: "GET"
            })
        }),
        getIdenticalErros: build.query<IDefaultDataResponse<IError[]>, IdenticalErrorsRequest>({
            query: (data)  => ({
                url: '/Issues/GetIdenticalErrors',
                method: "POST",
                body: data
            })
        }),
    })
})

export const {
    useLazyGetErrorsQuery,
    useGetErrorQuery,
    useGetFiltersQuery,
    useLazyGetIdenticalErrosQuery
} = errorApi;