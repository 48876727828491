import React, {useMemo} from 'react';
import PageTitle from '../../components/PageTitle';
import RsuiteTable, { ColumnsList } from '../../components/rsuite-table/RsuiteTable';
import LoadingBlock from '../../components/LoadingBlock';
import { useGetGroupedErrorsQuery } from '../../services/IssueService';
import { GroupedError, IIssue, IdenticalError } from '../../models/entities/IIssue';
import { nameof } from '../../utils/utils';
import { defaultPollingInterval } from '../../store/globalVariables';
import { RSwal } from '../../constants/sweetAlert';

function UnresolvedIssuesPage() {
    const {data: groupedErrors, refetch: getGroupedErrors, isFetching, fulfilledTimeStamp} = useGetGroupedErrorsQuery(
        undefined,
        { 
            pollingInterval: defaultPollingInterval, 
        }
    );

    const groupedErrorsColumns: ColumnsList = useMemo(()=>[
        {
            columnProps: {
                sortable: true,
                fixed: true,
                width: 60
            },
            headerProps: {
                children: "id"
            },
            cellProps: {
                dataKey: "id"
            }
        },
        {
            columnProps: {
                sortable: true,
                flexGrow: 1,
                fullText: true
            },
            headerProps: {
                children: "Название"
            },
            cellProps: {
                dataKey: nameof<GroupedError>("exception_name")
            },
            searchable: true
        },
        {
            columnProps: {
                sortable: true,
                flexGrow: 1,
                fullText: true
            },
            headerProps: {
                children: "Система"
            },
            cellProps: {
                dataKey: nameof<GroupedError>("complex_type")
            },
            searchable: true
        },
        {
            columnProps: {
                sortable: true,
                flexGrow: 2,
                fullText: true
            },
            headerProps: {
                children: "Текст ошибки"
            },
            cellProps: {
                dataKey: nameof<GroupedError>("msg")
            },
            searchable: true
        },
        {
            columnProps: {
                sortable: true,
                width: 190
            },
            headerProps: {
                children: "Кол-во ошибок"
            },
            cellProps: {
                dataKey: nameof<GroupedError>("errors_count")
            },
            searchable: true
        }
    ], [])

    const identicalErrorsColumns: ColumnsList = useMemo(()=>[
        {
            columnProps: {
                sortable: true,
                fixed: true,
                width: 100
            },
            headerProps: {
                children: "id"
            },
            cellProps: {
                dataKey: "id",
                children: (rowData) => {
                    const row = rowData as IdenticalError;
                    return <a
                        className='text-body p-2 w-100 h-100'
                        target='_blank'
                        href={`${window.location.origin + process.env.PUBLIC_URL}/Monitoring/${row.id}`}
                    >
                        {row.id}
                    </a>
                }
            }
        },
        {
            columnProps: {
                sortable: true,
                flexGrow: 1,
                fullText: true
            },
            headerProps: {
                children: "Название"
            },
            cellProps: {
                dataKey: nameof<IdenticalError>("exception_name"),
                children: (rowData) => {
                    const row = rowData as IdenticalError;
                    return <a
                        className='text-truncate text-body p-2 w-100 h-100'
                        target='_blank'
                        href={`${window.location.origin + process.env.PUBLIC_URL}/Monitoring/${row.id}`}
                    >
                        {row.exception_msg?.substring(0, 250) ??
                            row.exception_name?.substring(0, 250) ??
                            row.msg?.substring(0, 100) ??
                            ""}
                    </a>
                }
            },
            searchable: true
        },
        {
            columnProps: {
                sortable: true,
                flexGrow: 1,
                fullText: true
            },
            headerProps: {
                children: "Система"
            },
            cellProps: {
                dataKey: nameof<IdenticalError>("complex_type"),
                children: (rowData) => {
                    const row = rowData as IdenticalError;
                    return <a
                        className='text-truncate text-body p-2 w-100 h-100'
                        target='_blank'
                        href={`${window.location.origin + process.env.PUBLIC_URL}/Monitoring/${row.id}`}
                    >
                        {row.complex_type}
                    </a>
                }
            },
            searchable: true
        },
        {
            columnProps: {
                sortable: true,
                flexGrow: 2,
            },
            headerProps: {
                children: "Текст ошибки"
            },
            cellProps: {
                dataKey: nameof<IdenticalError>("msg"),
                children: (rowData) => {
                    const row = rowData as IdenticalError;
                    return <a
                        className='text-truncate text-body p-2 w-100 h-100'
                        target='_blank'
                        href={`${window.location.origin + process.env.PUBLIC_URL}/Monitoring/${row.id}`}
                    >
                        {row.msg.length > 500 ? row.msg.substring(0, 500) : row.msg}
                    </a>
                }
            },
            searchable: true
        },
        {
            columnProps: {
                sortable: true,
                flexGrow: 1,
                fullText: true
            },
            headerProps: {
                children: "Дата"
            },
            cellProps: {
                dataKey: nameof<IdenticalError>("datemsk"),
                children: (rowData) => {
                    const row = rowData as IdenticalError;
                    return <a
                        className='text-truncate text-body p-2 w-100 h-100'
                        target='_blank'
                        href={`${window.location.origin + process.env.PUBLIC_URL}/Monitoring/${row.id}`}
                    >
                        {row.datemsk}
                    </a>
                }
            },
            searchable: true
        }
    ] as ColumnsList, [])

    const showRowErrors = (groupedError: GroupedError) => {
        RSwal.fire({
            title: `${groupedError.exception_name} | ${groupedError.complex_type}`,
            html: <RsuiteTable
                data={groupedError.identical_errors}
                columns={identicalErrorsColumns}
                withTotalRows
                virtualized
                className='text-start'
            />,
            showCloseButton: true,
            customClass: {
                popup: 'swal2-modal-lg-html'
            }
        })
    }

    return (
        <main>
            <PageTitle
                title='Группы ошибок'
                reloadPage={() => getGroupedErrors()}
                isLoading={isFetching}
                pageUpdateTime={!isFetching && fulfilledTimeStamp ? 
                    new Date(fulfilledTimeStamp).toLocaleString().replace(',', "") : 
                    ""}
            />

            {groupedErrors?.data ?
                <RsuiteTable
                    data={groupedErrors.data}
                    columns={groupedErrorsColumns}
                    withTotalRows
                    virtualized
                    onRowClick={showRowErrors}
                /> :
                <LoadingBlock rounded />}

        </main>
    );
}

export default React.memo(UnresolvedIssuesPage);