import { useRef, useLayoutEffect, useState, memo, useMemo, createRef } from 'react';
import PageTitle from '../../components/PageTitle';
import 'rsuite-table/dist/css/rsuite-table.css';
import { useCreateRegionMutation, useDeleteRegionMutation, useGetRegionsQuery, useUpdateRegionMutation } from '../../services/RegionService';
import { IRegion, RegionProperties, RegionPropertiesNames } from '../../models/entities/IRegion';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import RsuiteTable, { ColumnsList } from '../../components/rsuite-table/RsuiteTable';
import { RowDataType } from 'rsuite-table';
import Form from '../../components/Form/Form';
import { timeZones } from '../../constants/timeZones';
import { fetchDataAlert, showLoadingSwal } from '../../utils/utils';
import { FormNames, FormTypes, getFormElems } from '../../constants/formElems';
import SwalTable from '../../components/SwalTable';
import { RSwal } from '../../constants/sweetAlert';
import LoadingBlock from '../../components/LoadingBlock';
import { Link } from 'react-router-dom';
import { defaultPollingInterval } from '../../store/globalVariables';


function RegionsPage() {
    const { refetch, data: regions, fulfilledTimeStamp, isFetching } = useGetRegionsQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const [createRegion] = useCreateRegionMutation();
    const [updateRegion] = useUpdateRegionMutation();
    const [deleteRegion] = useDeleteRegionMutation();

    const handleCreateRegion = async (row: IRegion) => {
        showLoadingSwal("Создание...");
        const result = await createRegion(row);
        fetchDataAlert({
            result: result,
            showSuccessMessage: true,
            successMessage: "data" in result && result.data.data ?
                SwalTable({
                    headers: [
                        {
                            key: RegionProperties.id,
                            name: RegionPropertiesNames.id,
                            width: 60,
                            minWidth: 60
                        },
                        {
                            key: RegionProperties.name,
                            name: RegionPropertiesNames.name,
                            flexGrow: 1
                        }
                    ],
                    rows: [{
                        [RegionProperties.id]: result.data.data[RegionProperties.id],
                        [RegionProperties.name]: result.data.data[RegionProperties.name]
                    }]
                })
                : undefined
        })
    }

    const handleUpdateRegion = async (row: IRegion) => {
        showLoadingSwal("Изменение...");
        const result = await updateRegion(row);
        fetchDataAlert({
            result: result,
            showSuccessMessage: true
        })
    }

    const handleDeleteRegion = async (id: number) => {
        showLoadingSwal("Удаление...");
        const result = await deleteRegion(id);
        fetchDataAlert({
            result: result,
            showSuccessMessage: true
        })
    }

    const columns: ColumnsList = useMemo(() => {
        const currentCols: ColumnsList = [
            {
                columnProps: {
                    width: 80,
                    fixed: "left"
                },
                headerProps: {
                    children: <div className="rs-table-crud-icon">
                        <MdAdd
                            title="Создать регион"
                            onClick={() =>
                                RSwal.fire({
                                    title: "Создание региона",
                                    html: <Form
                                        isSwalForm
                                        // Элементы формы модального окна
                                        elements={getFormElems({
                                            formName: FormNames.region,
                                            formType: FormTypes.create,
                                            options: {
                                                [RegionProperties.timezone]: timeZones
                                            }
                                        })}
                                        submitButton={{
                                            name: "Создать",
                                            handleClick: (data: IRegion) => handleCreateRegion(data)
                                        }}
                                        denyButton={{
                                            name: "Отменить",
                                            autoFocus: true,
                                            handleClick: () => RSwal.close()
                                        }}
                                    />,
                                    showConfirmButton: false,
                                    showCloseButton: true
                                })} />
                    </div>
                },
                cellProps: {
                    children: (row: RowDataType<IRegion> | IRegion) => <>
                        <div className="rs-table-crud-icon">
                            <MdEdit
                                title="Изменить регион"
                                onClick={() => {
                                    RSwal.fire({
                                        title: "Редактирование региона",
                                        html: <Form
                                            isSwalForm
                                            // Элементы формы модального окна
                                            elements={getFormElems({
                                                formName: FormNames.region,
                                                formType: FormTypes.update,
                                                options: {
                                                    [RegionProperties.timezone]: timeZones
                                                },
                                                row: {
                                                    id: row.id,
                                                    name: row.name,
                                                    timezone: row.timezone,
                                                    baseUrl: row.baseUrl,
                                                    managerUrl: row.managerUrl,
                                                    useAuth: row.useAuth,
                                                    userName: row.userName,
                                                    userPassword: row.userPassword
                                                }
                                            })}
                                            submitButton={{
                                                name: "Изменить",
                                                handleClick: (data: IRegion) => handleUpdateRegion(data)
                                            }}
                                            denyButton={{
                                                name: "Отменить",
                                                autoFocus: true,
                                                handleClick: () => RSwal.close()
                                            }}
                                        />,
                                        showConfirmButton: false,
                                        showCloseButton: true
                                    })
                                }} />
                        </div>
                        <div className="rs-table-crud-icon">
                            <MdDelete
                                title="Удалить регион"
                                onClick={() =>
                                    RSwal.fire({
                                        title: "Удаление региона",
                                        html: <Form
                                            isSwalForm
                                            // Элементы формы модального окна
                                            elements={getFormElems({
                                                formName: FormNames.region,
                                                formType: FormTypes.delete,
                                                options: {
                                                    [RegionProperties.timezone]: timeZones
                                                },
                                                row: {
                                                    id: row.id,
                                                    name: row.name,
                                                    timezone: row.timezone,
                                                    baseUrl: row.baseUrl,
                                                    managerUrl: row.managerUrl,
                                                    useAuth: row.useAuth,
                                                    userName: row.userName,
                                                    userPassword: row.userPassword
                                                }
                                            })}
                                            submitButton={{
                                                name: "Удалить",
                                                handleClick: (data: IRegion) => handleDeleteRegion(data.id)
                                            }}
                                            denyButton={{
                                                name: "Отменить",
                                                autoFocus: true,
                                                handleClick: () => RSwal.close()
                                            }}
                                        />,
                                        showConfirmButton: false,
                                        showCloseButton: true
                                    })} />
                        </div>
                    </>
                }
            },
            {
                columnProps: {
                    sortable: true,
                    fixed: true,
                    width: 60
                },
                headerProps: {
                    children: RegionPropertiesNames.id
                },
                cellProps: {
                    dataKey: RegionProperties.id
                }
            },
            {
                columnProps: {
                    sortable: true,
                    flexGrow: 1,
                    fullText: true
                },
                headerProps: {
                    children: RegionPropertiesNames.name
                },
                cellProps: {
                    dataKey: RegionProperties.name
                },
                searchable: true
            },
            {
                columnProps: {
                    sortable: true,
                    width: 100
                },
                headerProps: {
                    children: RegionPropertiesNames.timezone
                },
                cellProps: {
                    dataKey: RegionProperties.timezone
                },
                searchable: true
            },
            {
                columnProps: {
                    sortable: true,
                    flexGrow: 1,
                    fullText: true,

                },
                headerProps: {
                    children: RegionPropertiesNames.baseUrl
                },
                cellProps: {
                    dataKey: RegionProperties.baseUrl,
                    children: (rowData: RowDataType ) =>{
                        const row = rowData as IRegion;

                        return <Link
                            to={row.baseUrl}
                            target='_blank'
                            rel="noopener noreferrer"
                            className="p-2"
                        >
                            {row.baseUrl}
                        </Link>
                        }
                },
                searchable: true
            },
            {
                columnProps: {
                    sortable: true,
                    flexGrow: 1,
                    fullText: true
                },
                headerProps: {
                    children: RegionPropertiesNames.managerUrl
                },
                cellProps: {
                    dataKey: RegionProperties.managerUrl
                },
                searchable: true
            },
            {
                columnProps: {
                    sortable: true,
                    flexGrow: 1
                },
                headerProps: {
                    children: RegionPropertiesNames.useAuth
                },
                cellProps: {
                    dataKey: RegionProperties.useAuth,
                    children: (row: RowDataType) =>
                        <div className="p-2">
                            {(row as IRegion).useAuth ? "Да" : "Нет"}
                        </div>
                },
                searchable: true
            },
            {
                columnProps: {
                    sortable: true,
                    flexGrow: 1,
                    fullText: true
                },
                headerProps: {
                    children: RegionPropertiesNames.userName
                },
                cellProps: {
                    dataKey: RegionProperties.userName
                },
                searchable: true
            }
        ];

        return currentCols;
    }, []);

    return (
        <main>
            <PageTitle
                title='Регионы'
                reloadPage={() => refetch()}
                isLoading={isFetching}
                pageUpdateTime={fulfilledTimeStamp && !isFetching ? new Date(fulfilledTimeStamp).toLocaleString().replace(',', '') : ""}
            />

            {regions?.data ?
                <RsuiteTable
                    data={regions.data}
                    columns={columns}
                    withTotalRows
                    virtualized
                /> :
                <LoadingBlock rounded />}
        </main>
    )
}

export default memo(RegionsPage)