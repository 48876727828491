import { memo } from 'react'
import { useController, useFormContext } from "react-hook-form";
import { DropDownElem, DropDownElemValue } from '../FormElemTypes';
import Select from 'react-select';

const DropDown = memo(({ name, options, label, defaultValues, ...rest }: DropDownElem) => {
    const elemId = 'form_elem_' + name;
    const { control } = useFormContext();
  
    const { field } = useController({ name: name, control, defaultValue: defaultValues });
    const { value, onChange: valueOnChange, ...restLangField } = field;
    const currentValue = value as DropDownElem['defaultValues'];

    return (
        <div className="form-field">
            {label &&
                <label htmlFor={elemId} className='text-truncate'>
                    {label}
                </label>}

                <Select
                    className='drop-down-field text-start'
                    classNamePrefix='drop-down-field'
                    isClearable
                    options={options}
                    value={currentValue ? options.filter(x => currentValue.includes(x.value)) : currentValue}
                    onChange={(selectedOptions) => valueOnChange((selectedOptions as DropDownElem['options']).map(option=>option.value) as DropDownElemValue[])}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    {...rest}
                    {...restLangField}
                />
        </div>
    )
})

export default DropDown