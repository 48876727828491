import React, { memo } from 'react'
import RenderError from './RenderError';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import LoadingBlock from '../LoadingBlock';

type RenderDataProps = {
    component: JSX.Element,
    isSuccess: boolean,
    isLoading: boolean,
    error?: FetchBaseQueryError | SerializedError,
    endpointName?: string 
}

const RenderData = memo(({ component, isSuccess, error, isLoading, endpointName }: RenderDataProps) => {
    if(isSuccess) {
        return component;
    }

    if(error) {
        return <RenderError 
            statusCode={'originalStatus' in error ? error.originalStatus : undefined}
            statusMsg={'status' in error ? error.status : undefined}
            errorMsg={'error' in error ? error.error : undefined}
            endpointName={endpointName ?? undefined}
        />
    }

    if(isLoading) {
        return <LoadingBlock />
    }

    else {
        return component;
    }
})

export default RenderData