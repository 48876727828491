import { useEffect, RefObject } from "react";

export function useGrabScroll(ref: RefObject<HTMLElement>) {
    useEffect(() => {
        if (ref.current) {
            const elem = ref.current;
            let pos = { top: 0, left: 0, x: 0, y: 0 };

            elem.addEventListener('mousedown', (e)=>mouseDownHandler(e));

            const mouseMoveHandler = (e: MouseEvent) => {
                // How far the mouse has been moved
                const dx = e.clientX - pos.x;
                const dy = e.clientY - pos.y;

                // Scroll the element
                elem.scrollTop = pos.top - dy;
                elem.scrollLeft = pos.left - dx;
            };

            const mouseUpHandler = () => {
                document.removeEventListener('mousemove', mouseMoveHandler);
                document.removeEventListener('mouseup', mouseUpHandler);

                elem.style.removeProperty('user-select');
            };
            
            const mouseDownHandler = (e: MouseEvent) => {
                if (document.querySelector("html.is-touch")) return;

                if (window.innerWidth > 1200) return;

                elem.style.userSelect = 'none';

                pos = {
                    // The current scroll
                    left: elem.scrollLeft,
                    top: elem.scrollTop,
                    // Get the current mouse position
                    x: e.clientX,
                    y: e.clientY,
                };

                document.addEventListener('mousemove', mouseMoveHandler);
                document.addEventListener('mouseup', mouseUpHandler);
            };

            return elem?.removeEventListener('mousedown', (e)=>mouseDownHandler(e));
        }
    }, [ref]);
}