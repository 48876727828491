import React, { useState, useCallback } from 'react';
import PageTitle from '../../components/PageTitle';
import accountImg from '../../assets/images/account.png';
import './account.scss';
import Form from '../../components/Form/Form';
import { ElementVariant, FormElemTypes } from '../../components/Form/FormElemTypes';
import { MdCheck, MdClose, MdEdit, MdSettings } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { IUser } from '../../models/entities/IUser';
import { useUpdateUserMutation } from '../../services/UserService';
import { fetchDataAlert } from '../../utils/utils';
import { useAppSelector } from '../../hooks/redux';
import { logout } from '../../store'
import useDidMountEffect from '../../hooks/skipFirstUseEffectRun';
import FormButtonContent from '../../components/Form/FormButtonContent';
import { RSwal } from '../../constants/sweetAlert';

function AccountPage() {
    const [updateUser] = useUpdateUserMutation();

    const currentUser = useAppSelector(state => state.user.currentUserState);

    const [editingEnabled, setEditingEnabled] = useState<boolean>(false);

    const formElements: ElementVariant[] = [
        {
            type: FormElemTypes.text,
            name: "firstname",
            label: "Имя:",
            htmlAttributes: {
                autoFocus: true,
                placeholder: "Иван",
                required: true,
                defaultValue: currentUser?.firstName,
                readOnly: !editingEnabled
            }
        },
        {
            type: FormElemTypes.text,
            name: "lastname",
            label: "Фамилия:",
            htmlAttributes: {
                placeholder: "Иванов",
                required: true,
                defaultValue: currentUser?.lastName,
                readOnly: !editingEnabled
            }
        },
        {
            type: FormElemTypes.text,
            name: "username",
            label: "Логин:",
            htmlAttributes: {
                placeholder: "Логин",
                required: true,
                defaultValue: currentUser?.username,
                readOnly: !editingEnabled
            }
        }
    ];

    const [currentFormElements, setCurrentFormElements] = useState<ElementVariant[]>(formElements);

    const handleSetEditingEnabled = (enable: boolean) => {
        setEditingEnabled(enable);
    }

    useDidMountEffect(() => {
        if (editingEnabled) {
            const editingELems = formElements.concat([{
                type: FormElemTypes.password,
                name: "password",
                label: "Новый пароль:",
                htmlAttributes: {
                    placeholder: "Пароль",
                    autoComplete: 'new-password'
                }
            }]);
            setCurrentFormElements(editingELems);
        }
        else {
            setCurrentFormElements(formElements);
        }
    }, [editingEnabled])

    const editUser = useCallback((data: IUser) => {
        if (currentUser) {
            data.id = currentUser.id;
            updateUser(data).then(result => {
                fetchDataAlert({
                    result: result,
                    showSuccessMessage: true
                })
            })
        }
        else {
            RSwal.fire({
                icon: 'error',
                title: 'Что-то пошло не так'
            });
            logout();
        }
        handleSetEditingEnabled(false);
    }, [])

    return (
        <main>
            <PageTitle
                title='Мой профиль'
            />

            <div className="w-100 h-100">
                <img className="account-img" src={accountImg} />

                <div className="account-main-content">
                    <Form
                        elements={currentFormElements}
                        submitButton={editingEnabled ?
                            {
                                name: <FormButtonContent 
                                    name='Применить'
                                    icon={<MdCheck />}
                                />,
                                className: 'btn-success mt-3 me-2',
                                handleClick: (data: IUser) => editUser(data)
                            }
                            : {
                                name: <FormButtonContent 
                                    name='Редактировать'
                                    icon={<MdEdit />}
                                />,
                                className: 'btn-primary mt-3 me-2',
                                handleClick: () => handleSetEditingEnabled(true)
                            }}
                        otherButtons={editingEnabled ?
                            [{
                                name: <FormButtonContent 
                                name='Отменить'
                                icon={<MdClose />}
                            />,
                                className: 'btn-danger mt-3 d-inline-flex-center-center',
                                handleClick: () => handleSetEditingEnabled(false)
                            }]
                            : [{
                                name:
                                    <Link
                                        to={'/Settings'}
                                        className="form-button-content"
                                    >
                                        Настройки
                                        <MdSettings className="ms-1" />
                                    </Link>,
                                className: 'btn-primary mt-3'
                            }]}
                    />
                </div>
            </div>
        </main>
    );
}

export default React.memo(AccountPage);