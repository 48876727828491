import React, { createRef, useLayoutEffect, useMemo } from 'react';
import PageTitle from '../../components/PageTitle';
import { fetchDataAlert, fetchDataAlertProps, nameof, showLoadingSwal } from '../../utils/utils';
import { IRole } from '../../models/entities/IRole';
import { RSwal } from '../../constants/sweetAlert';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import Form from '../../components/Form/Form';
import RsuiteTable, { ColumnsList } from '../../components/rsuite-table/RsuiteTable';
import LoadingBlock from '../../components/LoadingBlock';
import { useCreateRoleMutation, useDeleteRoleMutation, useGetAllRolesQuery, useUpdateRoleMutation } from '../../services/RoleService';
import { useAppSelector } from '../../hooks/redux';
import {
    getCreateRoleElements,
    getCreateRoleSAElements,
    getDeleteRoleElements,
    getDeleteRoleSAElements,
    getUpdateRoleElements,
    getUpdateRoleSAElements
} from './RoleCRUDElements';
import { DropDownElem } from '../../components/Form/FormElemTypes';
import { useGetRegionsQuery } from '../../services/RegionService';
import SwalTable from '../../components/SwalTable';
import { IUser } from '../../models/entities/IUser';
import { RowDataType } from 'rsuite-table';
import { defaultPollingInterval } from '../../store/globalVariables';

function RolesPage() {
    const { refetch: getRoles, data: roles, isFetching, fulfilledTimeStamp } = useGetAllRolesQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const { refetch: getRegions, data: regions } = useGetRegionsQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const [createRoleFetch] = useCreateRoleMutation();
    const [updateRoleFetch] = useUpdateRoleMutation();
    const [deleteRoleFetch] = useDeleteRoleMutation();

    const currentUser = useAppSelector(state => state.user.currentUserState);

    const createRole = (data: IRole) => {
        showLoadingSwal("Создание...");
        createRoleFetch(data).then(result => {
            fetchDataAlert({
                result: result,
                showSuccessMessage: true
            })
        })
    }

    const updateRole = (data: IRole) => {
        showLoadingSwal("Изменение...");
        updateRoleFetch(data).then(result => {
            fetchDataAlert({
                result: result,
                showSuccessMessage: true
            })
        })
    }

    const deleteRole = (id: number) => {
        showLoadingSwal("Удаление...");

        deleteRoleFetch(id).then(result => {
            let otherInfo: fetchDataAlertProps['otherInfo'] = "";
            if("data" in result && "data" in result.data && Array.isArray(result.data.data.relatedRoles)) {
                otherInfo = SwalTable({
                    headers: [
                        {
                            key: 'id',
                            name: 'id',
                            width: 60,
                            minWidth: 60
                        },
                        {
                            key: nameof<IUser>('firstName'),
                            name: "Имя",
                            flexGrow: 1
                        },
                        {
                            key: nameof<IUser>('lastName'),
                            name: "Фамилия",
                            flexGrow: 1
                        },
                        {
                            key: nameof<IUser>('username'),
                            name: "Логин",
                            flexGrow: 1
                        },
                        {
                            key: nameof<IUser>('role'),
                            name: "Роль",
                            flexGrow: 1
                        }
                    ],
                    rows: result.data.data.relatedRoles.map(user=>({
                        ['id']: user.id,
                        [nameof<IUser>('firstName')]: user.firstName,
                        [nameof<IUser>('lastName')]: user.lastName,
                        [nameof<IUser>('username')]: user.username,
                        [nameof<IUser>('role')]: user.role.name
                    }))
                })
            }

            fetchDataAlert({
                result: result,
                showSuccessMessage: true,
                otherInfo: otherInfo
            })
        })
    }

    const columns: ColumnsList = useMemo(() => {
        if (regions?.data && Array.isArray(regions.data)) {
            const regionsOptions: DropDownElem['options'] = regions.data.map(region => ({
                label: region.name,
                value: String(region.id)
            }));

            return [
                {
                    columnProps: {
                        width: 80,
                        fixed: "left"
                    },
                    headerProps: {
                        children: <div className="rs-table-crud-icon">
                            <MdAdd
                                title="Добавить роль"
                                onClick={() =>
                                    RSwal.fire({
                                        title: "Добавление роли",
                                        html: <Form
                                            isSwalForm
                                            // Элементы формы модального окна
                                            elements={currentUser?.role?.isSuperAdmin ?
                                                getCreateRoleSAElements(regionsOptions) :
                                                getCreateRoleElements(regionsOptions)}
                                            submitButton={{
                                                name: "Добавить",
                                                handleClick: (data: IRole) => createRole(data)
                                            }}
                                            denyButton={{
                                                name: "Отменить",
                                                autoFocus: true,
                                                handleClick: () => RSwal.close()
                                            }}
                                        />,
                                        showConfirmButton: false,
                                        showCloseButton: true
                                    })} />
                        </div>
                    },
                    cellProps: {
                        children: (row: IRole | RowDataType) => <>
                            <div className="rs-table-crud-icon">
                                <MdEdit
                                    title="Изменить роль"
                                    onClick={() => {
                                        RSwal.fire({
                                            title: "Редактирование роли",
                                            html: <Form
                                                isSwalForm
                                                // Элементы формы модального окна
                                                elements={currentUser?.role?.isSuperAdmin ?
                                                    getUpdateRoleSAElements(row, regionsOptions) :
                                                    getUpdateRoleElements(row, regionsOptions)}
                                                submitButton={{
                                                    name: "Изменить",
                                                    handleClick: (data: IRole) => updateRole(data)
                                                }}
                                                denyButton={{
                                                    name: "Отменить",
                                                    autoFocus: true,
                                                    handleClick: () => RSwal.close()
                                                }}
                                            />,
                                            showConfirmButton: false,
                                            showCloseButton: true
                                        })
                                    }} />
                            </div>
                            <div className="rs-table-crud-icon">
                                <MdDelete
                                    title="Удалить роль"
                                    onClick={() =>
                                        RSwal.fire({
                                            title: "Удаление роли",
                                            html: <Form
                                                isSwalForm
                                                // Элементы формы модального окна
                                                elements={currentUser?.role?.isSuperAdmin ?
                                                    getDeleteRoleSAElements(row, regionsOptions) :
                                                    getDeleteRoleElements(row, regionsOptions)}
                                                submitButton={{
                                                    name: "Удалить",
                                                    handleClick: (data: IRole) => deleteRole(data.id)
                                                }}
                                                denyButton={{
                                                    name: "Отменить",
                                                    autoFocus: true,
                                                    handleClick: () => RSwal.close()
                                                }}
                                            />,
                                            showConfirmButton: false,
                                            showCloseButton: true
                                        })} />
                            </div>
                        </>
                    }
                },
                {
                    columnProps: {
                        sortable: true,
                        fixed: true,
                        width: 60
                    },
                    headerProps: {
                        children: "id"
                    },
                    cellProps: {
                        dataKey: nameof<IRole>('id')
                    }
                },
                {
                    columnProps: {
                        sortable: true,
                        flexGrow: 1,
                        fullText: true
                    },
                    headerProps: {
                        children: "Имя"
                    },
                    cellProps: {
                        dataKey: nameof<IRole>('name')
                    },
                    searchable: true
                },
                {
                    columnProps: {
                        sortable: true,
                        flexGrow: 1,
                        fullText: true
                    },
                    headerProps: {
                        children: "Регионы"
                    },
                    cellProps: {
                        dataKey: nameof<IRole>('countryRoles'),
                        children: (row: IRole | RowDataType) => {
                            if (!row.isAdmin && !row.isSuperAdmin && row.countryRoles && Array.isArray(row.countryRoles)) {
                                if (row.countryRoles.length > 0) {
                                    if (row.countryRoles.length === 1 && typeof row.countryRoles[0].region === 'string') {
                                        return row.countryRoles[0].region;
                                    }
                                    else {
                                        return <select className='w-100 mx-2'>
                                            <option className='d-none'>
                                                Регионы
                                            </option>
                                            {row.countryRoles.map((item, index) =>
                                                <option
                                                    key={'countryRole_' + index}
                                                    disabled
                                                >
                                                    {typeof (item.region) === 'string' && item.region}
                                                </option>)}
                                        </select>
                                    }
                                }
                            }
                            else {
                                if (row.isAdmin || row.isSuperAdmin) {
                                    return "Все";
                                }
                                else {
                                    return "Неизвестно";
                                }
                            }
                        }
                    },
                    searchable: true
                },
                {
                    columnProps: {
                        sortable: true,
                        flexGrow: 1,
                        fullText: true
                    },
                    headerProps: {
                        children: "Админ"
                    },
                    cellProps: {
                        dataKey: nameof<IRole>('isAdmin'),
                        children: (row: IRole | RowDataType) => row.isAdmin ? "Да" : "Нет"
                    },
                    searchable: true
                },
                {
                    columnProps: {
                        sortable: true,
                        flexGrow: 1,
                        fullText: true
                    },
                    headerProps: {
                        children: "Супер-Админ"
                    },
                    cellProps: {
                        dataKey: nameof<IRole>('isSuperAdmin'),
                        children: (row: IRole | RowDataType) => 
                            row.isSuperAdmin === true ? 
                            "Да" : 
                            (row.isSuperAdmin === false ? "Нет" : "Неизвестно")
                    },
                    searchable: true
                }
            ];
        }
        else return []
    }, [regions, currentUser]);

    return (
        <main>
            <PageTitle
                title='Роли'
                reloadPage={() => {
                    getRoles();
                    getRegions();
                }}
                isLoading={isFetching}
                pageUpdateTime={!isFetching && fulfilledTimeStamp ? 
                    new Date(fulfilledTimeStamp).toLocaleString().replace(',', "") : 
                    ""}
            />

            {roles?.data ?
                <RsuiteTable
                    data={roles.data}
                    columns={columns}
                    withTotalRows={true}
                /> :
                <LoadingBlock rounded />}

        </main>
    );
}

export default React.memo(RolesPage);