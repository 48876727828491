import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {defaultBaseQueryParams} from "../constants/defaultBaseQueryParams";
import { IDefaultDataResponse } from "../models/responses/IDefaultResponse";

export const appApi = createApi({
    reducerPath: 'appApi',
    baseQuery: fetchBaseQuery(defaultBaseQueryParams),
    endpoints: build => ({
        getFileVersion: build.query<IDefaultDataResponse<string>, void>({
            query: ()  => ({
                url: '/PageData/GetFileVersion'
            })
        }),
    })
})

export const {
    useLazyGetFileVersionQuery
} = appApi;