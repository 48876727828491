import { memo } from 'react'
import { Sort } from './FiltersMenu'
import { FaLongArrowAltUp } from "react-icons/fa";


export type SortMenuItemProps = {
    name: string,
    value?: string,
    sortValue: Sort | undefined,
    handleSelectSort: (value: string) => void
}

const SortMenuItem = memo(({ name, value, sortValue, handleSelectSort }: SortMenuItemProps) => {
    return (
        <div
            className='filters-menu-sort'
            onClick={(e) => handleSelectSort(value ? value : name)}
            aria-sort={sortValue?.value === value ? sortValue?.type : undefined}
        >
            {name}
            <FaLongArrowAltUp  className="sort-arrow" />
        </div>
    )
})

export default SortMenuItem