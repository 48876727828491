import React, { useRef } from 'react'
import { MdPerson } from 'react-icons/md'
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/redux';
import { logout } from '../../../store';
import ClickOutside from '../../ClickOutside';
import { history } from '../../../utils/utils';

function AccountMenuToggler() {
    const accountMenuRef = useRef<HTMLDivElement>(null);

    function closeAccountMenu() {
        accountMenuRef.current?.classList.remove("show");
    }

    function toggleAccountMenu() {
        accountMenuRef.current?.classList.toggle("show");
    }

    const currentUser = useAppSelector(state=>state.user.currentUserState);

    return (
        <ClickOutside 
            className="navigation-head-button" 
            handleClickOutside={()=>closeAccountMenu()}
        >
            <div
                className="account-icon transparent-hover"
                onClick={() => toggleAccountMenu()}
                title={currentUser?.username}
                style={history.location && ['/settings', '/account'].includes(history.location.pathname.toLowerCase()) ?
                    {borderRadius: "50%", background: 'white', color: 'var(--dark)'}
                    : {}}
            >
                <MdPerson className='pointer-events-none' />
            </div>

            <div
                className={"account-menu"}
                ref={accountMenuRef}
            >
                <div className="account-menu-header text-truncate">
                    {currentUser?.username}
                </div>
                <div className="account-menu-icon">
                    <MdPerson />
                </div>
                <div className="account-menu-main row m-0 justify-content-center">
                    <Link
                        className="account-menu-link col-7 mb-1"
                        title="Вы"
                        to="/Account"
                        onClick={() => {
                            closeAccountMenu();
                        }}
                    >
                        Мой профиль
                    </Link>
                    <Link
                        className="account-menu-link col-7"
                        title="Настройки профиля"
                        to="/Settings"
                        onClick={() => {
                            closeAccountMenu();
                        }}
                    >
                        Настройки
                    </Link>
                    <div className='small col-12'>
                        {currentUser? "Вход выполнен" : "Вход не выполнен"}
                        </div> 
                </div>
                <div
                    className="account-menu-footer"
                    title="Выйти из аккаунта"
                    onClick={() => logout()}
                >
                    Выход
                </div>
            </div>
        </ClickOutside>
    )
}

export default React.memo(AccountMenuToggler)