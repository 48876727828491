import { memo, RefObject } from "react";
import { selectColor } from "../../../store/globalVariables";

export type CustomLegendProps = {
    data: {
        name: string,
        value?: number
    }[],
    deletedKeys: string[],
    itemTitle?: string,
    layout?: 'horizontal' | 'vertical',
    handleLegendClick: (dataKey: string) => void,
    handleLegendRightClick?: (dataKey: string) => void,
    handleLegendMouseEnter?: (index: number) => void,
    handleLegendMouseLeave?: (index: number) => void,
    innerRef?: RefObject<HTMLDivElement>
};

export const CustomLegend = memo(({
    data,
    deletedKeys,
    itemTitle,
    layout,
    handleLegendClick,
    handleLegendRightClick,
    handleLegendMouseEnter,
    handleLegendMouseLeave,
    innerRef
}: CustomLegendProps) => {
    return (
        <div 
            className={`custom-legend ${layout ?? ''}`}
            ref={innerRef}
        >
            {data?.map((item, index) => {
                const itemName = item.name;

                const color = selectColor(index);
                const keyIsDisabled = itemName ? deletedKeys.includes(itemName) : false;

                return (
                    <div
                        key={'legendItem_' + index}
                        className={
                            "legend-item " +
                            (keyIsDisabled ? 'disabled' : '')
                        }
                        title={itemTitle ?? (deletedKeys ? 'LC: удалить\nRC: оставить' : undefined)}
                        onClick={() => handleLegendClick(itemName ?? "")}
                        onMouseEnter={() => {
                            if(handleLegendMouseEnter) handleLegendMouseEnter(index)
                        }}
                        onMouseLeave={() => {
                            if(handleLegendMouseLeave) handleLegendMouseLeave(index)
                        }}
                        onContextMenu={(e) => {
                            if (itemName && handleLegendRightClick) {
                                e.preventDefault();
                                e.stopPropagation();
                                handleLegendRightClick(itemName);
                            }
                        }}
                    >
                        <div className="legend-item-marker" style={{ backgroundColor: color }} />
                        <div className="legend-item-name">
                            {itemName + (item.value ? ` (${item.value})` : "")}
                        </div>
                    </div>
                );
            })}
        </div>
    );
});