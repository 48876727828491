import { MutableRefObject, RefObject, useMemo, memo, useCallback, useRef, useState, useLayoutEffect } from 'react'
import { IGetFilters } from '../../../models/responses/IGetFilters'
import FiltersMenu, { FiltersMenuProps } from '../../../components/filters-menu/FiltersMenu'
import FiltersMenuItem from '../../../components/filters-menu/FiltersMenuItem';
import { MonitoringPanelLocState } from '../MonitoringPanel/MonitoringPanel';
import { history } from '../../../utils/utils';

export type TFilters = {
    regions: number[],
    complexTypes: number[],
    errorLevels: number[]
}

export type ErrorFilterMenuProps = {
    filters: FiltersMenuProps['listFilters'],
    filtersMenuTogglerRef: RefObject<HTMLDivElement>,
    filtersMenuRef: RefObject<HTMLDivElement>,
    regionsRef: MutableRefObject<number[]>,
    complexTypesRef: MutableRefObject<number[]>,
    errorLevelsRef: MutableRefObject<number[]>,
    startDateRef: MutableRefObject<Date | null>,
    endDateRef: MutableRefObject<Date | null>,
    submitButton: {
        name: string,
        handle: () => void
    },
    cancelButton: {
        name: string,
        handle: () => void
    }
}

const ErrorFilterMenu = memo(({
    filters,
    filtersMenuTogglerRef,
    filtersMenuRef,
    regionsRef,
    complexTypesRef,
    errorLevelsRef,
    startDateRef,
    endDateRef,
    submitButton,
    cancelButton
}: ErrorFilterMenuProps) => {
    const [startDate, setStartDate] = useState<Date | null>(startDateRef.current);
    const [endDate, setEndDate] = useState<Date | null>(endDateRef.current);

    const submit = useRef(submitButton.handle);
    const cancel = useRef(cancelButton.handle);

    const handleSubmit = () => submit.current();
    const handleCancel = () => cancel.current();

    const [currentFilters, setCurrentFilters] = useState(filters);

    useLayoutEffect(()=>{
        let locState: MonitoringPanelLocState | null = history.location?.state;

        if (locState) {
            if (locState.regions && Array.isArray(locState.regions) && locState.regions.length > 0) {
                setCurrentFilters(prev =>  {
                    if(!prev) return;
                    
                    const nextFilters = {...prev};
                    nextFilters.regions.selectedItems = locState?.regions ? locState.regions : [];
                    return nextFilters;
                })
            }

            if (Array.isArray(locState.complexTypes) && locState.complexTypes.length > 0) {
                setCurrentFilters(prev =>  {
                    if(!prev) return;

                    const nextFilters = {...prev};
                    nextFilters.complexTypes.selectedItems = locState?.complexTypes ? locState.complexTypes : [];
                    return nextFilters;
                })
            }

            if (Array.isArray(locState.errorLevels) && locState.errorLevels.length > 0) {
                setCurrentFilters(prev =>  {
                    if(!prev) return;
                    
                    const nextFilters = {...prev};
                    nextFilters.errorLevels.selectedItems = locState?.errorLevels ? locState.errorLevels : [];
                    return nextFilters;
                })
            }

            if (locState.startDateTypeDate instanceof Date) {
                setStartDate(locState.startDateTypeDate);
            }

            if (locState.endDateTypeDate instanceof Date) {
                setEndDate(locState.endDateTypeDate);
            }

            if(history.navigate && history.location) history.navigate(history.location.pathname, {})
        }
    }, [])

    const cancelFilters = useCallback(() => {
        if(filtersMenuRef.current) filtersMenuRef.current.classList.remove('show');
        regionsRef.current = [];
        complexTypesRef.current = [];
        errorLevelsRef.current = [];
        startDateRef.current = null;
        endDateRef.current = null;
        setStartDate(null);
        setEndDate(null);
        handleCancel();
    }, []);

    const onChangeStartDate = useCallback((value: Date | null) => {
        setStartDate(value);
        startDateRef.current = value;
    }, []);

    const onChangeEndDate = useCallback((value: Date | null) => {
        setEndDate(value);
        endDateRef.current = value;
    }, []);

    return (
        <FiltersMenu
            filtersMenuRef={filtersMenuRef}
            filtersMenuTogglerRef={filtersMenuTogglerRef}
            listFilters={currentFilters}
            customFilters={[
                <FiltersMenuItem
                    key='periodMenuFilter'
                    filter={{
                        type: 'datePicker',
                        content: {
                            name: 'Период отбора',
                            setStartDate: onChangeStartDate,
                            setEndDate: onChangeEndDate,
                            startDate: startDate,
                            endDate: endDate,
                            positionStartDate: 'auto',
                            positionEndDate: 'auto'
                        }
                    }}
                />
            ]}
            submitButton={{
                name: "Применить",
                handle: handleSubmit
            }}
            cancelButton={{
                name: "Отменить",
                handle: cancelFilters
            }}
        />
    )
})

export default ErrorFilterMenu