import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {defaultBaseQueryParams} from "../constants/defaultBaseQueryParams";
import IErrorCountByComplex from '../models/responses/IErrorCountByComplex';
import { IDefaultDataResponse } from '../models/responses/IDefaultResponse';
import { TGraphsData } from '../models/responses/TGraphsData';
import { IGetErrorsByComplex } from '../models/responses/IGetErrorsByComplex';
import { IGetClientsStatistic } from '../models/responses/ClientsStatistic';
import { IErrorsFilters } from '../models/requests/IErrorsFilters';

export const statisticApi = createApi({
    reducerPath: 'statisticApi',
    baseQuery: fetchBaseQuery(defaultBaseQueryParams),
    tagTypes: ['ClientsStatistic'],
    // refetchOnFocus: true,
    refetchOnReconnect: true,
    endpoints: build => ({
        getErrorsCount: build.query<IDefaultDataResponse<number>, number | undefined>({
            query: (hours)  => ({
                url: `/PageData/GetErrorsCount${hours ? `?hours=${hours}` : ''}`
            })
        }),
        getStatisticComplexes: build.query<IDefaultDataResponse<IErrorCountByComplex[]>, void>({
            query: () => ({
                url: '/PageData/GetComplexes'
            })
        }),
        getErrorsByComplex: build.query<IDefaultDataResponse<IGetErrorsByComplex>, number | void>({
            query: (errorType)  => ({
                url: `/PageData/GetErrorsByComplex${errorType ? `?errorType=${errorType}` : ''}`
            })
        }),
        getFiringAlerts: build.query<IDefaultDataResponse<number>, void>({
            query: () => ({
                url: '/Prometheus/GetFiringAlerts'
            })
        }),
        getGraphsDataByComplexes: build.query<IDefaultDataResponse<TGraphsData>, void>({
            query: () => ({
                url: '/PageData/GetGraphsDataByComplexes'
            })
        }),
        getGraphsDataByRegions: build.query<IDefaultDataResponse<TGraphsData>, void>({
            query: () => ({
                url: '/PageData/GetGraphsDataByRegions'
            })
        }),
        getClientsStatistic: build.query<IDefaultDataResponse<IGetClientsStatistic>, IErrorsFilters>({
            query: (filters)  => ({
                url: '/List/GetClientsStatistic',
                method: "POST",
                body: filters
            }),
            providesTags: ['ClientsStatistic']
        }),
    })
})

export const {
    useGetErrorsCountQuery,
    useGetStatisticComplexesQuery,
    useGetErrorsByComplexQuery,
    useGetFiringAlertsQuery,
    useLazyGetGraphsDataByComplexesQuery,
    useLazyGetGraphsDataByRegionsQuery,
    useLazyGetClientsStatisticQuery
} = statisticApi;