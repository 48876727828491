import { useRef, useEffect } from 'react';

type ClickOutsideProps = {
    children: React.ReactNode,
    handleClickOutside: (e:Event)=>void,
    className?: string
}

export default function ClickOutside({ children, handleClickOutside, className }:ClickOutsideProps) {
    const wrapperRef = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
        const listener = (event: Event) => {
            const el = wrapperRef.current;
            if (!el || el.contains((event?.target as Node) || null)) {
                return;
            }

            handleClickOutside(event);
        };

        document.addEventListener("mousedown", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
        };
    }, [wrapperRef]);

    return (
        <div ref={wrapperRef} className={`${className ?? ''}`}>
            {children}
        </div>
    );
};