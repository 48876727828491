import { memo, useCallback, useMemo, useRef, useState, useEffect } from 'react'
import FiltersHeader from '../../components/filters-header-line/FiltersHeader'
import { MdError, MdFilterAlt, MdFlag, MdSort } from 'react-icons/md'
import FiltersMenu, { FiltersMenuProps, Sort } from '../../components/filters-menu/FiltersMenu'
import FiltersMenuItem from '../../components/filters-menu/FiltersMenuItem'
import LoadingBlock from '../../components/LoadingBlock'
import { useGetPrometheusRulesQuery, useGetTargetsQuery } from '../../services/ServerLoadService'
import IPrometheusAlert from '../../models/entities/IPrometheusAlert'
import { GetAlertsHistoryRequest } from '../../models/requests/IGetAlertsHistoryRequest'
import { Link, useParams } from 'react-router-dom'
import RsuiteTable, { ColumnsList } from '../../components/rsuite-table/RsuiteTable'
import { RowDataType, SortType } from 'rsuite-table'
import { IAlert } from '../../models/entities/IAlert'
import useDidMountEffect from '../../hooks/skipFirstUseEffectRun'
import { RSwal } from '../../constants/sweetAlert'
import { history, nameof } from '../../utils/utils'
import { renderToString } from 'react-dom/server';
import { BsFire } from "react-icons/bs";

type AlertsHistoryProps = {
    getAlertsHistory: (filters?: GetAlertsHistoryRequest) => void,
    alerts: IPrometheusAlert[] | undefined,
    alertsIsLoading: boolean
}

const AlertsHistory = memo(({ getAlertsHistory, alerts, alertsIsLoading }: AlertsHistoryProps) => {
    const historySortRef = useRef<HTMLDivElement>(null);
    const historySortMenuRef = useRef<HTMLDivElement>(null);

    const historyFilterRef = useRef<HTMLDivElement>(null);
    const historyFilterMenuRef = useRef<HTMLDivElement>(null);

    const [currentSort, setCurrentSort] = useState();

    const [occurrenceStartDate, setOccurrenceStartDate] = useState<Date | null>(null);
    const occurrenceStartDateRef = useRef<Date | null>(null);

    const [occurrenceEndDate, setOccurrenceEndDate] = useState<Date | null>(null);
    const occurrenceEndDateRef = useRef<Date | null>(null);

    const [finishStartDate, setFinishStartDate] = useState<Date | null>(null);
    const finishStartDateRef = useRef<Date | null>(null);

    const [finishEndDate, setFinishEndDate] = useState<Date | null>(null);
    const finishEndDateRef = useRef<Date | null>(null);

    const { data: rules } = useGetPrometheusRulesQuery();
    const { data: targets } = useGetTargetsQuery();

    const instancesRef = useRef<string[]>([]);
    const rulesRef = useRef<string[]>([]);
    
    const shouldUpdateScrollRef = useRef<boolean>(false);

    const applyFilters = useCallback((lastId: number = 0, shouldUpdateScroll: boolean = true) => {
        shouldUpdateScrollRef.current = shouldUpdateScroll;
        
        getAlertsHistory({
            names: rulesRef.current,
            instances: instancesRef.current,
            startPeriod: {
                startsAt: occurrenceStartDateRef.current ? occurrenceStartDateRef.current.toLocaleString().replace(',', '') : null,
                endsAt: occurrenceEndDateRef.current ? occurrenceEndDateRef.current.toLocaleString().replace(',', '') : null
            },
            endPeriod: {
                startsAt: finishStartDateRef.current ? finishStartDateRef.current.toLocaleString().replace(',', '') : null,
                endsAt: finishEndDateRef.current ? finishEndDateRef.current.toLocaleString().replace(',', '') : null
            },
            lastId: lastId
        });

        if(historyFilterRef?.current) {
            if(
                rulesRef.current?.length ||
                instancesRef.current?.length ||
                occurrenceStartDateRef.current ||
                occurrenceEndDateRef.current ||
                finishStartDateRef.current ||
                finishEndDateRef.current 
            ) {
                historyFilterRef.current.classList.add('active');
            }
            else {
                historyFilterRef.current.classList.remove('active');
            }
        }
    }, [])

    const handleSetOccurrenceStartDate = (value: Date | null) => {
        setOccurrenceStartDate(value);
        occurrenceStartDateRef.current = value;
    }

    const handleSetOccurrenceEndDate = (value: Date | null) => {
        setOccurrenceEndDate(value);
        occurrenceEndDateRef.current = value;
    }

    const handleSetFinishStartDate = (value: Date | null) => {
        setFinishStartDate(value);
        finishStartDateRef.current = value;
    }

    const handleSetFinishEndDate = (value: Date | null) => {
        setFinishEndDate(value);
        finishEndDateRef.current = value;
    }

    const cancelFilters = useCallback(() => {
        rulesRef.current = [];
        instancesRef.current = [];
        setOccurrenceStartDate(null);
        occurrenceStartDateRef.current = null;
        setOccurrenceEndDate(null);
        occurrenceEndDateRef.current = null;
        setFinishStartDate(null);
        finishStartDateRef.current = null;
        setFinishEndDate(null);
        finishEndDateRef.current = null;
        getAlertsHistory();
        
        if(historyFilterRef.current) historyFilterRef.current.classList.remove('active');
    }, [])

    const columns: ColumnsList = useMemo(() => [
        {
            columnProps: {
                minWidth: 90,
                width: 90
            },
            headerProps: {
                children: ''
            },
            cellProps: {
                dataKey: '',
                children: (alertData: RowDataType) => {
                    const alert = alertData as IAlert;
                    const alertTime = alert.startsAt.split('T');

                    return <Link
                        to={`/ServerLoad/${alert.id}`}
                        className="w-100 h-100 text-danger d-flex-center-center gap-1 flex-column"
                    >
                        {alert.status === 'firing' ?
                            <BsFire
                                className='text-warning fs-1-25'
                                title='Активная проблема'
                            /> :
                            <MdError className='fs-1-25' />}
                        <div className='fs-07 text-center'>
                            {alertTime[0]}
                            <br />
                            {alertTime[1].split('.')[0]}
                        </div>
                    </Link>
                }
            }
        },
        {
            columnProps: {
                flexGrow: 1
            },
            headerProps: {
                children: ''
            },
            cellProps: {
                dataKey: '',
                children: (alertData: RowDataType) => {
                    const alert = alertData as IAlert;

                    return <Link
                        to={`/ServerLoad/${alert.id}`}
                        className="w-100 h-100 d-flex-unset-center flex-column p-2 text-body"
                    >
                        <div className="">
                            <div>
                                {alert.name}
                            </div>

                            <div className='d-flex gap-2'>
                                {alert.service && <span className='bg-secondary px-1 rounded fs-09 text-light'>
                                    {alert.service}
                                </span>}

                                {alert.instance && <span className='bg-secondary px-1 rounded fs-09 text-light'>
                                    {alert.instance}
                                </span>}
                            </div>

                            {alert.endsAt &&
                                <div>
                                    <MdFlag className='text-success me-1 align-text-top' />
                                    {alert.endsAt.replace('T', ' ')}
                                </div>}
                        </div>
                    </Link>
                }
            }
        }
    ], []);

    const selectedAlertId = useParams<{ id?: string }>().id;

    const alertsRef = useRef(alerts);

    useEffect(() => {
        alertsRef.current = alerts
    }, [alerts])

    useEffect(() => {
        if (selectedAlertId && alertsRef.current) {
            const selectedAlert = alertsRef.current.find(alert => alert.id === Number(selectedAlertId));

            if (selectedAlert) {
                const selectedAlertIcon = selectedAlert.status === 'firing' ?
                    <BsFire
                        className='me-1 text-warning'
                        title='Активная проблема'
                    /> :
                    <MdError className='me-1 text-danger' />;

                RSwal.fire({
                    showCloseButton: true,
                    title: renderToString(<div className='d-flex-center-unset'>
                        {selectedAlertIcon}
                        {selectedAlert.name}
                    </div>),
                    customClass: {
                        htmlContainer: 'text-start'
                    },
                    html: <table className='mx-auto'>
                        <tbody>
                            {selectedAlert.service &&
                                <tr>
                                    <th>Регион:</th>
                                    <td className='ps-2'>{selectedAlert.service}</td>
                                </tr>}

                            <tr>
                                <th>Сервер:</th>
                                <td className='ps-2'>{selectedAlert.instance}</td>
                            </tr>

                            <tr>
                                <th>Начало:</th>
                                <td className='ps-2'>
                                    {selectedAlert.startsAt.replace('T', ' ')}
                                </td>
                            </tr>

                            {selectedAlert.endsAt &&
                                <tr>
                                    <th>Конец:</th>
                                    <td className='ps-2'>
                                        {selectedAlert.endsAt.replace('T', ' ')}
                                    </td>
                                </tr>}
                        </tbody>
                    </table>,
                    willClose: () => history.navigate ? history.navigate('/ServerLoad') : null
                })
            }
            else {
                RSwal.fire({
                    icon: 'error',
                    title: "Такой тревоги не найдено"
                })
            }
        }
        else {
            RSwal.close()
        }
    }, [selectedAlertId, alerts])

    const sortValueRef = useRef<Sort>();

    const setSortValueRef = useRef<(sortColumn: string | undefined, sortType: SortType | undefined)=>void>();

    const onScrollEnd = useCallback(() => {
        if (
            !alertsIsLoading &&
            alertsRef?.current?.length &&
            alertsRef?.current[alertsRef?.current.length - 1].id !== 1
        ) {
            applyFilters(alertsRef.current[alertsRef.current.length - 1].id, false);
        }
    }, [alertsIsLoading]);

    return (
        <div className="server-load-table col-12 col-md-6 col-xl-5 px-0 h-100">
            <FiltersHeader
                title='История'
                items={[
                    {
                        btn: {
                            content: alerts && !alertsIsLoading ?
                                <MdSort /> :
                                <div className='d-flex-center-center disabled'>
                                    <span className="spinner-border spinner-border-sm pointer-events-none" />
                                </div>,
                            innerRef: historySortRef
                        },
                        menu: {
                            content:
                                <FiltersMenu
                                    filtersMenuTogglerRef={historySortRef}
                                    filtersMenuRef={historySortMenuRef}
                                    listSorts={[
                                        {
                                            name: "По дате начала",
                                            value: nameof<IAlert>('startsAt')
                                        },
                                        {
                                            name: "По дате конца",
                                            value: nameof<IAlert>('endsAt')
                                        },
                                        {
                                            name: "По статусу",
                                            value: nameof<IAlert>('status')
                                        }
                                    ]}
                                    sortValueRef={sortValueRef}
                                    setSortValueRef={setSortValueRef}
                                />,
                            innerRef: historySortMenuRef
                        }
                    },
                    {
                        btn: {
                            content: !false ?
                                <MdFilterAlt /> :
                                <span className="spinner-border spinner-border-sm pointer-events-none" />,
                            innerRef: historyFilterRef
                        },
                        menu: {
                            content:
                                <FiltersMenu
                                    filtersMenuTogglerRef={historyFilterRef}
                                    filtersMenuRef={historyFilterMenuRef}
                                    listFilters={{
                                        regions: {
                                            name: "Регионы",
                                            items: targets?.data.map(target => ({
                                                name: target.labels.service,
                                                value: target.labels.instance
                                            })),
                                            selectedItems: [],
                                            ref: instancesRef
                                        },
                                        rules: {
                                            name: "Триггеры",
                                            items: rules?.data.map(rule => ({
                                                name: rule.name
                                            })),
                                            selectedItems: [],
                                            ref: rulesRef
                                        }
                                    } as FiltersMenuProps['listFilters']}
                                    customFilters={[
                                        <FiltersMenuItem
                                            key='occurrenceMenuFilter'
                                            filter={{
                                                type: 'datePicker',
                                                content: {
                                                    name: 'Период возникновения',
                                                    setStartDate: handleSetOccurrenceStartDate,
                                                    setEndDate: handleSetOccurrenceEndDate,
                                                    startDate: occurrenceStartDate,
                                                    endDate: occurrenceEndDate,
                                                    positionStartDate: 'auto',
                                                    positionEndDate: 'auto'
                                                }
                                            }}
                                        />,
                                        <FiltersMenuItem
                                            key='finishMenuFilter'
                                            filter={{
                                                type: 'datePicker',
                                                content: {
                                                    name: 'Период завершения',
                                                    setStartDate: handleSetFinishStartDate,
                                                    setEndDate: handleSetFinishEndDate,
                                                    startDate: finishStartDate,
                                                    endDate: finishEndDate,
                                                    positionStartDate: 'auto',
                                                    positionEndDate: 'auto'
                                                }
                                            }}
                                        />
                                    ]}
                                    submitButton={{
                                        name: "Применить",
                                        handle: applyFilters
                                    }}
                                    cancelButton={{
                                        name: "Отменить",
                                        handle: cancelFilters
                                    }}
                                />,
                            innerRef: historyFilterMenuRef
                        }
                    }
                ]}
            />

            {!alertsIsLoading ?
                <RsuiteTable
                    data={alerts}
                    columns={columns}
                    virtualized
                    showHeader={false}
                    rowHeight={90}
                    bordered={false}
                    rowClassName="alert-item"
                    setSortValueRef={setSortValueRef}
                    onScrollEnd={onScrollEnd}
                    shouldUpdateScroll={shouldUpdateScrollRef.current}
                /> :
                <LoadingBlock />
            }
        </div>
    )
})

export default AlertsHistory