import { Link } from "react-router-dom";
import { windowPath } from "../../../store/globalVariables";
import { INavItem } from '../../../constants/navItems';
import { memo } from 'react'

type NavItemProps = {
    navItem: INavItem,
    navigationMenuRef: React.RefObject<HTMLDivElement>,
    isActive: boolean
};

function NavItem({ navItem, navigationMenuRef, isActive }: NavItemProps) {
    const navLinkClick = (path: string | undefined) => {
        if (path) {
            if (windowPath.value.replace(process.env.PUBLIC_URL, "").toLowerCase() === path.toLowerCase()) {
                window.location.reload();
            }
            if(window.innerWidth < 992 || window.innerHeight < 460) {
                navigationMenuRef.current?.classList.remove('show')
            }
        }
    }

    return (
        <Link
            to={navItem.path ?? "/"}
            className={
                "navigation-link " +
                (isActive ? 'active' : '')
            }
            onClick={() => navLinkClick(navItem.path)}
        >
            <div className="navigation-childs-container-title">
                {navItem.icon &&
                    <div className="navigation-link-icon">
                        {navItem.icon}
                    </div>}

                {navItem.name &&
                    <div className="navigation-link-name">
                        {navItem.name}
                    </div>}
            </div>
        </Link>
    );
}

export default memo(NavItem);