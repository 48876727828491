import { SplideSlide } from "@splidejs/react-splide"
import { memo } from "react"
import LoadingBlock from "../../../components/LoadingBlock"
import { Link } from "react-router-dom"

type StatisticsLineItemProps = {
    icon: JSX.Element,
    name: string,
    value: number | undefined,
    isLoading: boolean,
    title?: string,
    DropDownMenu?: React.ElementType,
    link?: {
        to: string
    }
}

export const StatisticsLineItem = memo(({DropDownMenu, ...item}: StatisticsLineItemProps) => {
    return (
        <SplideSlide 
            className='my-3' 
            title={item.title}
        >
            {!item.isLoading && item.value !== undefined ?
                (item.link ? 
                <Link 
                    to={item.link.to}
                    className='statistics-line-item'    
                >
                    <div className="statistics-line-item-icon">
                        {item.icon}
                    </div>
                    <div className="statistics-line-item-content">
                        <div className="statistics-line-item-name">
                            {item.name}
                        </div>
                        <div className="statistics-line-item-value">
                            {item.value}
                        </div>
                    </div>
                </Link>
                : <div className='statistics-line-item'>
                    <div className="statistics-line-item-icon">
                        {item.icon}
                    </div>
                    <div className="statistics-line-item-content">
                        <div className="statistics-line-item-name">
                            {item.name}
                        </div>
                        <div className="statistics-line-item-value">
                            {item.value}
                        </div>
                    </div>
                    {DropDownMenu && <DropDownMenu />}
                </div>)
                : <LoadingBlock rounded/>}
        </SplideSlide>
    )
})