import React, { memo, useEffect, useRef } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { ElementVariant, FormButton } from "../../components/Form/FormElemTypes";
import FormElemsSwitcher from "./FormElemsSwitcher";
import './form.scss'

export type FormProps = {
    className?: string,
    header?: JSX.Element,
    elements: ElementVariant[],
    submitButton: FormButton,
    cancelButton?: FormButton & {handleClick: ()=>void},
    denyButton?: FormButton & {handleClick: ()=>void},
    otherButtons?: FormButton[],
    isError?: boolean,
    errorMsg?: JSX.Element,
    isSwalForm?: boolean,
    containerRef?: React.MutableRefObject<HTMLDivElement | null>
};

function Form({
    className,
    header,
    elements,
    submitButton,
    cancelButton,
    denyButton,
    otherButtons,
    isError,
    errorMsg,
    isSwalForm,
    containerRef
}: FormProps) {
    const methods = useForm<any>();
    const { handleSubmit } = methods;

    const cancelRef = useRef<any>(null);
    const denyRef = useRef<any>(null);

    useEffect(()=>{
        if(cancelButton?.handleClick) cancelRef.current = cancelButton.handleClick;
        if(denyButton?.handleClick) denyRef.current = denyButton.handleClick;
    }, [])

    const onSubmit: SubmitHandler<any> = data => submitButton.handleClick ? submitButton.handleClick(data) : null;

    const handleCancel = () => cancelRef.current();
    const handleDeny = () => denyRef.current();

    return (
        <FormProvider {...methods}>
            <form
                action="#"
                className={`${className ?? ''} ${isSwalForm ? "swal2-form" : ""}`}
                onSubmit={handleSubmit(onSubmit)}
            >
                {header && header}

                {isError && errorMsg}

                {elements.map((elem, index) =>
                    <FormElemsSwitcher
                        key={`form_elem_${index}`}
                        element={elem}
                        containerRef={containerRef}
                    />
                )}

                {!isSwalForm ?
                    <>
                        <button
                            type="submit"
                            className={`form-submit btn ${submitButton.className ?? ""}`}
                            disabled={submitButton.isDisabled === true}
                        >
                            {submitButton.name}

                            {submitButton.isLoading && <span
                                className="spinner-border spinner-border-sm text-white align-middle ms-1"
                                role="status"
                            />}
                        </button>

                        {cancelButton &&
                            <button
                                type="button"
                                className={`form-cancel btn ${cancelButton.className ?? ""}`}
                                onClick={cancelButton.handleClick}
                            >
                                {cancelButton.name}
                            </button>}

                        {otherButtons?.map((button, index) =>
                            <button
                                key={`form_button_${index}`}
                                type="button"
                                className={`form-other-button btn ${button.className ?? ""}`}
                                onClick={button.handleClick}
                            >
                                {button.name}
                            </button>
                        )}
                    </> :

                    <footer className="swal2-actions">
                        <button
                            type="submit"
                            autoFocus={submitButton.autoFocus}
                            className={`swal2-confirm swal2-styled ${submitButton.className ?? ""}`}
                        >
                            {submitButton.name}
                        </button>

                        {cancelButton &&
                            <button
                                type="button"
                                className={`swal2-cancel swal2-styled ${cancelButton.className ?? ""}`}
                                autoFocus={cancelButton.autoFocus}
                                onClick={handleCancel}
                            >
                                {cancelButton.name}
                            </button>}

                        {denyButton &&
                            <button
                                type="button"
                                className={`swal2-deny swal2-styled ${denyButton.className ?? ""}`}
                                autoFocus={denyButton.autoFocus}
                                onClick={handleDeny}
                            >
                                {denyButton.name}
                            </button>}
                    </footer>}
            </form>
        </FormProvider>
    );
}

export default memo(Form);