import React, { memo } from 'react'
import { iframeStyles } from '../../../constants/others'
import DOMPurify from 'dompurify'
import { useAppSelector } from '../../../hooks/redux'

type ErrorMsgProps = {
    errorMsg: string
}

const ErrorMsg = memo(({ errorMsg }: ErrorMsgProps) => {
    const isDarkTheme = useAppSelector(state => state.user.isDarkTheme);

    return (
        <iframe
            className='w-100 h-100'
            srcDoc={
                (isDarkTheme ?
                    "<style>body{color: white !important}</style>"
                    : "") +
                iframeStyles +
                `<div class="error-info-msg">` +
                DOMPurify.sanitize(errorMsg)
                + "</div>"}
        />
    )
})

export default ErrorMsg