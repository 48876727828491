import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const LS_STATISTIC_CHECKLIST = 'statistic_checklist'
const LS_DARK_THEME = 'is_dark_theme'

interface StatisticState {
    statisticCheckList: string[],
    isDarkTheme: boolean
}

const initialState: StatisticState = {
    statisticCheckList: JSON.parse(localStorage.getItem(LS_STATISTIC_CHECKLIST) ?? '[]'),
    isDarkTheme: JSON.parse(localStorage.getItem(LS_DARK_THEME) ?? 'false')
}

export const statisticSlice = createSlice({
    name: 'statistic',
    initialState,
    reducers: {
        setFilters(state, action: PayloadAction<string>) {
            state.statisticCheckList.push(action.payload);
            localStorage.setItem(LS_STATISTIC_CHECKLIST, JSON.stringify(state.statisticCheckList));
        },
        cancelFilters(state) {
            state.statisticCheckList = [];
            localStorage.setItem(LS_STATISTIC_CHECKLIST, '[]');
        },
        changeTheme(state) {
            state.isDarkTheme = !state.isDarkTheme;
            localStorage.setItem(LS_DARK_THEME, `${state.isDarkTheme}`);
        },
    }
})

export const statisticActions = statisticSlice.actions
export const statisticReducer = statisticSlice.reducer
