import React, {useRef} from 'react'
import { MdOutlineRefresh } from 'react-icons/md';

type PageTitleProps = {
    title: string;
    isLoading?: boolean;
    reloadPage?: () => void;
    pageUpdateTime?: string;
}

function PageTitle({ title, isLoading, reloadPage, pageUpdateTime }: PageTitleProps) {
    return (
        <>
            {/* Название страницы */}
            <div className='page-title' style={{ width: title?.length * 13 + "px" }}>
                <span className='text-truncate'>
                    {title}
                </span>

                {/* Обновление данных страницы */}
                {reloadPage !== undefined &&
                    (!isLoading ?
                        // Отобразить иконку обновления
                        <div
                            className="page-reload-btn-container"
                            title='Обновить статистику'
                            onClick={reloadPage}
                        >
                            <div className="page-reload-btn">
                                <MdOutlineRefresh />
                            </div>
                        </div>
                        :
                        // Отобразить иконку спиннера
                        <div
                            className="page-reload-btn-container loading"
                        >
                            <div
                                className="page-reload-btn"
                                title='Обновление статистики...'
                            >
                                <span
                                    className="spinner-border text-white align-middle"
                                    role="status"
                                />
                            </div>
                        </div>)}
            </div>

            {/* Дата и время обновления страницы */}
            {pageUpdateTime &&
                <div className="page-update-time">
                    Обновлено {pageUpdateTime}
                </div>
            }
        </>
    )
}

export default React.memo(PageTitle)
