import { useMemo } from 'react';
import PageTitle from '../../components/PageTitle';
import RsuiteTable, { ColumnsList } from '../../components/rsuite-table/RsuiteTable';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import { ComplexTypeProperties, ComplexTypePropertiesNames, IComplexType } from '../../models/entities/IComplexType';
import { 
    useCreateComplexTypeMutation, 
    useDeleteComplexTypeMutation, 
    useGetComplexesTypesQuery, 
    useUpdateComplexTypeMutation 
} from '../../services/ComplexTypeService';
import { fetchDataAlert, showLoadingSwal } from '../../utils/utils';
import Form from '../../components/Form/Form';
import { FormNames, FormTypes, getFormElems } from '../../constants/formElems';
import { RowDataType } from 'rsuite-table';
import SwalTable from '../../components/SwalTable';
import { RSwal } from '../../constants/sweetAlert';
import LoadingBlock from '../../components/LoadingBlock';
import { defaultPollingInterval } from '../../store/globalVariables';

function ComplexesTypesPage() {
    const { refetch, data: complexesTypes, isFetching, fulfilledTimeStamp } = useGetComplexesTypesQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const [createComplexType] = useCreateComplexTypeMutation();
    const [updateComplexType] = useUpdateComplexTypeMutation();
    const [deleteComplexType] = useDeleteComplexTypeMutation();

    const handleCreateComplexType = async (row: IComplexType) => {
        showLoadingSwal("Создание...");
        const result = await createComplexType(row);
        fetchDataAlert({
            result: result,
            showSuccessMessage: true,
            successMessage: "data" in result && result.data.data ?
                SwalTable({
                    headers: [
                        {
                            key: ComplexTypeProperties.id,
                            name: ComplexTypePropertiesNames.id,
                            width: 60,
                            minWidth: 60
                        },
                        {
                            key: ComplexTypeProperties.name,
                            name: ComplexTypePropertiesNames.name,
                            flexGrow: 1
                        }
                    ],
                    rows: [{
                        [ComplexTypeProperties.id]: result.data.data[ComplexTypeProperties.id],
                        [ComplexTypeProperties.name]: result.data.data[ComplexTypeProperties.name]
                    }]
                })
                : undefined
        })
    }

    const handleUpdateComplexType = async (row: IComplexType) => {
        showLoadingSwal("Изменение...");
        const result = await updateComplexType(row);
        fetchDataAlert({
            result: result,
            showSuccessMessage: true
        })
    }

    const handleDeleteComplexType = async (id: number) => {
        showLoadingSwal("Удаление...");
        const result = await deleteComplexType(id);
        fetchDataAlert({
            result: result,
            showSuccessMessage: true
        })
    }

    const columns: ColumnsList = useMemo(() => {
        const currentCols: ColumnsList = [
            {
                columnProps: {
                    width: 80,
                    fixed: "left"
                },
                headerProps: {
                    children: <div className="rs-table-crud-icon">
                        <MdAdd
                            title="Создать тип системы"
                            onClick={() =>
                                RSwal.fire({
                                    title: "Создание типа системы",
                                    html: <Form
                                        isSwalForm
                                        // Элементы формы модального окна
                                        elements={getFormElems({
                                            formName: FormNames.complexType,
                                            formType: FormTypes.create
                                        })}
                                        submitButton={{
                                            name: "Создать",
                                            handleClick: (data: IComplexType) => handleCreateComplexType(data)
                                        }}
                                        denyButton={{
                                            name: "Отменить",
                                            autoFocus: true,
                                            handleClick: () => RSwal.close()
                                        }}
                                    />,
                                    showConfirmButton: false,
                                    showCloseButton: true
                                })} />
                    </div>
                },
                cellProps: {
                    children: (row: RowDataType<IComplexType> | IComplexType) => <>
                        <div className="rs-table-crud-icon">
                            <MdEdit
                                title="Изменить тип системы"
                                onClick={() =>
                                    RSwal.fire({
                                        title: "Редактирование типа системы",
                                        html: <Form
                                            isSwalForm
                                            // Элементы формы модального окна
                                            elements={getFormElems({
                                                formName: FormNames.complexType,
                                                formType: FormTypes.update,
                                                row: {
                                                    id: row.id,
                                                    name: row.name
                                                }
                                            })}
                                            submitButton={{
                                                name: "Изменить",
                                                handleClick: (data: IComplexType) => handleUpdateComplexType(data)
                                            }}
                                            denyButton={{
                                                name: "Отменить",
                                                autoFocus: true,
                                                handleClick: () => RSwal.close()
                                            }}
                                        />,
                                        showConfirmButton: false,
                                        showCloseButton: true
                                    })} />
                        </div>
                        <div className="rs-table-crud-icon">
                            <MdDelete
                                title="Удалить тип системы"
                                onClick={() =>
                                    RSwal.fire({
                                        title: "Удаление типа системы",
                                        html: <Form
                                            isSwalForm
                                            // Элементы формы модального окна
                                            elements={getFormElems({
                                                formName: FormNames.complexType,
                                                formType: FormTypes.delete,
                                                row: {
                                                    id: row.id,
                                                    name: row.name
                                                }
                                            })}
                                            submitButton={{
                                                name: "Удалить",
                                                handleClick: (data: IComplexType) => handleDeleteComplexType(data.id)
                                            }}
                                            denyButton={{
                                                name: "Отменить",
                                                autoFocus: true,
                                                handleClick: () => RSwal.close()
                                            }}
                                        />,
                                        showConfirmButton: false,
                                        showCloseButton: true
                                    })} />
                        </div>
                    </>
                }
            },
            {
                columnProps: {
                    sortable: true,
                    fixed: true,
                    width: 60
                },
                headerProps: {
                    children: ComplexTypePropertiesNames.id
                },
                cellProps: {
                    dataKey: ComplexTypeProperties.id
                }
            },
            {
                columnProps: {
                    sortable: true,
                    flexGrow: 1,
                    fullText: true
                },
                headerProps: {
                    children: ComplexTypePropertiesNames.name
                },
                cellProps: {
                    dataKey: ComplexTypeProperties.name
                },
                searchable: true
            }
        ];
        return currentCols;
    }, [])

    return (
        <main>
            <PageTitle
                title='Типы систем'
                reloadPage={() => refetch()}
                isLoading={isFetching}
                pageUpdateTime={fulfilledTimeStamp && !isFetching ? new Date(fulfilledTimeStamp).toLocaleString().replace(',', "") : ""}
            />

            {complexesTypes?.data ?
                <RsuiteTable
                    data={complexesTypes.data}
                    columns={columns}
                    withTotalRows
                    virtualized
                /> : <LoadingBlock rounded />}
        </main>
    );
}

export default ComplexesTypesPage;