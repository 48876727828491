import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {defaultBaseQueryParams} from "../constants/defaultBaseQueryParams";

export const serverManagerApi = createApi({
    reducerPath: 'serverManagerApi',
    baseQuery: fetchBaseQuery(defaultBaseQueryParams),
    endpoints: build => ({

    })
})

export const {} = serverManagerApi;