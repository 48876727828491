import {AiFillInfoCircle} from 'react-icons/ai'
import {BsQuestionCircleFill} from 'react-icons/bs'
import {HiExclamationCircle} from 'react-icons/hi2'

export enum EErrorTypes {
    "Без категории" = 1,
    "Информационная" = 2,
    "Средняя" = 3,
    "Критическая" = 4
};

type TErrorTypesIcons = {
    [key in EErrorTypes]: JSX.Element
}

export const ErrorTypesIcons: TErrorTypesIcons = {
    [EErrorTypes['Без категории']]: <BsQuestionCircleFill className='error-unknown' />,
    [EErrorTypes['Информационная']]: <AiFillInfoCircle className='error-info' />,
    [EErrorTypes['Средняя']]: <HiExclamationCircle className='error-warning' />,
    [EErrorTypes['Критическая']]: <HiExclamationCircle className='error' />,
};