import { memo, useCallback, useMemo, useRef, useState, RefObject, MutableRefObject } from 'react'
import { IMenuFilter } from '../../models/responses/IGetFilters';
import useShowing from '../../hooks/showing';
import './filters-menu.scss'
import FiltersMenuItem from './FiltersMenuItem';
import SortMenuItem from './SortMenuItem';
import { MonitoringPanelLocState } from '../../pages/monitoring-page/MonitoringPanel/MonitoringPanel';
import { history } from '../../utils/utils';
import { sortTypes } from '../../constants/others';
import { SortType } from 'rsuite-table';

export type Sort = {
    value: string,
    type?: keyof typeof sortTypes
}

export type FiltersMenuPropsWithoutSort = {
    filtersMenuTogglerRef: RefObject<HTMLDivElement>,
    filtersMenuRef: RefObject<HTMLDivElement>,
    listFilters?: {
        [key: string]: IMenuFilter
    } | undefined,
    listSorts?: never,
    sortValueRef?: never,
    setSortValueRef?: never,
    customFilters?: JSX.Element[],
    submitButton?: {
        name: string,
        handle: () => void
    },
    cancelButton?: {
        name: string,
        handle: () => void
    }
};

export type FiltersMenuPropsWithSort = {
    filtersMenuTogglerRef: RefObject<HTMLDivElement>,
    filtersMenuRef: RefObject<HTMLDivElement>,
    listFilters?: {
        [key: string]: IMenuFilter
    } | undefined,
    listSorts: {
        name: string,
        value?: string
    }[],
    sortValueRef: MutableRefObject<Sort | undefined>,
    setSortValueRef: MutableRefObject<((sortColumn: string | undefined, sortType: SortType | undefined) => void) | undefined>,
    customFilters?: JSX.Element[],
    submitButton?: {
        name: string,
        handle: () => void
    },
    cancelButton?: {
        name: string,
        handle: () => void
    }
};

export type FiltersMenuProps = FiltersMenuPropsWithoutSort | FiltersMenuPropsWithSort;

export type TSelectedFilters = {
    [T in keyof string]: (number | string)[]
} | {}

export type TSelectFilters = (filterKey: string, value: number | string) => void

const FiltersMenu = memo(({
    filtersMenuTogglerRef,
    filtersMenuRef,
    listFilters,
    listSorts,
    sortValueRef,
    setSortValueRef,
    customFilters,
    submitButton,
    cancelButton
}: FiltersMenuProps) => {
    useShowing({
        togglerRef: filtersMenuTogglerRef,
        toggleTargetRef: filtersMenuRef,
        closeOnClickOutside: true
    });

    const [selectedFilters, setSelectedFilters] = useState<TSelectedFilters>(()=>{
        if(listFilters) {
            const currentSelectedFilters: TSelectedFilters = {};
            
            let locState: MonitoringPanelLocState | null = history.location?.state;

            if(locState !== null) {
                Object.keys(listFilters).forEach(filterKey=>{
                    const selectedItems = locState ? locState[filterKey as keyof MonitoringPanelLocState] : undefined;
                    
                    if(selectedItems && Array.isArray(selectedItems)) {
                        (currentSelectedFilters[filterKey as keyof TSelectedFilters] as any) = [...selectedItems];
                    }
                })
            }
            
            return {...currentSelectedFilters};
        }
        return {}
    });
 
    const selectFilters: TSelectFilters = useCallback((filterKey: string, value: number | string) => {
        if(listFilters) {
            const currentFilterRef = listFilters[filterKey as keyof FiltersMenuProps['listFilters']].ref;
            
            if(currentFilterRef?.current) {
                setSelectedFilters(prevFilters => {
                    const currentKey = filterKey as keyof TSelectedFilters;
                    
                    if(!prevFilters[currentKey]) {
                       (prevFilters[currentKey] as any) = [];
                    }

                    let currentFilter = prevFilters[currentKey] as typeof value[];

                    if (currentFilter.includes(value)) {
                        currentFilter = currentFilter.filter(item => item !== value);
                    }
                    else {
                        currentFilter.push(value);
                    }
        
                    currentFilterRef.current = currentFilter as (number[] | string[]);
        
                    return {
                        ...prevFilters,
                        [filterKey]: [...currentFilter]
                    };
                });
            }
            else {
                console.error(`Не задан Ref для фильтра ${filterKey} в FiltersMenu`)
            }
        }
    }, [listFilters]);

    const submit = useRef(submitButton?.handle);
    const cancel = useRef(cancelButton?.handle);

    const handleSubmit = () => submit.current ? submit.current() : null;
    const handleCancel = () => {
        if(cancel.current) cancel.current();
        setSelectedFilters({});
    };

    const memoCustomFilters = useMemo(() => customFilters, [customFilters]);

    const [sortValue, setSortValue] = useState<Sort>();

    const selectSort = (value: string) => {
        if (listSorts) {
            sortValueRef.current = {
                value: value,
                type: sortValueRef.current?.value === value ?

                    (sortValueRef.current?.type === sortTypes.ascending ?
                        sortTypes.descending :
                        (sortValueRef.current?.type === sortTypes.descending ?
                            sortTypes.none :
                            sortTypes.ascending)
                    ) :

                    sortTypes.ascending
            };

            setSortValue({ ...sortValueRef.current });

            if(setSortValueRef?.current) {
                let currentSortType: SortType | undefined = undefined;

                if(sortValueRef.current.type === sortTypes.ascending) {
                    currentSortType = 'asc'
                }
                if(sortValueRef.current.type === sortTypes.descending) {
                    currentSortType = 'desc'
                }

                setSortValueRef.current(sortValueRef.current.value, currentSortType);
            }
        }
    }

    return (
        <div
            className="filters-menu"
            ref={filtersMenuRef}
            onKeyDown={(event) => {
                if (event.key === 'Enter' && filtersMenuRef.current) {
                    handleSubmit();
                    filtersMenuRef.current.blur();
                }
                if (event.key === 'Escape' && filtersMenuRef.current) {
                    handleCancel();
                    filtersMenuRef.current.blur();
                }
            }}
            tabIndex={0}
        >
            <div className="filters-menu-content">
                {listFilters && Object.keys(listFilters).map((filterKey: string, index) =>
                    <FiltersMenuItem
                        key={index}
                        filter={{
                            type: 'list',
                            content: {
                                ...listFilters[filterKey],
                                selectedItems: (selectedFilters[filterKey as keyof TSelectedFilters] as [])?.length ? selectedFilters[filterKey as keyof TSelectedFilters] : []
                            },
                            filterKey: filterKey
                        }}
                        handleListSelect={selectFilters}
                    />
                )}

                {listSorts && listSorts.map((sortItem, index) =>
                    <SortMenuItem
                        key={index}
                        name={sortItem.name}
                        value={sortItem.value}
                        handleSelectSort={(name)=>selectSort(name)}
                        sortValue={sortValue}
                    />
                )}

                {Array.isArray(memoCustomFilters) && memoCustomFilters.length > 0 && memoCustomFilters}

                {(submitButton || cancelButton) &&
                    <div className="filters-menu-buttons">
                        {submitButton && <div
                            className="btn btn-primary"
                            onClick={handleSubmit}
                        >
                            {submitButton.name}
                        </div>}
                        {cancelButton && <div
                            className="btn btn-danger filter-menu-cancel-btn"
                            onClick={handleCancel}
                        >
                            {cancelButton.name}
                        </div>}
                    </div>}
            </div>
        </div>
    )
})

export default FiltersMenu