import { RowDataType } from "rsuite-table";
import { ElementVariant, FormElemTypes, SelectElem } from "../../components/Form/FormElemTypes";
import { IUser } from "../../models/entities/IUser";
import { nameof } from "../../utils/utils";

export const getCreateUserElements = (rolesOptions: SelectElem['options']): ElementVariant[] => [
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('firstName'),
        label: "Имя",
        htmlAttributes: {
            placeholder: "Иван",
            required: true
        }
    },
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('lastName'),
        label: "Фамилия",
        htmlAttributes: {
            placeholder: "Иванов",
            required: true
        }
    },
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('username'),
        label: "Логин",
        htmlAttributes: {
            autoComplete: 'new-password',
            placeholder: "ivan_ivanov",
            required: true
        }
    },
    {
        type: FormElemTypes.select,
        name: 'roleId',
        label: "Роль",
        options: rolesOptions,
        htmlAttributes: {
            required: true
        }
    },
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('password'),
        label: "Пароль",
        htmlAttributes: {
            autoComplete: 'new-password',
            placeholder: "*****",
            required: true
        },
        withGeneratePassword: true
    },
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('domainLDAP'),
        label: "DomainLDAP",
        htmlAttributes: {
            placeholder: "DomainLDAP"
        }
    }
];

export const getUpdateUserElements = (data: IUser | RowDataType, rolesOptions: SelectElem['options']): ElementVariant[] => [
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('id'),
        label: "id",
        htmlAttributes: {
            defaultValue: String(data.id),
            readOnly: true,
            required: true
        }
    },
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('firstName'),
        label: "Имя",
        htmlAttributes: {
            defaultValue: data.firstName,
            placeholder: "Иван",
            required: true
        }
    },
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('lastName'),
        label: "Фамилия",
        htmlAttributes: {
            defaultValue: data.lastName,
            placeholder: "Иванов",
            required: true
        }
    },
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('username'),
        label: "Логин",
        htmlAttributes: {
            defaultValue: data.username,
            placeholder: "ivan_ivanov",
            required: true
        }
    },
    {
        type: FormElemTypes.select,
        name: "roleId",
        label: "Роль",
        options: rolesOptions,
        htmlAttributes: {
            defaultValue: String(data.role.id),
            required: true
        }
    },
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('password'),
        label: "Новый пароль",
        htmlAttributes: {
            autoComplete: 'new-password',
            placeholder: "*****"
        },
        withGeneratePassword: true
    },
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('domainLDAP'),
        label: "DomainLDAP",
        htmlAttributes: {
            defaultValue: data.domainLDAP,
            placeholder: "DomainLDAP"
        }
    }
];

export const getDeleteUserElements = (data: IUser | RowDataType): ElementVariant[] => [
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('id'),
        label: "id",
        htmlAttributes: {
            defaultValue: String(data.id),
            readOnly: true
        }
    },
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('firstName'),
        label: "Имя",
        htmlAttributes: {
            defaultValue: data.firstName,
            disabled: true
        }
    },
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('lastName'),
        label: "Фамилия",
        htmlAttributes: {
            defaultValue: data.lastName,
            disabled: true
        }
    },
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('username'),
        label: "Логин",
        htmlAttributes: {
            defaultValue: data.username,
            disabled: true
        }
    },
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('role'),
        label: "Роль",
        htmlAttributes: {
            defaultValue: data.role.name,
            disabled: true
        }
    },
    {
        type: FormElemTypes.text,
        name: nameof<IUser>('domainLDAP'),
        label: "DomainLDAP",
        htmlAttributes: {
            defaultValue: data.domainLDAP,
            disabled: true
        }
    }
];