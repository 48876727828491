import { memo, useLayoutEffect, useRef, useMemo, useCallback, useState, useEffect } from 'react';
import PageTitle from '../../components/PageTitle';
import { useGetFiltersQuery } from '../../services/ErrorService';
import './clients-statistic.scss'
import { MdFilterAlt } from 'react-icons/md';
import FiltersMenu from '../../components/filters-menu/FiltersMenu';
import { IGetFilters } from '../../models/responses/IGetFilters';
import ClientsStatisticCol from './ClientsStatisticCol';
import LoadingBlock from '../../components/LoadingBlock';
import { useGrabScroll } from '../../hooks/grabScroll';
import FiltersMenuItem from '../../components/filters-menu/FiltersMenuItem';
import { useLazyGetClientsStatisticQuery } from '../../services/StatisticService';
import { defaultPollingInterval } from '../../store/globalVariables';
import FiltersHeader from '../../components/filters-header-line/FiltersHeader';

export type TSelectedFilters = {
    [T in keyof IGetFilters]: number[]
}

const initFilters = {
    regions: [],
    complexTypes: [],
    errorLevels: []
};

function ClientsStatisticPage() {
    const [getStatistic, { data: statistic, isFetching: statisticIsLoading, fulfilledTimeStamp }] = useLazyGetClientsStatisticQuery({
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const { refetch: getFilters, data: filters, isFetching: filtersIsLoading } = useGetFiltersQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    useLayoutEffect(() => {
        getStatistic({
            uniqueUsersOfComplex: true
        }, true);
    }, []);

    const filtersMenuTogglerRef = useRef<HTMLDivElement>(null);
    const filtersMenuRef = useRef<HTMLDivElement>(null);
    const regionsRef = useRef<number[]>([]);
    const complexTypesRef = useRef<number[]>([]);
    const errorLevelsRef = useRef<number[]>([]);
    const uniqUsersRef = useRef<HTMLInputElement>(null);

    const statisticContentRef = useRef<HTMLDivElement>(null);

    const [startDate, setStartDate] = useState<Date | null>(null);
    const startDateRef = useRef<Date | null>(null);
    
    const [endDate, setEndDate] = useState<Date | null>(null);
    const endDateRef = useRef<Date | null>(null);

    const [selectedFilters, setSelectedFilters] = useState<TSelectedFilters>({ ...initFilters });

    const currentFilters = useMemo(() => {
        if (filters?.data) {
            const prevFilters: IGetFilters = JSON.parse(JSON.stringify(filters.data));

            prevFilters.regions.ref = regionsRef;
            prevFilters.regions.selectedItems = selectedFilters.regions;

            prevFilters.complexTypes.ref = complexTypesRef;
            prevFilters.complexTypes.selectedItems = selectedFilters.complexTypes;

            prevFilters.errorLevels.ref = errorLevelsRef;
            prevFilters.errorLevels.selectedItems = selectedFilters.errorLevels;

            return { ...prevFilters };
        }
    }, [filters, selectedFilters]);

    useGrabScroll(statisticContentRef);

    const applyFilters = useCallback(() => {
        if (filtersMenuRef.current) filtersMenuRef.current.classList.remove('show');

        let withFilters = false;

        if (regionsRef.current.length > 0) {
            withFilters = true;
        }

        if (complexTypesRef.current.length > 0) {
            withFilters = true;
        }

        if (errorLevelsRef.current.length > 0) {
            withFilters = true;
        }

        if (startDateRef.current) {
            withFilters = true;
        }

        if (endDateRef.current) {
            withFilters = true;
        }

        if (uniqUsersRef.current?.checked === false) {
            withFilters = true;
        }

        if (filtersMenuTogglerRef.current) {
            if (withFilters) {
                filtersMenuTogglerRef.current.classList.add('active');
            }
            else {
                filtersMenuTogglerRef.current.classList.remove('active');
            }
        }
        
        getStatistic({
            regions: regionsRef.current.length > 0 ? regionsRef.current : undefined,
            complexTypes: complexTypesRef.current.length > 0 ? complexTypesRef.current : undefined,
            errorLevels: errorLevelsRef.current.length > 0 ? errorLevelsRef.current : undefined,
            startDate: startDateRef.current?.toLocaleString().replace(',', ''),
            endDate: endDateRef.current?.toLocaleString().replace(',', ''),
            uniqueUsersOfComplex: uniqUsersRef.current?.checked
        })
    }, []);

    const cancelFilters = useCallback(() => {
        if (filtersMenuRef.current) filtersMenuRef.current.classList.remove('show');
        if (filtersMenuTogglerRef.current) filtersMenuTogglerRef.current.classList.remove('active');
        if (uniqUsersRef.current) uniqUsersRef.current.checked = true;
        regionsRef.current = [];
        complexTypesRef.current = [];
        errorLevelsRef.current = [];
        setSelectedFilters({ ...initFilters })
        setStartDate(null);
        setEndDate(null);
        startDateRef.current = null;
        endDateRef.current = null;
        getStatistic({ uniqueUsersOfComplex: true })
    }, []);
    
    const onChangeStartDate = useCallback((value: Date | null) => {
        setStartDate(value);
        startDateRef.current = value;
    }, []);

    const onChangeEndDate = useCallback((value: Date | null) => {
        setEndDate(value);
        endDateRef.current = value;
    }, []);

    return (
        <main>
            <PageTitle
                title='Клиентская статистика'
                reloadPage={() => {
                    applyFilters();
                    getFilters();
                }}
                isLoading={statisticIsLoading}
                pageUpdateTime={!statisticIsLoading && fulfilledTimeStamp ?
                    new Date(fulfilledTimeStamp).toLocaleString().replace(',', "") :
                    ""}
            />

            <div className="clients-statistic-page">
                <FiltersHeader
                    items={[{
                        btn: {
                            content: !filtersIsLoading ?
                                <MdFilterAlt /> :
                                <span className="spinner-border spinner-border-sm pointer-events-none" />,
                            innerRef: filtersMenuTogglerRef
                        },
                        menu: {
                            content:
                                <FiltersMenu
                                    filtersMenuRef={filtersMenuRef}
                                    filtersMenuTogglerRef={filtersMenuTogglerRef}
                                    listFilters={currentFilters}
                                    customFilters={[
                                        <FiltersMenuItem
                                            key='periodMenuFilter'
                                            filter={{
                                                type: 'datePicker',
                                                content: {
                                                    name: 'Период отбора',
                                                    setStartDate: onChangeStartDate,
                                                    setEndDate: onChangeEndDate,
                                                    startDate: startDate,
                                                    endDate: endDate,
                                                    positionStartDate: 'auto',
                                                    positionEndDate: 'auto'
                                                }
                                            }}
                                        />,
                                        <FiltersMenuItem
                                            key='uniqUsersMenuFilter'
                                            filter={{
                                                type: 'switch',
                                                content: {
                                                    name: 'Уникальные пользователи',
                                                    innerRef: uniqUsersRef,
                                                    defaultChecked: true
                                                }
                                            }}
                                        />
                                    ]}
                                    submitButton={{
                                        name: "Применить",
                                        handle: applyFilters
                                    }}
                                    cancelButton={{
                                        name: "Отменить",
                                        handle: cancelFilters
                                    }}
                                />,
                            innerRef: filtersMenuRef
                        }
                    }]}
                />

                <div
                    className='clients-statistic-content'
                    ref={statisticContentRef}
                >
                    {statistic?.data && !statisticIsLoading &&
                        statistic?.data?.browsers &&
                        statistic?.data?.operatingSystems &&
                        statistic?.data?.screenResolutions ?
                        <>
                            <ClientsStatisticCol data={statistic.data.browsers} />
                            <ClientsStatisticCol data={statistic.data.operatingSystems} />
                            <ClientsStatisticCol data={statistic.data.screenResolutions} />
                        </> :
                        <LoadingBlock />}
                </div>
            </div>
        </main>
    );
}

export default memo(ClientsStatisticPage);