import { ReactNode, useRef } from "react"
import { MdContentCopy } from "react-icons/md"

type CopyableBlockProps = {
    content?: ReactNode,
    copyableValue: string,
    className?: string
}

export default function CopyableBlock({content, copyableValue, className}: CopyableBlockProps) {
    const copyMessageRef = useRef<HTMLDivElement>(null);
    let copyTimer: NodeJS.Timeout | undefined = undefined;
    let copyTimerIsActive: boolean = false;
    
    return (
        <div
            className={"copyable-block " + (className ?? "")}
            title="Скопировать"
            onClick={() => window.navigator.clipboard.writeText(copyableValue)}
            onMouseDown={() => {
                if (copyMessageRef.current) {
                    copyMessageRef.current.classList.add('copying');
                }

                clearTimeout(copyTimer);

                copyTimerIsActive=true;

                copyTimer = setTimeout(() => {
                    if (copyMessageRef.current) copyMessageRef.current.classList.remove('copying');
                    copyTimerIsActive=false;
                }, 1000);
            }}
            onMouseLeave={()=>{
                if(copyMessageRef.current && !copyTimerIsActive) copyMessageRef.current.classList.remove('copying');
            }}
        >
            <div className="copy-content">
                {content ? content : copyableValue}
            </div>
            <div 
                className="copy-message" 
                ref={copyMessageRef}
            >
                <MdContentCopy />
            </div>
        </div>
    )
}
