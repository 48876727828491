import { ReactNode, RefObject, memo } from "react";

type CustomTooltipProps = {
    refs?: {
        containerRef: RefObject<HTMLDivElement>,
        contentRef: RefObject<HTMLDivElement>,
        bgRef: RefObject<HTMLDivElement>
    },
    className?: string,
    label?: string,
    children?: ReactNode,
    keysColors?: {
        [key: string]: string
    }
}

export const CustomTooltip = memo(({ refs, className, label, children, keysColors }: CustomTooltipProps) => {
    return (
        <div
            ref={refs?.containerRef}
            className={`custom-tooltip ${className ?? ""}`}
        >
            <div
                className="custom-tooltip-bg"
                ref={refs?.bgRef}
                style={keysColors && label ? {
                    backgroundColor: keysColors[label],
                    outlineColor: keysColors[label]
                } : {}}
            />
            <div
                className="custom-tooltip-content"
                ref={refs?.contentRef}
            >
                {children}
            </div>
        </div>
    )
});