import React, { useMemo, useCallback } from 'react';
import PageTitle from '../../components/PageTitle';
import { 
    useCreateUserMutation, 
    useDeleteApplicationForRegistrMutation, 
    useDeleteUserMutation, 
    useGetAllApplicationsForRegistrQuery, 
    useGetAllUsersQuery, 
    useUpdateUserMutation 
} from '../../services/UserService';
import RsuiteTable, { ColumnsList } from '../../components/rsuite-table/RsuiteTable';
import { MdAdd, MdContactMail, MdDelete, MdEdit } from 'react-icons/md';
import { RSwal } from '../../constants/sweetAlert';
import Form from '../../components/Form/Form';
import { IUser } from '../../models/entities/IUser';
import { fetchDataAlert, nameof, showLoadingSwal } from '../../utils/utils';
import LoadingBlock from '../../components/LoadingBlock';
import { getCreateUserElements, getDeleteUserElements, getUpdateUserElements } from './UserCRUDElements';
import { useGetAllRolesQuery } from '../../services/RoleService';
import { RowDataType } from 'rsuite-table';
import { useAppSelector } from '../../hooks/redux';
import { dispatch } from '../../store';
import { fetchReauth } from '../../store/reducers/UserReducer';
import { defaultPollingInterval } from '../../store/globalVariables';
import { FormElemTypes } from '../../components/Form/FormElemTypes';
import IApplyForRegistration from '../../models/requests/IApplyForRegistration';
import { GrStatusGoodSmall } from "react-icons/gr";
import { onlineInterval } from '../../constants/others';

function UsersPage() {
    const currentUser = useAppSelector(state => state.user.currentUserState);

    const { refetch: getUsers, data: users, isFetching, fulfilledTimeStamp } = useGetAllUsersQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const { refetch: getRoles, data: roles } = useGetAllRolesQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const { data: applicationsForRegistr } = useGetAllApplicationsForRegistrQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const [updateUserFetch] = useUpdateUserMutation();
    const [createUserFetch] = useCreateUserMutation();
    const [deleteUserFetch] = useDeleteUserMutation();

    const createUser = (user: IUser, application: Pick<IApplyForRegistration, 'id' | 'email'> | undefined = undefined) => {
        showLoadingSwal("Создание...");
        createUserFetch(user).then(result => {
            fetchDataAlert({
                result: result,
                showSuccessMessage: true
            })
            
            if (application && "data" in result && result.data.success) {
                deleteApplicationForRegistr(application.id).then((rejectResult) => {
                    fetchDataAlert({
                        result: rejectResult
                    })

                    window.open(
                        `mailto:${application.email}?subject=Подтверждение регистрации на Сервисе Мониторинга&body=Добрый день, Ваша заявка на Сервисе Мониторинга ОДОБРЕНА\n` +
                        ` Логин: ${user.username}\n` +
                        ` Пароль: ${user.password}\n`
                    );
                });
            }
        });
    }

    const updateUser = (data: IUser) => {
        showLoadingSwal("Изменение...");

        updateUserFetch(data).then(result => {
            fetchDataAlert({
                result: result,
                showSuccessMessage: true
            });

            if (Number(data.id) === currentUser?.id) {
                dispatch(fetchReauth());
            }
        });
    }

    const deleteUser = (id: number) => {
        showLoadingSwal("Удаление...");
        
        deleteUserFetch(id).then(result => {
            fetchDataAlert({
                result: result,
                showSuccessMessage: true
            })
        })
    }

    const columns: ColumnsList = useMemo(() => {
        if (roles?.data && Array.isArray(roles.data)) {
            const rolesOptions = roles.data.map(role => ({
                name: role.name,
                value: role.id
            }));

            const currentCols: ColumnsList = [
                {
                    columnProps: {
                        width: 80,
                        fixed: "left"
                    },
                    headerProps: {
                        children: <div className="rs-table-crud-icon">
                            <MdAdd
                                title="Добавить пользователя"
                                onClick={() =>
                                    RSwal.fire({
                                        title: "Добавление пользователя",
                                        html: <Form
                                            isSwalForm
                                            // Элементы формы модального окна
                                            elements={getCreateUserElements(rolesOptions)}
                                            submitButton={{
                                                name: "Добавить",
                                                handleClick: (data: IUser) => createUser(data)
                                            }}
                                            denyButton={{
                                                name: "Отменить",
                                                autoFocus: true,
                                                handleClick: () => RSwal.close()
                                            }}
                                        />,
                                        showConfirmButton: false,
                                        showCloseButton: true
                                    })} />
                        </div>
                    },
                    cellProps: {
                        children: (row: IUser | RowDataType) => <>
                            <div className="rs-table-crud-icon">
                                <MdEdit
                                    title="Изменить пользователя"
                                    onClick={() => {
                                        RSwal.fire({
                                            title: "Редактирование пользователя",
                                            html: <Form
                                                isSwalForm
                                                // Элементы формы модального окна
                                                elements={getUpdateUserElements(row, rolesOptions)}
                                                submitButton={{
                                                    name: "Изменить",
                                                    handleClick: (data: IUser) => updateUser(data)
                                                }}
                                                denyButton={{
                                                    name: "Отменить",
                                                    autoFocus: true,
                                                    handleClick: () => RSwal.close()
                                                }}
                                            />,
                                            showConfirmButton: false,
                                            showCloseButton: true
                                        })
                                    }}/>
                            </div>
                            <div className="rs-table-crud-icon">
                                <MdDelete
                                    title="Удалить пользователя"
                                    onClick={() =>
                                        RSwal.fire({
                                            title: "Удаление пользователя",
                                            html: <Form
                                                isSwalForm
                                                // Элементы формы модального окна
                                                elements={getDeleteUserElements(row)}
                                                submitButton={{
                                                    name: "Удалить",
                                                    handleClick: (data: IUser) => deleteUser(data.id)
                                                }}
                                                denyButton={{
                                                    name: "Отменить",
                                                    autoFocus: true,
                                                    handleClick: () => RSwal.close()
                                                }}
                                            />,
                                            showConfirmButton: false,
                                            showCloseButton: true
                                        })} />
                            </div>
                        </>
                    }
                },
                {
                    columnProps: {
                        sortable: true,
                        fixed: true,
                        width: 60
                    },
                    headerProps: {
                        children: "id"
                    },
                    cellProps: {
                        dataKey: nameof<IUser>('id')
                    }
                },
                {
                    columnProps: {
                        sortable: true,
                        fixed: true,
                        width: 40
                    },
                    headerProps: {
                        children: ""
                    },
                    cellProps: {
                        dataKey: nameof<IUser>('lastOnline'),
                        children: (rowData) => {
                            const row = rowData as IUser;

                            const lastOnline = row.lastOnline ? new Date(row.lastOnline) : undefined;

                            let isOnline = false;
                            let isRecently = false;

                            if(currentUser?.id === row.id) {
                                isOnline = true;
                            }
                            else if(lastOnline) {
                                const lastOnlineDiff = new Date().getTime() - lastOnline.getTime();

                                isOnline = lastOnlineDiff < (onlineInterval + 1000);

                                if(!isOnline) {
                                    isRecently = lastOnlineDiff < 43200000; // Была в сети последние 12 часов
                                }
                            }

                            const lastOnlineDateTime = lastOnline ? lastOnline.toLocaleString().replace(',', '') : undefined;

                            return <div 
                                className={'d-flex-center-center px-2 w-100 h-100 cursor-help ' + (isOnline ? "text-success" : (isRecently ? "text-warning" : "text-danger"))}
                                title={isOnline ? 
                                    "В сети": 
                                    "Был в сети: " + (lastOnlineDateTime ?? "неизвестно")
                                }
                            >
                                <GrStatusGoodSmall 
                                    style={{width: 14}}
                                />
                            </div>
                        }
                    }
                },
                {
                    columnProps: {
                        sortable: true,
                        flexGrow: 1,
                        fullText: true
                    },
                    headerProps: {
                        children: "Имя"
                    },
                    cellProps: {
                        dataKey: nameof<IUser>('firstName')
                    },
                    searchable: true
                },
                {
                    columnProps: {
                        sortable: true,
                        flexGrow: 1,
                        fullText: true
                    },
                    headerProps: {
                        children: "Фамилия"
                    },
                    cellProps: {
                        dataKey: nameof<IUser>('lastName')
                    },
                    searchable: true
                },
                {
                    columnProps: {
                        sortable: true,
                        flexGrow: 1,
                        fullText: true
                    },
                    headerProps: {
                        children: "Логин"
                    },
                    cellProps: {
                        dataKey: nameof<IUser>('username')
                    },
                    searchable: true
                },
                {
                    columnProps: {
                        sortable: true,
                        flexGrow: 1,
                        fullText: true
                    },
                    headerProps: {
                        children: "DomainLDAP"
                    },
                    cellProps: {
                        dataKey: nameof<IUser>('domainLDAP')
                    },
                    searchable: true
                },
                {
                    columnProps: {
                        sortable: true,
                        flexGrow: 1,
                        fullText: true
                    },
                    headerProps: {
                        children: "Роль"
                    },
                    cellProps: {
                        dataKey: nameof<IUser>('role'),
                        children: (row: IUser | RowDataType) => row.role.name
                    },
                    searchable: true
                }
            ];

            return currentCols;
        }
        else return []
    }, [roles]);

    const [deleteApplicationForRegistr] = useDeleteApplicationForRegistrMutation();

    const handleOpenRegistrationApplications = useCallback(() => {
        if (applicationsForRegistr?.data && applicationsForRegistr?.data.length! > 0 && roles?.data) {
            const handleRejectApplication = async (application: IApplyForRegistration) => {
                showLoadingSwal("Отклонение заявки");
                const result = await deleteApplicationForRegistr(application.id);

                fetchDataAlert({
                    result: result,
                    showSuccessMessage: true
                });
                
                window.open(`mailto:${application.username}@keysystems.ru?subject=Подтверждение регистрации на Сервисе Мониторинга&body=Добрый день, Ваша заявка на Сервисе Мониторинга ОТКЛОНЕНА`);
            }
            
            const rolesOptions = roles.data.map(role => ({
                name: role.name,
                value: role.id
            }));

            RSwal.fire({
                title: "Заявки на регистрацию",
                showConfirmButton: false,
                showCloseButton: true,
                customClass: {
                  popup: "swal2-modal-full-height-html"  
                },
                html: <div className='w-100 h-100'>
                    {applicationsForRegistr.data.map(item =>
                        <Form
                            isSwalForm
                            elements={[
                                {
                                    type: FormElemTypes.text,
                                    id: item.id,
                                    name: nameof<IApplyForRegistration>('id'),
                                    label: "id",
                                    htmlAttributes: {
                                        defaultValue: String(item.id),
                                        readOnly: true,
                                        required: true
                                    }
                                },
                                {
                                    type: FormElemTypes.text,
                                    id: item.id,
                                    name: nameof<IApplyForRegistration>('firstName'),
                                    label: "Имя",
                                    htmlAttributes: {
                                        defaultValue: item.firstName,
                                        placeholder: "Иван",
                                        readOnly: true,
                                        required: true
                                    }
                                },
                                {
                                    type: FormElemTypes.text,
                                    id: item.id,
                                    name: nameof<IApplyForRegistration>('lastName'),
                                    label: "Фамилия",
                                    htmlAttributes: {
                                        defaultValue: item.lastName,
                                        placeholder: "Иванов",
                                        readOnly: true,
                                        required: true
                                    }
                                },
                                {
                                    type: FormElemTypes.text,
                                    id: item.id,
                                    name: nameof<IApplyForRegistration>('username'),
                                    label: "Логин",
                                    htmlAttributes: {
                                        defaultValue: item.username,
                                        placeholder: "ivanov-in",
                                        readOnly: true,
                                        required: true
                                    }
                                },
                                {
                                    type: FormElemTypes.text,
                                    id: item.id,
                                    name: nameof<IApplyForRegistration>('region'),
                                    label: "Регион",
                                    htmlAttributes: {
                                        defaultValue: item.region,
                                        placeholder: "Москва",
                                        readOnly: true,
                                        required: true
                                    }
                                },
                                {
                                    type: FormElemTypes.text,
                                    id: item.id,
                                    name: nameof<IApplyForRegistration>('email'),
                                    label: "Почта",
                                    htmlAttributes: {
                                        defaultValue: item.email,
                                        placeholder: "ivanov-in@keysystems.ru",
                                        readOnly: true,
                                        required: true
                                    }
                                }
                            ]}
                            submitButton={{
                                name: "Рассмотреть",
                                handleClick: async (application: IApplyForRegistration) => {
                                    RSwal.fire({
                                        title: "Рассмотрение заявки",
                                        showConfirmButton: false,
                                        html: <Form
                                            isSwalForm
                                            elements={[
                                                {
                                                    type: FormElemTypes.text,
                                                    name: nameof<IApplyForRegistration>('firstName'),
                                                    label: "Имя",
                                                    htmlAttributes: {
                                                        defaultValue: application.firstName,
                                                        placeholder: "Иван",
                                                        required: true
                                                    }
                                                },
                                                {
                                                    type: FormElemTypes.text,
                                                    name: nameof<IApplyForRegistration>('lastName'),
                                                    label: "Фамилия",
                                                    htmlAttributes: {
                                                        defaultValue: application.lastName,
                                                        placeholder: "Иванов",
                                                        required: true
                                                    }
                                                },
                                                {
                                                    type: FormElemTypes.text,
                                                    name: nameof<IApplyForRegistration>('username'),
                                                    label: "Логин",
                                                    htmlAttributes: {
                                                        defaultValue: application.username,
                                                        placeholder: "ivanov-in",
                                                        required: true
                                                    }
                                                },
                                                {
                                                    type: FormElemTypes.select,
                                                    name: nameof<IUser>('roleId'),
                                                    label: "Роль",
                                                    options: rolesOptions,
                                                    htmlAttributes: {
                                                        required: true
                                                    }
                                                },
                                                {
                                                    type: FormElemTypes.text,
                                                    name: nameof<IUser>('password'),
                                                    label: "Пароль",
                                                    htmlAttributes: {
                                                        placeholder: "******",
                                                        required: true
                                                    },
                                                    withGeneratePassword: true
                                                }
                                            ]}
                                            submitButton={{
                                                name: "Одобрить",
                                                handleClick: (currentUser: IUser) => {
                                                    createUser(
                                                        currentUser, 
                                                        {
                                                            id: application.id, 
                                                            email: application.email
                                                        }
                                                    )
                                                }
                                            }}
                                            denyButton={{
                                                name: "Отклонить",
                                                handleClick: () => handleRejectApplication(application)
                                            }}
                                        />
                                    })
                                }
                            }}
                            denyButton={{
                                name: "Отклонить",
                                handleClick: () => handleRejectApplication(item)
                            }}
                        />
                    )}
                </div>
            });
        }
        else {
            RSwal.fire({
                icon: 'info',
                title: 'Заявок не обнаружено'
            })
        }
    }, [applicationsForRegistr, roles]);

    return (
        <main>
            <PageTitle
                title='Пользователи'
                reloadPage={() => {
                    getUsers();
                    getRoles();
                }}
                isLoading={isFetching}
                pageUpdateTime={!isFetching && fulfilledTimeStamp ?
                    new Date(fulfilledTimeStamp).toLocaleString().replace(',', "") :
                    ""}
            />

            {users?.data ?
                <RsuiteTable
                    data={users.data}
                    columns={columns}
                    withTotalRows={true}
                    renderFooter={<div
                        className="border-right h-100 position-relative"
                        style={{ width: "80px" }}
                    >
                        <div
                            className="rs-table-crud-icon"
                            title="Заявки на регистрацию"
                            aria-disabled={!applicationsForRegistr?.data?.length}
                            onClick={handleOpenRegistrationApplications}
                        >
                            <MdContactMail className='dynamic-btn' />
                            {applicationsForRegistr && applicationsForRegistr?.data?.length! > 0 &&
                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger user-select-none">
                                    {applicationsForRegistr.data.length}
                                </span>}
                        </div>
                    </div>}
                /> :
                <LoadingBlock rounded />}

        </main>
    );
}

export default React.memo(UsersPage);