import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { defaultPollingInterval, windowPath } from '../../store/globalVariables';
import Navigation from "./navigation/Navigation";
import './navigation/navigation.scss'
import monitoringIcon from '../../assets/images/monitoring-icon-180x180.png'
import { useLazyGetFileVersionQuery } from '../../services/AppService';
import ThemeToggler from './account-menu/ThemeToggler';
import AccountMenuToggler from './account-menu/AccountMenuToggler';
import './account-menu/accountMenu.scss';
import { SlMenu } from 'react-icons/sl';
import { useAppSelector } from '../../hooks/redux';

let prevFileVersion = "";

function Header() {
    const navigationContainerRef = useRef<HTMLDivElement>(null);

    const navTogglerRef = useRef<HTMLDivElement>(null);

    const currentUser = useAppSelector(state=>state.user.currentUserState);

    // Получить версию сайта
    const [getFileVersion, { data: fileVersion, isUninitialized }] = useLazyGetFileVersionQuery(
        { 
            pollingInterval: defaultPollingInterval, 
            // refetchOnFocus: true
        }
    );

    const [isOldVersion, setIsOldVersion] = useState(false);

    useEffect(()=>{
        if(fileVersion?.data) {
            if(prevFileVersion) {
                if(prevFileVersion !== fileVersion.data) {
                    // какая-то логика после изменения версии сайта
                    setIsOldVersion(true);
                }
            }
            prevFileVersion = fileVersion.data;
        }
    }, [fileVersion])

    const location = useLocation();

    useEffect(() => {}, [location]);

    useEffect(()=>{
        if(currentUser && isUninitialized) getFileVersion();
    }, [currentUser]);

    let path: string = windowPath.value.toLowerCase();

    let isLoginPage = path === '/login';
    let isRegistrationPage = path === '/registration';

    const handleNavBrandClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if(path === "/") window.location.reload();

        if((event.target as HTMLElement).classList?.contains('btn-is-old-version')) {
            event.preventDefault();
        }
    }

    return (
        <nav 
            className="navigation-container"
            ref={navigationContainerRef}
        >
            <div className="navigation-head">
                <Link
                    className={"navigation-brand " + (currentUser ? "" : "pointer-events-none")}
                    to="/"
                    title="Главная"
                    onClick={(e)=>handleNavBrandClick(e)}
                >
                    <img
                        src={monitoringIcon}
                        alt="monitoring-icon"
                    />
                    <span className="brand-name">
                        WT Admin Tools
                        {fileVersion?.data &&
                            <div className='site-version'>
                                {isOldVersion ?
                                    <div
                                        className="btn-is-old-version"
                                        title='Новая версия уже вышла!'
                                        onClick={()=>window.location.reload()}
                                    >
                                        Обновить
                                    </div> :
                                    fileVersion?.data}
                            </div>}
                    </span>
                </Link>

                {!isLoginPage && !isRegistrationPage ?
                    <>
                        <div className='navigation-head-buttons-container'>
                            <ThemeToggler />
                            <AccountMenuToggler />
                        </div>
                        <div className="navigation-toggler-container pe-3">
                            <div
                                className="navigation-toggler"
                                ref={navTogglerRef}
                            >
                                <SlMenu className='pointer-events-none' />
                            </div>
                        </div>
                    </>
                    :
                    <div className="navigation-head-buttons-container">
                        <div className='px-3' style={{ width: "125.5px" }}>
                            {isLoginPage &&
                                <Link to="/Registration" className='transparent-hover w-100 h-100 d-block text-end'>
                                    Регистрация
                                </Link>}
                            {isRegistrationPage &&
                                <Link to="/Login" className='transparent-hover w-100 h-100 d-block text-end'>
                                    Войти
                                </Link>}
                        </div>
                    </div>}
            </div>

            {!isLoginPage && !isRegistrationPage &&
                <Navigation 
                    navTogglerRef={navTogglerRef}
                />
            }
        </nav >
    );
}

export default React.memo(Header);