import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {defaultBaseQueryParams} from "../constants/defaultBaseQueryParams";
import { IDefaultDataResponse } from "../models/responses/IDefaultResponse";
import { IPrometheusActiveTarget } from "../models/entities/IPrometheusActiveTarget";
import IPrometheusQueryRange from "../models/entities/IPrometheusQueryRange";
import { GetAlertsHistoryRequest } from "../models/requests/IGetAlertsHistoryRequest";
import IPrometheusAlert from "../models/entities/IPrometheusAlert";
import { IPrometheusRuleType } from "../models/entities/IPrometheusRuleType";
import { IPrometheusQueryRangeResponse } from "../models/responses/IPrometheusQueryRangeResponse";
import { RSwal } from "../constants/sweetAlert";

export const serverLoadApi = createApi({
    reducerPath: 'serverLoadApi',
    baseQuery: fetchBaseQuery(defaultBaseQueryParams),
    endpoints: build => ({
        getTargets: build.query<IDefaultDataResponse<IPrometheusActiveTarget[]>, void>({
            query: ()  => ({
                url: `/Prometheus/GetTargets`,
                method: "GET"
            }),
            // providesTags: (result) =>
            //     result?.data? [
            //         ...result.data.map(({ id }) => ({ type: 'Role' as const, id })),
            //         { type: 'Role', id: 'LIST' },
            //         ]
            //     : [{ type: 'Role', id: 'LIST' }]
        }),
        getFromPrometheus: build.query<IDefaultDataResponse<IPrometheusActiveTarget[]>, string>({
            query: (requestString)  => ({
                url: `/Prometheus/GetFromPrometheus?requestString=${requestString}`,
                method: "GET"
            })
        }),
        getCpuGraph: build.query<IDefaultDataResponse<IPrometheusQueryRangeResponse>, IPrometheusQueryRange>({
            query: (body)  => ({
                url: `/Prometheus/GetCpuGraph`,
                method: "POST",
                body: body
            })
        }),
        getRamGraph: build.query<IDefaultDataResponse<IPrometheusQueryRangeResponse>, IPrometheusQueryRange>({
            query: (body)  => ({
                url: `/Prometheus/GetRamGraph`,
                method: "POST",
                body: body
            })
        }),
        getGraphsData: build.query<IDefaultDataResponse<IPrometheusQueryRangeResponse>, IPrometheusQueryRange>({
            query: (body)  => ({
                url: `/Prometheus/GetGraphsData`,
                method: "POST",
                body: body
            })
        }),
        getDiskUsage: build.query<IDefaultDataResponse<IPrometheusQueryRangeResponse>, string>({
            query: (instance)  => ({
                url: `/Prometheus/GetDiskUsage?instance=${instance}`,
                method: "GET"
            })
        }),
        getPrometheusRules: build.query<IDefaultDataResponse<IPrometheusRuleType[]>, void>({
            query: ()  => ({
                url: `/Prometheus/GetPrometheusRules`,
                method: "GET"
            })
        }),
        getAlerts: build.query<IDefaultDataResponse<IPrometheusAlert[]>, string>({
            query: (instance)  => ({
                url: `/Prometheus/GetAlerts?instance=${instance}`,
                method: "GET"
            })
        }),
        getFiringAlerts: build.query<IDefaultDataResponse<IPrometheusAlert[]>, void>({
            query: ()  => ({
                url: `/Prometheus/GetFiringAlerts`,
                method: "GET"
            })
        }),
        getAlertsHistory: build.query<IDefaultDataResponse<IPrometheusAlert[]>, GetAlertsHistoryRequest>({
            query: (body)  => ({
                url: `/Prometheus/GetAlertsHistory`,
                method: "POST",
                body: body
            }),
            // Only have one cache entry because the arg always maps to one string
            serializeQueryArgs: ({ endpointName }) => {
              return endpointName
            },
            // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, other) => {
                if (other.arg.lastId) {
                    currentCache.data.push(...newItems.data)
                }
                else {
                    currentCache.data = newItems.data
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
            },
            transformResponse: (productMetrics: IDefaultDataResponse<IPrometheusAlert[]>, meta, arg) => {
                if(arg.lastId && productMetrics?.data && productMetrics.data.length === 0) {
                    RSwal.fire({
                        icon: 'info',
                        title: 'Таких проблем больше не найдено'
                    })
                }
                return productMetrics;
            }
        }),
        getHistoryElem: build.query<IDefaultDataResponse<IPrometheusAlert[]>, number>({
            query: (id)  => ({
                url: `/Prometheus/GetHistoryElem?${id}`,
                method: "GET"
            })
        })
    })
})

export const {
    useGetTargetsQuery,
    useLazyGetAlertsHistoryQuery,
    useGetAlertsQuery,
    useLazyGetCpuGraphQuery,
    useLazyGetRamGraphQuery,
    useLazyGetGraphsDataQuery,
    useLazyGetDiskUsageQuery,
    useGetFiringAlertsQuery,
    useGetFromPrometheusQuery,
    useGetHistoryElemQuery,
    useGetPrometheusRulesQuery
} = serverLoadApi;