import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {defaultBaseQueryParams} from "../constants/defaultBaseQueryParams";
import { IDefaultDataResponse } from "../models/responses/IDefaultResponse";
import { IGetBugzillaProductInfo } from "../models/responses/IGetBugzillaProductInfo";
import { IGetBugzillaUser } from "../models/responses/IGetBugzillaUser";
import { ICreateBug } from "../models/requests/ICreateBug";

export const bugzillaApi = createApi({
    reducerPath: 'bugzillaApi',
    baseQuery: fetchBaseQuery(defaultBaseQueryParams),
    endpoints: build => ({
        getBugzillaProductInfo: build.query<IDefaultDataResponse<IGetBugzillaProductInfo>, void>({
            query: () => ({
                url: '/Bugzilla/GetBugzillaProductInfo',
                method: "GET"
            })
        }),
        getBugzillaUser: build.query<IDefaultDataResponse<IGetBugzillaUser>, string>({
            query: (email) => ({
                url: `/Bugzilla/GetBugzillaUser/?email=${email}`,
                method: "GET"
            })
        }),
        createBug: build.mutation<IDefaultDataResponse<string>, ICreateBug>({
            query: (model) => ({
                url: "/Bugzilla/CreateBug",
                method: "POST",
                body: model
            })
        }),
    })
})

export const {
    useGetBugzillaProductInfoQuery,
    useLazyGetBugzillaUserQuery,
    useCreateBugMutation
} = bugzillaApi;