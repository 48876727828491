import React, { memo } from 'react'

export interface AxisProps extends React.MouseEvent<SVGElement, MouseEvent> {
    index: number,
    value: string
};

export type CustomXAxisTickProps = {
    tickProps: {
        index: number,
        angle: number,
        fontSize: number,
        x: number,
        y: number,
        payload: AxisProps,
        dy: number,
        dx: number,
        textAnchor: string,
        fill: string
    },
    handleMouseEnter?: (index: number, x: number, y: number) => void,
    handleMouseLeave?: () => void,
    handleClick?: (tickIdx: number) => void
};

const CustomXAxisTick = memo(({tickProps, handleMouseEnter, handleMouseLeave, handleClick}: CustomXAxisTickProps) => {
    return (
        <g 
            transform={`translate(${tickProps.x},${tickProps.y})`} 
            onMouseEnter={(e) => {
                if(handleMouseEnter) {
                    const pos = (e.target as HTMLElement).getBoundingClientRect();
                    handleMouseEnter(tickProps.index, pos.x, pos.y);
                }
            }}
            onMouseLeave={handleMouseLeave}
            onClick={()=>handleClick ? handleClick(tickProps.index) : null}
        >
            <text 
                className='recharts-cartesian-axis-tick-value'
                x={0} 
                y={0} 
                dy={tickProps.dy} 
                dx={tickProps.dx}
                textAnchor={tickProps.textAnchor}
                fill={tickProps.fill} 
                fontSize={tickProps.fontSize}
                transform={`rotate(${tickProps.angle})`}
                cursor={handleMouseEnter ? "pointer" : ''}
            >
                {tickProps.payload.value}
            </text>
        </g>
    );
})

export default CustomXAxisTick