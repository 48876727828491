import React, { useLayoutEffect, useState, useMemo, useRef, useCallback } from 'react';
import './product-metrics.scss'
import PageTitle from '../../components/PageTitle';
import { useGetComplexesWithProductMetricsQuery, useGetProductMetricsTypesQuery, useLazyGetProductMetricsQuery } from '../../services/ProductMetricService';
import ProductMetricsFullInfo from './ProductMetricsFullInfo';
import ProductMetricsPanel from './ProductMetricsPanel';
import { IProductMetric } from '../../models/entities/IProductMetric';
import { defaultPollingInterval } from '../../store/globalVariables';

function ProductMetricsPage() {
    const {
        refetch: getComplexes,
        data: complexes,
        isFetching: complexIsLoading,
        fulfilledTimeStamp: complexesReceivingTime
    } = useGetComplexesWithProductMetricsQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const {
        refetch: getProductMetricsTypes,
        data: productMetricsTypes
    } = useGetProductMetricsTypesQuery(undefined, {
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    const [getProductMetrics, {
        data: productMetrics,
        isFetching: productMetricsIsLoading,
        fulfilledTimeStamp: productMetricsReceivingTime
    }] = useLazyGetProductMetricsQuery({
        refetchOnReconnect: true,
        pollingInterval: defaultPollingInterval
    });

    useLayoutEffect(() => {
        getProductMetrics({}, true);
    }, [])

    const isLoad: boolean =
        (!complexIsLoading && !!complexes?.data) ||
        (!productMetricsIsLoading && !!productMetrics?.data);

    const isLoading: boolean =
        complexIsLoading ||
        productMetricsIsLoading;

    const lastReceivingTime = Math.max(
        complexesReceivingTime ?? 0,
        productMetricsReceivingTime ?? 0
    );

    const reloadPage = useCallback(
      () => {
        applyFilters();
        getComplexes();
        getProductMetricsTypes();
    },[])

    const productMetricsPageRef = useRef<HTMLDivElement>(null);
    
    const filtersMenuTogglerRef = useRef<HTMLDivElement>(null);
    const filtersMenuRef = useRef<HTMLDivElement>(null);

    const searchStringRef = useRef<HTMLInputElement>(null);
    const complexesRef = useRef<number[]>([]);
    const productMetricsTypesRef = useRef<number[]>([]);
    const startRecDateRef = useRef<Date | null>(null); 
    const endRecDateRef = useRef<Date | null>(null); 
    const startCollDateRef = useRef<Date | null>(null); 
    const endCollDateRef = useRef<Date | null>(null); 
    
    const shouldUpdateScrollRef = useRef<boolean>(false);

    const applyFilters = useCallback((lastIdForNextMetrics?: number, shouldUpdateScroll: boolean = true) => {
        shouldUpdateScrollRef.current = shouldUpdateScroll;
        if(filtersMenuRef.current) filtersMenuRef.current.classList.remove('show');

        let withFilters: boolean = false;

        if(searchStringRef.current) {
            if (searchStringRef.current.value) {
                withFilters = true;
                searchStringRef.current.classList.add('active');
            }
            else {
                searchStringRef.current.classList.remove('active');
            }
        }

        if(
            complexesRef.current.length > 0 || 
            productMetricsTypesRef.current.length > 0 ||
            startRecDateRef.current || 
            endRecDateRef.current || 
            startCollDateRef.current || 
            endCollDateRef.current
        ) {
            withFilters = true;
        }

        if (withFilters || lastIdForNextMetrics) {
            if (filtersMenuTogglerRef.current && !lastIdForNextMetrics && withFilters) {
                filtersMenuTogglerRef.current.classList.add('active');
            }

            getProductMetrics({
                lastProductMetricId: typeof lastIdForNextMetrics === 'number' ? lastIdForNextMetrics : null,
                metricsTypes: productMetricsTypesRef.current,
                complexes: complexesRef.current,
                receivingStartDate: startRecDateRef.current?.toLocaleString().replace(',', ''),
                receivingEndDate: endRecDateRef.current?.toLocaleString().replace(',', ''),
                collectionStartDate: startCollDateRef.current?.toLocaleString().replace(',', ''),
                collectionEndDate: endCollDateRef.current?.toLocaleString().replace(',', '')
            })
        }
        else {
            if (filtersMenuTogglerRef.current) filtersMenuTogglerRef.current.classList.remove('active');
            if (searchStringRef.current) searchStringRef.current.classList.remove('active');
            getProductMetrics({});
        }
    }, []);

    const cancelFilters = useCallback(()=>{
        if(filtersMenuRef.current) filtersMenuRef.current.classList.remove('show');
        if(filtersMenuTogglerRef.current) filtersMenuTogglerRef.current.classList.remove('active');
        if(searchStringRef.current) {
            searchStringRef.current.classList.remove('active');
            searchStringRef.current.value = "";
        }
        getProductMetrics({}, true);
    }, [])

    return (
        <main>
            <PageTitle
                title='Продуктовые метрики'
                reloadPage={reloadPage}
                isLoading={isLoading}
                pageUpdateTime={!isLoading && isLoad && lastReceivingTime ?
                    new Date(lastReceivingTime).toLocaleString().replace(',', "") :
                    ""}
            />

            <div 
                ref={productMetricsPageRef}
                className="product-metrics-page"
            >
                <ProductMetricsPanel
                    productMetricsPageRef={productMetricsPageRef}
                    filtersMenuTogglerRef={filtersMenuTogglerRef}
                    filtersMenuRef={filtersMenuRef}
                    panelIsLoading={isLoading}
                    complexes={complexes?.data}
                    productMetricsTypes={productMetricsTypes?.data}
                    productMetrics={productMetrics?.data}
                    productMetricsReceivingTime={productMetricsReceivingTime}
                    searchStringRef={searchStringRef}
                    complexesRef={complexesRef}
                    productMetricsTypesRef={productMetricsTypesRef}
                    startRecDateRef={startRecDateRef}
                    endRecDateRef={endRecDateRef}
                    startCollDateRef={startCollDateRef}
                    endCollDateRef={endCollDateRef}
                    applyFilters={applyFilters}
                    cancelFilters={cancelFilters}
                    shouldUpdateScroll={shouldUpdateScrollRef.current}
                />

                <ProductMetricsFullInfo />
            </div>
        </main>
    );
}

export default React.memo(ProductMetricsPage);