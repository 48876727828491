import { RefObject, memo, ReactNode } from 'react';
import ClickOutside from './ClickOutside';

type GraphContextMenuProps = {
    innerRef: RefObject<HTMLDivElement>,
    children: ReactNode
}

export const GraphContextMenu = memo(({ innerRef, children }: GraphContextMenuProps) => {
    const clickOutside = () => {
        if(innerRef.current) {
            innerRef.current.classList.remove("show");
        }
    };

    return (
        <ClickOutside
            handleClickOutside={clickOutside}
        >
            <div
                ref={innerRef}
                className='graph-context-menu'
            >
                {children}
            </div>
        </ClickOutside>
    )
});