export enum ComplexTypeProperties {
    id="id",
    name="name"
}

export interface IComplexType {
    [ComplexTypeProperties.id]: number,
    [ComplexTypeProperties.name]: string
}

export enum ComplexTypePropertiesNames {
    id="id",
    name="Название"
}