import { RefObject, memo, useRef } from 'react'
import { MdClose, MdSearch } from 'react-icons/md';
import { Filter } from './RsuiteTable';
import ClickOutside from '../ClickOutside';

type RsuiteSearchInputProps = {
    dataKey: string,
    showInput: (target: HTMLInputElement) => void,
    filterRows: (filter: Filter) => void,
    cancelFilter: (dataKey: string)=>void
}

const RsuiteSearchInput = memo(({ dataKey, showInput, filterRows, cancelFilter }: RsuiteSearchInputProps) => {
    const headerSearchRef = useRef<HTMLDivElement>(null);
    const inputSearchRef = useRef<HTMLInputElement>(null);

    const handleCancelFilter = () => {
        if(inputSearchRef.current) {
            inputSearchRef.current.value = "";
        }
        if(headerSearchRef.current) {
            headerSearchRef.current.classList.remove('active')
        }
        cancelFilter(dataKey)
    }

    return (
        <ClickOutside
            handleClickOutside={() => {
                if (headerSearchRef.current?.classList.contains("active")) {
                    headerSearchRef.current.classList.remove("active")
                }
            }}
            className='ms-auto'
        >
            <span
                className="rs-table-cell-header-search-wrapper"
                onClick={(e) => {
                    const target = e.target as HTMLInputElement;
                    showInput(target);
                    inputSearchRef.current?.focus();
                }}
                ref={headerSearchRef}
            >
                <MdSearch className='pointer-events-none' />
            </span>
            <div className="rs-table-cell-header-search-input">
                <input
                    type="text"
                    placeholder='Поиск'
                    name={'search_'+dataKey}
                    onChange={(e) => {
                        if (dataKey) {
                            const target = e.target as HTMLInputElement;
                            filterRows({
                                name: dataKey,
                                condition: target.value
                            });
                        }
                    }} 
                    ref={inputSearchRef}
                    onKeyDown={(e)=>{
                        if(e.key === "Escape") {
                            handleCancelFilter()
                        }
                    }}
                />
                <MdClose 
                    onClick={handleCancelFilter}
                />
            </div>
        </ClickOutside>
    )
})

export default RsuiteSearchInput