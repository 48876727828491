import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {defaultBaseQueryParams} from "../constants/defaultBaseQueryParams";
import { IDefaultDataResponse, IDefaultResponse } from "../models/responses/IDefaultResponse";
import { IUpdateHistoryItem } from "../models/entities/IUpdateHistoryItem";
import { dispatch } from "../store";

export const updateHistoryApi = createApi({
    reducerPath: 'updateHistoryApi',
    baseQuery: fetchBaseQuery(defaultBaseQueryParams),
    tagTypes: ['UpdateHistory'],
    endpoints: build => ({
        getUpdateHistory: build.query<IDefaultDataResponse<IUpdateHistoryItem[]>, void>({
            query: ()  => ({
                url: `/UpdateHistory/GetUpdateHistory`,
                method: "GET"
            }),
            providesTags: (result) =>
                result?.data? [
                    ...result.data.map(({ id }) => ({ type: 'UpdateHistory' as const, id })),
                    { type: 'UpdateHistory', id: 'LIST' },
                    ]
                : [{ type: 'UpdateHistory', id: 'LIST' }]
        }),
        setUpdateHistoryIsViewed: build.mutation<IDefaultResponse, number[]>({
            query: (ids) => ({
                url: "/UpdateHistory/SetUpdateHistoryIsViewed",
                body: ids,
                method: "POST"
            }),
            invalidatesTags: [{ type: 'UpdateHistory', id: 'LIST' }]
        }),
        createUpdateHistoryItem: build.mutation<IDefaultResponse, IUpdateHistoryItem>({
            query: (updateHistoryItem) => ({
                url: "/UpdateHistory/CreateUpdateHistoryItem",
                body: updateHistoryItem,
                method: "POST"
            }),
            invalidatesTags: [{ type: 'UpdateHistory', id: 'LIST' }]
        }),
        editUpdateHistoryItem: build.mutation<IDefaultResponse, IUpdateHistoryItem>({
            query: (updateHistoryItem)  => ({
                url: `/UpdateHistory/EditUpdateHistoryItem`,
                body: updateHistoryItem,
                method: "PUT"
            }),
            invalidatesTags: [{ type: 'UpdateHistory', id: 'LIST' }]
        }),
        deleteUpdateHistoryItem: build.mutation<IDefaultResponse, number>({
            query: (id)  => ({
                url: `/UpdateHistory/DeleteUpdateHistoryItem/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: 'UpdateHistory', id: 'LIST' }]
        }),
    })
})

export const {
    useGetUpdateHistoryQuery,
    useLazyGetUpdateHistoryQuery,
    useSetUpdateHistoryIsViewedMutation,
    useCreateUpdateHistoryItemMutation,
    useEditUpdateHistoryItemMutation,
    useDeleteUpdateHistoryItemMutation
} = updateHistoryApi;

export const getCurrentUpdateHistory = (): Promise<IDefaultDataResponse<IUpdateHistoryItem[]>> => {
    return dispatch(updateHistoryApi.endpoints.getUpdateHistory.initiate()).unwrap();
}
export const setCurrentUpdateHistoryIsViewed = (ids: number[]): Promise<IDefaultResponse> => {
    return dispatch(updateHistoryApi.endpoints.setUpdateHistoryIsViewed.initiate(ids)).unwrap();
}