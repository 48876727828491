import React, { memo, useLayoutEffect } from 'react'
import LoadingBlock from '../../components/LoadingBlock'
import { selectColor } from '../../store/globalVariables'
import { useLazyGetProductMetricQuery } from '../../services/ProductMetricService'
import { useParams } from 'react-router-dom'

const ProductMetricsFullInfo = memo(() => {
    const [getProductMetric, {
        data: productMetric,
        isFetching,
        isError,
        isSuccess
    }] = useLazyGetProductMetricQuery();

    const selectedProductMetricId = useParams<{ id?: string }>().id;

    useLayoutEffect(() => {
        if (selectedProductMetricId && !Number.isNaN(selectedProductMetricId)) {
            getProductMetric(Number(selectedProductMetricId), true)
        }
    }, [selectedProductMetricId]);

    const productMetricNotFound: boolean = !isError && isSuccess && !productMetric?.data;

    return (
        <div className="product-metric-full-info">
            {!productMetricNotFound ?
                (selectedProductMetricId && !isError ?
                    <>
                        <div className="product-metric-full-info-header">
                            {!isFetching && productMetric?.data ?
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>
                                                Регион:
                                            </th>
                                            <td>
                                                <span className='d-inline-block'>
                                                    {productMetric.data.region}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Система:
                                            </th>
                                            <td>
                                                <span className='d-inline-block'>
                                                    {productMetric.data.complex_type}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Период сбора метрик (Регион):
                                            </th>
                                            <td>
                                                <span className='d-inline-block'>
                                                    {productMetric.data.startDate}
                                                </span>
                                                &nbsp;–&nbsp;
                                                <span className='d-inline-block'>
                                                    {productMetric.data.endDate}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Дата приема метрик (Мск):
                                            </th>
                                            <td>
                                                {productMetric.data.receivingDate}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> :
                                <LoadingBlock rounded />}
                        </div>

                        <div className="product-metric-full-info-content">
                            {!isFetching && productMetric?.data?.metrics ?
                                productMetric.data.metrics.map((pm, index) =>
                                    <div
                                        key={'product_metric_' + index}
                                        className="product-metric-full-info-item"
                                        style={{ backgroundColor: selectColor(index) }}
                                    >
                                        <div className="row mx-0 w-100">
                                            <div className="col-12 px-0 text-truncate fw-bold">
                                                {pm.name}
                                            </div>
                                            <div className="col-12 px-0 text-truncate">
                                                {pm.value}
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                <LoadingBlock rounded />}
                        </div>
                    </> :
                    <h5>
                        {isError ?
                            "Возникла ошибка при получении метрики" :
                            "Выберите из списка"}
                    </h5>) :
                <h5>Продуктовой метрики с id {selectedProductMetricId} не найдено</h5>}
        </div>
    )
})

export default ProductMetricsFullInfo